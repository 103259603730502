import React, { createRef, useContext, useEffect, useState } from 'react'
import './encounterDetail.scss'
import config from '../../config/config';
import { useLocation, useNavigate } from 'react-router-dom'
import { LoaderContext } from '../../contexts/LoaderContext';
import { useNotification } from '../../contexts/NotificationContext';
import { approveEncounterRequest, generateCPTCodeRequest, generateClinicalNoteRequest, generateICD10CodeRequest, generateMedicalInsightsRequest, getAudioOfEncounterRequest, getEncounterDetailRequest, updateEncounterClinicalNoteRequest, updateEncounterCptCodesRequest, updateEncounterDataRequest, updateEncounterDateRequest, updateEncounterIcd10CodesRequest, updateEncounterInsightsRequest, updateEncounterPatientRequest } from '../../webservices/webservice';
import { DataStoreContext } from '../../contexts/DataStoreContext';
import { genders } from '../../constants/data';
import moment from 'moment';
import MainDropdown from '../../components/dropdowns/mainDropdown/MainDropdown';
import MainTextField from '../../components/textfields/mainTextField/MainTextField';
import MainDateTimePicker from '../../components/dateTimePickers/mainDateTimePicker/MainDateTimePicker';
import { formattedDate } from '../../utils/date';
import MainIconButton from '../../components/buttons/mainIconButton/MainIconButton';
import { colors } from '../../constants/colors';
import { CircularProgress, Tab, Tabs, ToggleButton, ToggleButtonGroup } from '@mui/material';
import MainButton from '../../components/buttons/mainButton/MainButton';
import IconBadge from '../../components/badges/iconBadge/IconBadge';
import MainDatePicker from '../../components/dateTimePickers/mainDatePicker/MainDatePicker';
import Alert from '../../modals/alert/Alert';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { Article, VerticalSplit } from '@mui/icons-material';
import MainTextArea from '../../components/textfields/mainTextArea/MainTextArea';
import { characterCountMinimumForLearning } from '../../config/config';
import { SocketContext } from '../../contexts/SocketContext';

function EncounterDetail ()
{
    const { width } = useWindowDimensions();
    //const { patients, updateData } = useContext( DataStoreContext )
    const { patients, updateData, preferences} = useContext( DataStoreContext )
    const { updateEncounter, onEncounterUpdation } = useContext( SocketContext )
    const [ patient, setPatient ] = useState( {
        patientName: '',
        patientEmrId: '',
        dob: null
    } )
    const [ encounter, setEncounter ] = useState( null );
    const { showLoader, hideLoader } = useContext( LoaderContext )
    const notify = useNotification();
    const { state } = useLocation();
    const encounterId = state ? state.encounterId : null
    const [ selectedPatient, setSelectedPatient ] = useState( null )
    const [ encounterDate, setEncounterDate ] = useState( moment() )
    const [ data, setData ] = useState( '' )
    const [ clinicalNote, setClinicalNote ] = useState( '' )
    const [ cptCodes, setCptCodes ] = useState( '' )
    const [ icd10Codes, setIcd10Codes ] = useState( '' )
    const [ insights, setInsights ] = useState( '' )
    const [ editForm, setEditForm ] = useState( null )
    const [ isPlayingAudio, setIsPlayingAudio ] = useState( false );
    const audioRef = createRef();
    const [ audioData, setAudioData ] = useState( null );
    const [ tabValue, setTabValue ] = useState( 'data' )
    const [ approveAlert, setApproveAlert ] = useState( {
        show: false
    } )
    const [ isSplitView, setIsSplitView ] = useState( false );
    const [ splitTabValue, setSplitTabValue ] = useState( 'clinical_note' )
    const [audioLoader,setAudioLoader] = useState(false)
    const [playButtonClicked, setPlayButtonClicked] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);

    const handleChangeOfSplitView = ( e, newIsSplitView ) =>
    {
        if ( newIsSplitView !== null )
        {
            setIsSplitView( newIsSplitView )
        }
    }
    const openApproveAlert = () =>
    {
        setApproveAlert( {
            show: true
        } )
    }


    const closeApproveAlert = () =>
    {
        setApproveAlert( {
            show: false
        } )
    }


    const patientOptions = () =>
    {
        var options = []
        options.push(
            {
                value: 'NewPatient',
                label: 'New Patient'
            }
        )
        patients.forEach( patient =>
        {
            options.push( {
                value: patient,
                label: `${ patient.patientName }${ patient.patientEmrId ? ` | EMR ${ patient.patientEmrId }` : '' }${ patient.patientDetails && patient.patientDetails.dob ? ` | DOB ${ patient.patientDetails.dob }` : '' }`
            } )
        } );

        return options

    }

    const genderOptions = genders.map( gender => ( {
        label: gender,
        value: gender
    } ) )

    const handlePatientOptionChange = ( option ) =>
    {
        setSelectedPatient( option );
        if ( option.value === 'NewPatient' )
        {
            setPatient( {
                patientName: '',
                patientEmrId: '',
                dob: null
            } )
        }
        else
        {
            setPatient( {
                patientName: option.value.patientName,
                patientEmrId: option.value.patientEmrId ? option.value.patientEmrId : '',
                dob: option.value.patientDetails && option.value.patientDetails.dob ? moment( option.value.patientDetails.dob, 'MM/DD/YYYY' ) : null,
            } )
        }
    }

    const isPatientFieldsDisabled = () =>
    {
        if ( selectedPatient.value === 'NewPatient' )
        {
            return false
        }
        else
        {
            return true
        }
    }

    const navigate = useNavigate();

    const getAudioOfEncounter = () =>
    {
        setAudioLoader(true)
        getAudioOfEncounterRequest( encounterId )
            .then( data =>
            {
                setAudioData( data );
                setAudioLoader(false)
                console.log( data );
            } )
            .catch( error =>
            {
                setAudioLoader(false)
                notify( {
                    type: "ERROR",
                    message: error.message ? error.message : error,
                    title: error.title ? error.title : "Error!!!"
                } )
            } )

    }

    useEffect(() => {
     playAudio()
    }, [audioData]);

    const generateClinicalNote = () =>
    {
        showLoader();
        generateClinicalNoteRequest( encounterId )
            .then( message =>
            {
                hideLoader();
                setEditForm( null );
                notify( {
                    type: "SUCCESS",
                    message: message,
                    title: "Success!!!"
                } )
                getEncounterDetail();
                setTabValue( 'clinical_note' )
                setSplitTabValue( 'data' )
            } )
            .catch( error =>
            {
                hideLoader()
                cancelEditFn();
                notify( {
                    type: "ERROR",
                    message: error.message ? error.message : error,
                    title: error.title ? error.title : "Error!!!"
                } )
            } )

    }


    const generateICD10Code = () =>
    {
        showLoader();
        generateICD10CodeRequest( encounterId )
            .then( message =>
            {
                hideLoader();
                setEditForm( null );
                notify( {
                    type: "SUCCESS",
                    message: message,
                    title: "Success!!!"
                } )
                getEncounterDetail();
                setTabValue( 'icd_10_code' )
            } )
            .catch( error =>
            {
                hideLoader()
                cancelEditFn();
                notify( {
                    type: "ERROR",
                    message: error.message ? error.message : error,
                    title: error.title ? error.title : "Error!!!"
                } )
            } )

    }

    const generateCPTCode = () =>
    {
        showLoader();
        generateCPTCodeRequest( encounterId )
            .then( message =>
            {
                hideLoader();
                setEditForm( null );
                notify( {
                    type: "SUCCESS",
                    message: message,
                    title: "Success!!!"
                } )
                getEncounterDetail();
                setTabValue( 'cpt_code' )
            } )
            .catch( error =>
            {
                hideLoader()
                cancelEditFn();
                notify( {
                    type: "ERROR",
                    message: error.message ? error.message : error,
                    title: error.title ? error.title : "Error!!!"
                } )
            } )

    }

    const generateMedicalInsights = () =>
    {
        showLoader();
        generateMedicalInsightsRequest( encounterId )
            .then( message =>
            {
                hideLoader();
                setEditForm( null );
                notify( {
                    type: "SUCCESS",
                    message: message,
                    title: "Success!!!"
                } )
                getEncounterDetail();
                setTabValue( 'insights' )
            } )
            .catch( error =>
            {
                hideLoader()
                cancelEditFn();
                notify( {
                    type: "ERROR",
                    message: error.message ? error.message : error,
                    title: error.title ? error.title : "Error!!!"
                } )
            } )

    }

    const generateBillingCode = () =>
    {
        showLoader();
        Promise.all( [
            generateCPTCodeRequest( encounterId ),
            generateICD10CodeRequest( encounterId )
        ] )
            .then( messages =>
            {
                hideLoader();
                setEditForm( null );
                notify( {
                    type: "SUCCESS",
                    message: messages[ 0 ],
                    title: "Success!!!"
                } )
                notify( {
                    type: "SUCCESS",
                    message: messages[ 1 ],
                    title: "Success!!!"
                } )
                getEncounterDetail();
                setTabValue( 'cpt_code' )
            } )
            .catch( error =>
            {
                hideLoader()
                cancelEditFn();
                notify( {
                    type: "ERROR",
                    message: error.message ? error.message : error,
                    title: error.title ? error.title : "Error!!!"
                } )
            } )

    }

    const approveEncounter = () =>
    {
        showLoader();
        approveEncounterRequest( encounterId )
            .then( message =>
            {
                hideLoader();
                notify( {
                    type: "SUCCESS",
                    message: message,
                    title: "Success!!!"
                } )
                closeApproveAlert()
                getEncounterDetail();
                setEditForm( null );
            } )
            .catch( error =>
            {
                hideLoader()
                cancelEditFn();
                notify( {
                    type: "ERROR",
                    message: error.message ? error.message : error,
                    title: error.title ? error.title : "Error!!!"
                } )
            } )

    }

    const updateEncounterDate = () =>
    {
        showLoader();
        var enctrDate = encounterDate?  encounterDate.format( 'YYYY-MM-DD HH:mm:ssZ' ) : null
        updateEncounterDateRequest( encounterId, enctrDate )
            .then( message =>
            {
                hideLoader();
                notify( {
                    type: "SUCCESS",
                    message: message,
                    title: "Success!!!"
                } )
                getEncounterDetail();
                setEditForm( null );
            } )
            .catch( error =>
            {
                hideLoader()
                notify( {
                    type: "ERROR",
                    message: error.message ? error.message : error,
                    title: error.title ? error.title : "Error!!!"
                } )
                cancelEditFn();
            } )

    }

    const updateEncounterPatient = () =>
    {
        showLoader();
        var patientDetail = {
        }
        if ( patient.dob !== null )
        {
            patientDetail[ 'dob' ] = patient.dob.format( 'MM/DD/YYYY' )
        }

        updateEncounterPatientRequest(
            encounterId,
            selectedPatient && selectedPatient.value !== 'NewPatient' ? selectedPatient.value.patientId : null,
            selectedPatient && selectedPatient.value !== 'NewPatient' ? null : patient.patientName.trim(),
            selectedPatient && selectedPatient.value !== 'NewPatient' ? null : ( patient.patientEmrId.trim() === "" ? null : patient.patientEmrId.trim() ),
            selectedPatient && selectedPatient.value !== 'NewPatient' ? null : ( Object.keys( patientDetail ).length > 0 ? patientDetail : null ),
        )
            .then( message =>
            {
                hideLoader();
                notify( {
                    type: "SUCCESS",
                    message: message,
                    title: "Success!!!"
                } )
                getEncounterDetail();
                updateData();
                setEditForm( null );
            } )
            .catch( error =>
            {
                hideLoader()
                notify( {
                    type: "ERROR",
                    message: error.message ? error.message : error,
                    title: error.title ? error.title : "Error!!!"
                } )
                cancelEditFn();
            } )

    }


    const updateEncounterData = () =>
    {
        showLoader();
        updateEncounterDataRequest( encounterId, data )
            .then( message =>
            {
                hideLoader();
                notify( {
                    type: "SUCCESS",
                    message: message,
                    title: "Success!!!"
                } )
                getEncounterDetail();
                setEditForm( null );
            } )
            .catch( error =>
            {
                hideLoader()
                notify( {
                    type: "ERROR",
                    message: error.message ? error.message : error,
                    title: error.title ? error.title : "Error!!!"
                } )
                cancelEditFn();
            } )

    }


    const updateEncounterClinicalNote = () =>
    {
        showLoader();
        updateEncounterClinicalNoteRequest( encounterId, clinicalNote )
            .then( message =>
            {
                hideLoader();
                notify( {
                    type: "SUCCESS",
                    message: message,
                    title: "Success!!!"
                } )
                getEncounterDetail();
                setEditForm( null );
            } )
            .catch( error =>
            {
                hideLoader()
                notify( {
                    type: "ERROR",
                    message: error.message ? error.message : error,
                    title: error.title ? error.title : "Error!!!"
                } )
                cancelEditFn();
            } )

    }


    const updateEncounterCptCodes = () =>
    {
        showLoader();
        updateEncounterCptCodesRequest( encounterId, cptCodes )
            .then( message =>
            {
                hideLoader();
                notify( {
                    type: "SUCCESS",
                    message: message,
                    title: "Success!!!"
                } )
                getEncounterDetail();
                setEditForm( null );
            } )
            .catch( error =>
            {
                hideLoader()
                notify( {
                    type: "ERROR",
                    message: error.message ? error.message : error,
                    title: error.title ? error.title : "Error!!!"
                } )
                cancelEditFn();
            } )

    }


    const updateEncounterIcd10Codes = () =>
    {
        showLoader();
        updateEncounterIcd10CodesRequest( encounterId, icd10Codes )
            .then( message =>
            {
                hideLoader();
                notify( {
                    type: "SUCCESS",
                    message: message,
                    title: "Success!!!"
                } )
                getEncounterDetail();
                setEditForm( null );
            } )
            .catch( error =>
            {
                hideLoader()
                notify( {
                    type: "ERROR",
                    message: error.message ? error.message : error,
                    title: error.title ? error.title : "Error!!!"
                } )
                cancelEditFn();
            } )

    }


    const updateEncounterInsights = () =>
    {
        showLoader();
        updateEncounterInsightsRequest( encounterId, insights )
            .then( message =>
            {
                hideLoader();
                notify( {
                    type: "SUCCESS",
                    message: message,
                    title: "Success!!!"
                } )
                getEncounterDetail();
                setEditForm( null );
            } )
            .catch( error =>
            {
                hideLoader()
                notify( {
                    type: "ERROR",
                    message: error.message ? error.message : error,
                    title: error.title ? error.title : "Error!!!"
                } )
                cancelEditFn();
            } )

    }

    const getEncounterDetail = (first= false,onSocketUpdate = false) =>
    {
        showLoader();
        getEncounterDetailRequest( encounterId )
            .then( enc =>
            {
                hideLoader();
                console.log( enc )
                setEncounter( enc );
                // if ( enc.isTranscriptionUsed )
                // {
                //     getAudioOfEncounter();
                // }
                if(first && enc.isClinicalNoteGenerated){
                    setTabValue( 'clinical_note' )
                    setSplitTabValue( 'data' )
                }
                if(onSocketUpdate && enc.isClinicalNoteGenerated && enc.isAutoPilot){
                    setTabValue( 'clinical_note' )
                    setSplitTabValue( 'data' )
                }
            } )
            .catch( error =>
            {
                hideLoader()
                notify( {
                    type: "ERROR",
                    message: error.message ? error.message : error,
                    title: error.title ? error.title : "Error!!!"
                } )
            } )
    }

    const assignData = () =>
    {
        var filteredPatientOptions = patientOptions().filter( option => ( option.value.patientId && option.value.patientId === encounter.patientId ) )
        console.log( filteredPatientOptions );
        if ( filteredPatientOptions.length > 0 )
        {
            handlePatientOptionChange( filteredPatientOptions[ 0 ] )
        }

        setEncounterDate( moment( encounter.encounterDate ).local() )
        setData( encounter.recordedData ? encounter.recordedData : '' );
        setClinicalNote( encounter.clinicalRecord ? encounter.clinicalRecord : '' );
        setCptCodes( encounter.cptCodes ? encounter.cptCodes : '' );
        setIcd10Codes( encounter.icd10Codes ? encounter.icd10Codes : '' );
        setInsights( encounter.insights ? encounter.insights : '' );
    }

    const cancelEditFn = () =>
    {
        setEditForm( null )
        assignData();
    }

    const playAudio = () =>
    {
        if ( audioData )
        {
            const bufferData = new Uint8Array( audioData.data );
            const blob = new Blob( [ bufferData ], { type: "audio/mpeg" } )
            const audioUrl = URL.createObjectURL( blob );
            audioRef.current.src = audioUrl;
            audioRef.current.play();
            setIsPlayingAudio( true );

        }
    }
    const stopAudio = () =>
    {
        if ( isPlayingAudio )
        {
            audioRef.current.pause();
            audioRef.current.currentTime = 0;
            setIsPlayingAudio( false );
        }
    }
    const handleAudioEnded = () =>
    {
        setIsPlayingAudio( false );
    };

    const copyToClipboard = ( text ) =>
    {
        navigator.clipboard.writeText( text )
            .then( () =>
            {
                notify( {
                    type: "SUCCESS",
                    message: 'Text copied to clipboard',
                    title: ""
                } )
            } )
            .catch( ( error ) =>
            {
                notify( {
                    type: "ERROR",
                    message: 'Error copying text to clipboard',
                    title: "Error!!!"
                } )
                console.log( error )
            } );
    };


    useEffect( () =>
    {
        if ( encounterId )
        {
            getEncounterDetail(true);
        }
        else
        {
            navigate( '/user/encounters' )
        }
    }, [] )

    useEffect( () =>
    {
        if ( encounterId && updateEncounter )
        {
            getEncounterDetail( false , true );
            onEncounterUpdation();
        }
    }, [ updateEncounter ] )

    useEffect( () =>
    {
        if ( encounter )
        {
            assignData()
        }
    }, [ encounter, patients ] )

    const tabList = () =>
    {
        var tabs = []

        tabs.push( {
            label: encounter.isTranscriptionUsed ? ( encounter.isTranscriptionModeConversation ? 'Conversation' : 'Dictation' ) : 'Medical Notes',
            value: 'data'
        } )

        if ( encounter.isClinicalNoteGenerated )
        {
            tabs.push( {
                label: 'Clinical Note',
                value: 'clinical_note'
            } )
        }
        if ( encounter.isInsightsGenerated )
        {
            tabs.push( {
                label: 'Medical Insights',
                value: 'insights'
            } )
        }
        if ( encounter.isCptCodesGenerated )
        {
            tabs.push( {
                label: 'CPT Codes',
                value: 'cpt_code'
            } )
        }
        if ( encounter.isIcd10CodesGenerated )
        {
            tabs.push( {
                label: 'ICD 10 Codes',
                value: 'icd_10_code'
            } )
        }


        return tabs
    }

    const splitTabList = () =>
    {
        var tabs = []

        if (
            tabValue !== 'data'
        )
        {
            tabs.push( {
                label: encounter.isTranscriptionUsed ? ( encounter.isTranscriptionModeConversation ? 'Conversation' : 'Dictation' ) : 'Medical Notes',
                value: 'data'
            } )
        }


        if ( encounter.isClinicalNoteGenerated && tabValue !== 'clinical_note' )
        {
            tabs.push( {
                label: 'Clinical Note',
                value: 'clinical_note'
            } )
        }
        if ( encounter.isInsightsGenerated && tabValue !== 'insights' )
        {
            tabs.push( {
                label: 'Medical Insights',
                value: 'insights'
            } )
        }
        if ( encounter.isCptCodesGenerated && tabValue !== 'cpt_code' )
        {
            tabs.push( {
                label: 'CPT Codes',
                value: 'cpt_code'
            } )
        }
        if ( encounter.isIcd10CodesGenerated && tabValue !== 'icd_10_code' )
        {
            tabs.push( {
                label: 'ICD 10 Codes',
                value: 'icd_10_code'
            } )
        }


        return tabs
    }


    const getElementOfTab = ( tabValue, isEdit = false ) =>
    {

        if ( tabValue === 'data' )
        {

            return (
                isEdit && !encounter.isApproved && !encounter.isClinicalNoteGenerated ?
                    (
                        <div className='tabElement'>
                            <div className='rightButtons'>
                                {
                                    editForm === 'data' ?
                                        (
                                            <div className='buttons'>
                                                <MainIconButton title={ 'Save' } fontSize={ 20 } padding='2px' iconName={ 'save_rounded' } selectedColor={ colors.successColor } disabled={ encounter.recordedData === data || data.trim() === '' } onClick={ () => { updateEncounterData() } } />
                                                <MainIconButton title={ 'Cancel' } fontSize={ 20 } padding='2px' iconName={ 'highlight_off_rounded' } selectedColor={ colors.errorColor } onClick={ () => { cancelEditFn() } } />
                                            </div>
                                        )

                                        :
                                        (
                                            <div className='buttons'>
                                                <MainIconButton title={ 'Edit' } fontSize={ 20 } padding='2px' iconName={ 'edit_outlined' } selectedColor={ colors.primaryColor } onClick={ () => { setEditForm( 'data' ); assignData() } } />
                                                <MainIconButton title={ 'Copy' } fontSize={ 20 } padding='2px' iconName={ 'file_copy_outlined' } selectedColor={ colors.primaryColor } onClick={ () => { copyToClipboard( encounter.recordedData ) } } />
                                            </div>
                                        )
                                }
                            </div>
                            {
                                editForm === 'data' ?
                                    (

                                        <div className='field'>
                                            <MainTextArea rows={ `${ data.split( '\n' ).length + 5 }` } title={ encounter.isTranscriptionUsed ? ( encounter.isTranscriptionModeConversation ? 'Conversation' : 'Dictation' ) : 'Medical Notes' } required maxLength={ 9000 } placeholder={ encounter.isTranscriptionUsed ? ( encounter.isTranscriptionModeConversation ? 'Conversation' : 'Dictation' ) : 'Medical Notes' } value={ data } onChange={ ( e ) => { setData( e.target.value ) } } />
                                        </div>
                                    )
                                    :
                                    (
                                        <div className='dataText'>
                                            { encounter.recordedData }
                                        </div>
                                    )

                            }

                        </div>
                    )
                    :
                    (
                        <div className='tabElement'>
                            <div className='rightButtons'>
                                <MainIconButton title={ 'Copy' } fontSize={ 20 } padding='2px' iconName={ 'file_copy_outlined' } selectedColor={ colors.primaryColor } onClick={ () => { copyToClipboard( encounter.recordedData ) } } />
                            </div>
                            <div className='dataText'>
                                { encounter.recordedData }
                            </div>
                        </div>
                    )

            )
        }
        else if ( tabValue === 'clinical_note' )
        {
            return (
                isEdit && !encounter.isApproved ?
                    (
                        <div className='tabElement'>
                            <div className='rightButtons'>
                                {
                                    editForm === 'clinical_note' ?
                                        (
                                            <div className='buttons'>
                                                <MainIconButton title={ 'Save' } fontSize={ 20 } padding='2px' iconName={ 'save_rounded' } selectedColor={ colors.successColor } disabled={ encounter.clinicalRecord === clinicalNote || clinicalNote.trim() === '' } onClick={ () => { updateEncounterClinicalNote() } } />
                                                <MainIconButton title={ 'Cancel' } fontSize={ 20 } padding='2px' iconName={ 'highlight_off_rounded' } selectedColor={ colors.errorColor } onClick={ () => { cancelEditFn() } } />
                                            </div>
                                        )

                                        :
                                        (
                                            <div className='buttons'>
                                                <MainIconButton title={ 'Edit' } fontSize={ 20 } padding='2px' iconName={ 'edit_outlined' } selectedColor={ colors.primaryColor } onClick={ () => { setEditForm( 'clinical_note' ); assignData() } } />
                                                <MainIconButton title={ 'Copy' } fontSize={ 20 } padding='2px' iconName={ 'file_copy_outlined' } selectedColor={ colors.primaryColor } onClick={ () => { copyToClipboard( encounter.clinicalRecord ) } } />
                                            </div>
                                        )
                                }
                            </div>
                            {
                                editForm === 'clinical_note' ?
                                    (

                                        <div className='field'>
                                            <MainTextArea rows={ `${ clinicalNote.split( '\n' ).length + 5 }` } title={ 'Clinical Note' } required maxLength={ 9000 } placeholder={ 'Clinical Note' } value={ clinicalNote } onChange={ ( e ) => { setClinicalNote( e.target.value ) } } />
                                        </div>
                                    )
                                    :
                                    (
                                        <div className='dataText'>
                                            { encounter.clinicalRecord }
                                        </div>
                                    )

                            }

                        </div>
                    )
                    :
                    (
                        <div className='tabElement'>
                            <div className='rightButtons'>
                                <MainIconButton title={ 'Copy' } fontSize={ 20 } padding='2px' iconName={ 'file_copy_outlined' } selectedColor={ colors.primaryColor } onClick={ () => { copyToClipboard( encounter.clinicalRecord ) } } />
                            </div>
                            <div className='dataText'>
                                { encounter.clinicalRecord }
                            </div>
                        </div>
                    )
            )
        }
        else if ( tabValue === 'cpt_code' )
        {
            return (
                isEdit && !encounter.isApproved ?
                    (
                        <div className='tabElement'>
                            <div className='rightButtons'>
                                {
                                    editForm === 'cpt_code' ?
                                        (
                                            <div className='buttons'>
                                                <MainIconButton title={ 'Save' } fontSize={ 20 } padding='2px' iconName={ 'save_rounded' } selectedColor={ colors.successColor } disabled={ encounter.cptCodes === cptCodes || cptCodes.trim() === '' } onClick={ () => { updateEncounterCptCodes() } } />
                                                <MainIconButton title={ 'Cancel' } fontSize={ 20 } padding='2px' iconName={ 'highlight_off_rounded' } selectedColor={ colors.errorColor } onClick={ () => { cancelEditFn() } } />
                                            </div>
                                        )

                                        :
                                        (
                                            <div className='buttons'>
                                                <MainIconButton title={ 'Edit' } fontSize={ 20 } padding='2px' iconName={ 'edit_outlined' } selectedColor={ colors.primaryColor } onClick={ () => { setEditForm( 'cpt_code' ); assignData() } } />
                                                <MainIconButton title={ 'Copy' } fontSize={ 20 } padding='2px' iconName={ 'file_copy_outlined' } selectedColor={ colors.primaryColor } onClick={ () => { copyToClipboard( encounter.cptCodes ) } } />
                                            </div>
                                        )
                                }
                            </div>
                            {
                                editForm === 'cpt_code' ?
                                    (

                                        <div className='field'>
                                            <MainTextArea rows={ `${ cptCodes.split( '\n' ).length + 5 }` } title={ 'CPT Codes' } required maxLength={ 9000 } placeholder={ 'CPT Codes' } value={ cptCodes } onChange={ ( e ) => { setCptCodes( e.target.value ) } } />
                                        </div>
                                    )
                                    :
                                    (
                                        <div className='dataText'>
                                            { encounter.cptCodes }
                                        </div>
                                    )

                            }

                        </div>
                    )
                    :
                    (
                        <div className='tabElement'>
                            <div className='rightButtons'>
                                <MainIconButton title={ 'Copy' } fontSize={ 20 } padding='2px' iconName={ 'file_copy_outlined' } selectedColor={ colors.primaryColor } onClick={ () => { copyToClipboard( encounter.cptCodes ) } } />
                            </div>
                            <div className='dataText'>
                                { encounter.cptCodes }
                            </div>
                        </div>
                    )
            )

        }
        else if ( tabValue === 'icd_10_code' )
        {
            return (
                isEdit && !encounter.isApproved ?
                    (
                        <div className='tabElement'>
                            <div className='rightButtons'>
                                {
                                    editForm === 'icd_10_code' ?
                                        (
                                            <div className='buttons'>
                                                <MainIconButton title={ 'Save' } fontSize={ 20 } padding='2px' iconName={ 'save_rounded' } selectedColor={ colors.successColor } disabled={ encounter.icd10Codes === icd10Codes || icd10Codes.trim() === '' } onClick={ () => { updateEncounterIcd10Codes() } } />
                                                <MainIconButton title={ 'Cancel' } fontSize={ 20 } padding='2px' iconName={ 'highlight_off_rounded' } selectedColor={ colors.errorColor } onClick={ () => { cancelEditFn() } } />
                                            </div>
                                        )

                                        :
                                        (
                                            <div className='buttons'>
                                                <MainIconButton title={ 'Edit' } fontSize={ 20 } padding='2px' iconName={ 'edit_outlined' } selectedColor={ colors.primaryColor } onClick={ () => { setEditForm( 'icd_10_code' ); assignData() } } />
                                                <MainIconButton title={ 'Copy' } fontSize={ 20 } padding='2px' iconName={ 'file_copy_outlined' } selectedColor={ colors.primaryColor } onClick={ () => { copyToClipboard( encounter.icd10Codes ) } } />
                                            </div>
                                        )
                                }
                            </div>
                            {
                                editForm === 'icd_10_code' ?
                                    (

                                        <div className='field'>
                                            <MainTextArea rows={ `${ icd10Codes.split( '\n' ).length + 5 }` } title={ 'ICD 10 Codes' } required maxLength={ 9000 } placeholder={ 'ICD 10 Codes' } value={ icd10Codes } onChange={ ( e ) => { setIcd10Codes( e.target.value ) } } />
                                        </div>
                                    )
                                    :
                                    (
                                        <div className='dataText'>
                                            { encounter.icd10Codes }
                                        </div>
                                    )

                            }

                        </div>
                    )
                    :
                    (
                        <div className='tabElement'>
                            <div className='rightButtons'>
                                <MainIconButton title={ 'Copy' } fontSize={ 20 } padding='2px' iconName={ 'file_copy_outlined' } selectedColor={ colors.primaryColor } onClick={ () => { copyToClipboard( encounter.icd10Codes ) } } />
                            </div>
                            <div className='dataText'>
                                { encounter.icd10Codes }
                            </div>
                        </div>
                    )
            )
        }
        else if ( tabValue === 'insights' )
        {
            return (
                isEdit && !encounter.isApproved ?
                    (
                        <div className='tabElement'>
                            <div className='rightButtons'>
                                {
                                    editForm === 'insights' ?
                                        (
                                            <div className='buttons'>
                                                <MainIconButton title={ 'Save' } fontSize={ 20 } padding='2px' iconName={ 'save_rounded' } disabled={ encounter.insights === insights || insights.trim() === '' } selectedColor={ colors.successColor } onClick={ () => { updateEncounterInsights() } } />
                                                <MainIconButton title={ 'Cancel' } fontSize={ 20 } padding='2px' iconName={ 'highlight_off_rounded' } selectedColor={ colors.errorColor } onClick={ () => { cancelEditFn() } } />
                                            </div>
                                        )

                                        :
                                        (
                                            <div className='buttons'>
                                                <MainIconButton title={ 'Edit' } fontSize={ 20 } padding='2px' iconName={ 'edit_outlined' } selectedColor={ colors.primaryColor } onClick={ () => { setEditForm( 'insights' ); assignData() } } />
                                                <MainIconButton title={ 'Copy' } fontSize={ 20 } padding='2px' iconName={ 'file_copy_outlined' } selectedColor={ colors.primaryColor } onClick={ () => { copyToClipboard( encounter.insights ) } } />
                                            </div>
                                        )
                                }
                            </div>
                            {
                                editForm === 'insights' ?
                                    (

                                        <div className='field'>
                                            <MainTextArea rows={ `${ insights.split( '\n' ).length + 5 }` } title={ 'Medical Insights' } required maxLength={ 9000 } placeholder={ 'Medical Insights' } value={ insights } onChange={ ( e ) => { setInsights( e.target.value ) } } />
                                        </div>
                                    )
                                    :
                                    (
                                        <div className='dataText'>
                                            { encounter.insights }
                                        </div>

                                    )

                            }

                        </div>
                    )
                    :
                    (
                        <div className='tabElement'>
                            <div className='rightButtons'>
                                <MainIconButton title={ 'Copy' } fontSize={ 20 } padding='2px' iconName={ 'file_copy_outlined' } selectedColor={ colors.primaryColor } onClick={ () => { copyToClipboard( encounter.insights ) } } />
                            </div>
                            <div className='dataText'>
                                { encounter.insights }
                            </div>
                        </div>
                    )
            )
        }

        else
        {
            return null
        }
    }

    const completeIconCondition = function () {
      if (encounter && encounter.isClinicalNoteGenerated && !encounter.isApproved && !encounter.includeApproval) {
        if(encounter.isIcd10CodesGenerated && encounter.isCptCodesGenerated && encounter.isInsightsGenerated){
            return true
        }
        else if (encounter.isInsightsGenerated && !encounter.isBillingCodes ){
            return true
        }
        else if (encounter.isCptCodesGenerated && encounter.isIcd10CodesGenerated && !encounter.isMedicalInsights ){
            return true
        }
        else if (!encounter.isBillingCodes && !encounter.isMedicalInsights ){
            return true
        }
      } 
    };





    return (
        encounter ?
            (
                <div className='encounterDetail' >
                    <Alert
                        open={ approveAlert.show }
                        handleClose={ closeApproveAlert }
                        okFn={ () => { approveEncounter() } }
                        cancelFn={ closeApproveAlert }
                        title={ `You are about to approve this item, and once approved, no further changes can be made to this encounter.\n Are you sure you want to proceed and lock this version in?` }
                        okText={ "Approve" }
                        cancelText={ 'Cancel' } />
                    <div className='encounterDetail__header'>
                        <div className='encounterDetail__header--title'>
                            Encounter Details
                        </div>
                        <div className='rightBtn'>
                            <MainIconButton
                                iconName={ 'cached_outlined' }
                                fontSize={ 30 }
                                title='Refresh'
                                onClick={ getEncounterDetail }
                            />
                        </div>
                    </div>
                    <div className='topContent'>
                        <div className='leftArea'>
                            <div className='top'>
                                <div className='patientArea' style={ { padding: encounter.isApproved ? '10px 20px' : '5px 10px' } }>
                                    {
                                        !encounter.isApproved ?
                                            (
                                                <div className='rightButtons'>
                                                    {
                                                        editForm === 'patient' ?
                                                            (
                                                                <div className='buttons'>
                                                                    <MainIconButton title={ 'Save' } fontSize={ 20 } padding='2px' iconName={ 'save_rounded' } selectedColor={ colors.successColor } disabled={ ( selectedPatient.value === 'NewPatient' && ( patient.patientName.trim() === '' || ( patient.dob !== null && !patient.dob.isValid() ) || ( patient.dob !== null && !patient.dob.isBefore() ) ) )|| ( selectedPatient.value.patientId === encounter.patientId ) } onClick={ () => { updateEncounterPatient() } } />
                                                                    <MainIconButton title={ 'Cancel' } fontSize={ 20 } padding='2px' iconName={ 'highlight_off_rounded' } selectedColor={ colors.errorColor } onClick={ () => { cancelEditFn() } } />
                                                                </div>
                                                            )

                                                            :
                                                            (
                                                                <MainIconButton title={ 'Edit' } fontSize={ 20 } padding='2px' iconName={ 'edit_outlined' } selectedColor={ colors.primaryColor } onClick={ () => { setEditForm( 'patient' ); assignData() } } />
                                                            )
                                                    }
                                                </div>
                                            )
                                            :
                                            (
                                                null
                                            )
                                    }

                                    <div className='patient'>
                                        <MainDropdown title={ 'Patient' } placeholder={ 'Patient' } required options={ patientOptions() } value={ selectedPatient } onChange={ handlePatientOptionChange } isDisabled={ !( editForm && editForm === 'patient' ) } />
                                    </div>
                                    <div className={ selectedPatient && !isPatientFieldsDisabled() ? 'show' : 'hide' }>
                                        {
                                            selectedPatient && !isPatientFieldsDisabled() ?
                                                (
                                                    <div className='details'>
                                                        <div className='field'>
                                                            <MainTextField title={ 'Patient / Case Name' } placeholder={ 'Patient / Case Name' } required disabled={ isPatientFieldsDisabled() } value={ patient.patientName } onChange={ ( e ) => { setPatient( { ...patient, patientName: e.target.value } ) } } />
                                                        </div>
                                                        <div className='field'>
                                                            <MainTextField title={ 'Patient EMR ID' } placeholder={ 'Patient EMR ID' } disabled={ isPatientFieldsDisabled() } value={ patient.patientEmrId } onChange={ ( e ) => { setPatient( { ...patient, patientEmrId: e.target.value } ) } } />
                                                        </div>
                                                        <div className='field'>
                                                            <MainDatePicker title={ 'Date of Birth' } disabled={ isPatientFieldsDisabled() } onChange={ date => { setPatient( { ...patient, dob: date } ) } } disableFuture value={ patient.dob } />
                                                        </div>
                                                    </div>
                                                )
                                                :
                                                (
                                                    null
                                                )
                                        }
                                    </div>

                                </div>
                                <div className='dateArea' style={ { padding: encounter.isApproved ? '10px 20px' : '5px 10px' } }>
                                    {
                                        !encounter.isApproved ?
                                            (
                                                <div className='rightButtons'>
                                                    {
                                                        editForm === 'date' ?
                                                            (
                                                                <div className='buttons'>
                                                                    <MainIconButton title={ 'Save' } fontSize={ 20 } padding='2px' iconName={ 'save_rounded' } selectedColor={ colors.successColor } disabled={ encounterDate && encounterDate.format( 'YYYY-MM-DD HH:mm:ssZ' ) === moment( encounter.encounterDate ).local().format( 'YYYY-MM-DD HH:mm:ssZ' ) } onClick={ () => { updateEncounterDate() } } />
                                                                    <MainIconButton title={ 'Cancel' } fontSize={ 20 } padding='2px' iconName={ 'highlight_off_rounded' } selectedColor={ colors.errorColor } onClick={ () => { cancelEditFn() } } />
                                                                </div>
                                                            )

                                                            :
                                                            (
                                                                <MainIconButton title={ 'Edit' } fontSize={ 20 } padding='2px' iconName={ 'edit_outlined' } selectedColor={ colors.primaryColor } onClick={ () => { setEditForm( 'date' ); assignData() } } />
                                                            )
                                                    }
                                                </div>
                                            )
                                            :
                                            (
                                                null
                                            )
                                    }

                                    <div className='date'>
                                        <MainDateTimePicker title={ 'Encounter Date' } required value={ encounterDate } onChange={ date => { setEncounterDate( date ) } } disabled={ !( editForm && editForm === 'date' ) } />
                                    </div>

                                </div>
                            </div>

                            <div className={ `majorArea ${ encounter.isTranscribed && !encounter.isApproved && !completeIconCondition() ? 'action' : '' }` }>
                                {
                                    encounter.isTranscribed ?
                                        (
                                            encounter.isApproved ?
                                                (
                                                    <div className='approve'>
                                                        <IconBadge
                                                            title='Approved'
                                                            iconName={ 'task_alt_outlined' }
                                                            selectedColor={ colors.successColor }
                                                        />
                                                        <div className='text'>Approved</div>
                                                    </div>
                                                )
                                                :
                                                (
                                                    encounter.isClinicalNoteGenerated ?
                                                        (
                                                            encounter.clinicalRecord === null || encounter.clinicalRecord === '' || encounter.clinicalRecord.length < characterCountMinimumForLearning ?
                                                                (
                                                                    <div className='statusBox error'>
                                                                        <div className='text'>
                                                                            The clinical note does not contain sufficient healthcare or patient information for further processing.
                                                                        </div>
                                                                    </div>
                                                                )
                                                                :
                                                                (

                                                                    <div className='buttons'>
                                                                        {
                                                                            !encounter.isInsightsGenerated && encounter.isMedicalInsights ?
                                                                                (
                                                                                    <MainButton
                                                                                        text={ 'Medical Insights' }
                                                                                        onClick={ generateMedicalInsights }
                                                                                        filled
                                                                                        hoverColor={ colors.blackColor }
                                                                                        textColor={ colors.whiteColor }

                                                                                    />
                                                                                )
                                                                                :
                                                                                (
                                                                                    null
                                                                                )
                                                                        }
                                                                        {
                                                                            !encounter.isCptCodesGenerated && !encounter.isIcd10CodesGenerated && encounter.isBillingCodes ?

                                                                                (
                                                                                    <MainButton
                                                                                        text={ 'Billing Codes' }
                                                                                        filled
                                                                                        onClick={ generateBillingCode }
                                                                                        hoverColor={ colors.blackColor }
                                                                                        textColor={ colors.whiteColor }

                                                                                    />
                                                                                )
                                                                                :
                                                                                (
                                                                                    <>
                                                                                        {
                                                                                            !encounter.isCptCodesGenerated && encounter.isBillingCodes ?
                                                                                                (
                                                                                                    <MainButton
                                                                                                        text={ 'Generate CPT Code' }
                                                                                                        filled
                                                                                                        onClick={ generateCPTCode }
                                                                                                        hoverColor={ colors.blackColor }
                                                                                                        textColor={ colors.whiteColor }

                                                                                                    />
                                                                                                )
                                                                                                :
                                                                                                (
                                                                                                    null
                                                                                                )
                                                                                        }

                                                                                        {
                                                                                            !encounter.isIcd10CodesGenerated && encounter.isBillingCodes ?
                                                                                                (
                                                                                                    <MainButton
                                                                                                        text={ 'Generate ICD Code' }
                                                                                                        filled
                                                                                                        onClick={ generateICD10Code }
                                                                                                        hoverColor={ colors.blackColor }
                                                                                                        textColor={ colors.whiteColor }
                                                                                                    />
                                                                                                )
                                                                                                :
                                                                                                (
                                                                                                    null
                                                                                                )
                                                                                        }
                                                                                    </>
                                                                                )
                                                                        }
                                                                        {   
                                                                            encounter.includeApproval ?
                                                                                (
                                                                                    <MainButton
                                                                                        text={'Approve'}
                                                                                        onClick={openApproveAlert}
                                                                                        filled
                                                                                        color={colors.secondaryColor}
                                                                                        hoverColor={colors.blackColor}
                                                                                        textColor={colors.whiteColor}

                                                                                    />
                                                                                ) : (
                                                                                    null
                                                                                    )
                                                                                }
                                                                        {
                                                                            (completeIconCondition()) ?
                                                                                (
                                                                                    <div className='approve'>
                                                                                        <IconBadge
                                                                                            title='Completed'
                                                                                            iconName={'task_alt_outlined'}
                                                                                            selectedColor={colors.successColor}
                                                                                        />
                                                                                        <div className='text'>Documents have been generated successfully</div>
                                                                                    </div>
                                                                                )
                                                                                :
                                                                                (
                                                                                    null
                                                                                )

                                                                        }

                                                                    </div>
                                                                )




                                                        )
                                                        :
                                                        (
                                                            ( encounter.recordedData === null || encounter.recordedData === '' || encounter.recordedData.length < characterCountMinimumForLearning ) ?
                                                                (
                                                                    <div className='statusBox error'>
                                                                        <div className='text'>
                                                                            The provided data does not contain sufficient healthcare or patient information to formulate a clinical note.
                                                                        </div>
                                                                    </div>
                                                                )
                                                                :
                                                                (
                                                                    <div className='buttons'>
                                                                        <MainButton
                                                                            text={ 'Generate Clinical Note' }
                                                                            onClick={ generateClinicalNote }
                                                                            filled
                                                                            hoverColor={ colors.blackColor }
                                                                            textColor={ colors.whiteColor }

                                                                        />
                                                                    </div>
                                                                )

                                                        )
                                                )

                                        )
                                        :
                                        (
                                            <div className='statusBox'>
                                                <div className='text'>
                                                { preferences && preferences.isAutoPilot ? 'Documentation' : 'Transcription'} in progress                                                  
                                                </div>
                                            </div>
                                        )

                                }

                            </div>
                        </div>

                        <div className='rightArea'>

                            <div className='detailArea'>

                                <div className='keyValue'>
                                    <div className='key'>
                                        Type :
                                    </div>
                                    <div className='value'>
                                        { encounter.isTranscriptionUsed ? ( encounter.isTranscriptionModeConversation ? 'Conversation' : 'Dictation' ) : 'Medical Notes' }
                                    </div>
                                </div>
                                {
                                    encounter.isTranscriptionUsed ?
                                        (
                                            <>
                                                <div className='keyValue'>
                                                    <div className='key'>
                                                        Audio :
                                                    </div>
                                                    <div className='value'>
                                                        { encounter.isRecorded ? 'Recorded' : 'Uploaded' }
                                                    </div>
                                                </div>
                                                <div className='keyValue'>
                                                    <div className='key'>
                                                        Format :
                                                    </div>
                                                    <div className='value'>
                                                        { encounter.fileFormat }
                                                    </div>
                                                </div>
                                                <div className='keyValue'>
                                                    <div className='key'>
                                                        Duration :
                                                    </div>
                                                    <div className='value'>
                                                        { encounter.audioDuration }
                                                    </div>
                                                </div>
                                            </>
                                        )
                                        :
                                        (
                                            null
                                        )
                                }
                                <div className='keyValue'>
                                    <div className='key'>
                                        Created :
                                    </div>
                                    <div className='value'>
                                        { formattedDate( encounter.createdDate ) }
                                    </div>
                                </div>
                                {
                                    encounter.isTranscriptionUsed  ?
                                        (
                                            (
                                                <div className='button'>
                                                {audioLoader ? (
                                                    <CircularProgress size={25} />
                                                ) : (
                                                    <div>
                                                        {!showErrorMessage && (
                                                            <MainIconButton
                                                                title={isPlayingAudio ? 'Stop Audio' : 'Play Audio'}
                                                                iconName={isPlayingAudio ? 'stop_circle_outlined' : 'play_circle_outline'}
                                                                selectedColor={colors.primaryColor}
                                                                onClick={() => {
                                                                    setPlayButtonClicked(true);
                                                                    if (encounter.contentLength > config.audioFileDownloadSizeLimit) {
                                                                        setShowErrorMessage(true);
                                                                    } else if (!audioData) {
                                                                        getAudioOfEncounter();
                                                                    } else {
                                                                        isPlayingAudio ? stopAudio() : playAudio();
                                                                    }
                                                                }}
                                                            />
                                                        )}
                                                        {playButtonClicked && encounter.contentLength > config.audioFileDownloadSizeLimit && (
                                                            <div className='error-message'>Audio file exceeds file size limit and cannot be played</div>
                                                        )}
                                                        <audio ref={audioRef} controls hidden onEnded={handleAudioEnded} />
                                                    </div>
                                                )}
                                            </div>
                                            )
                                        )
                                        :
                                        (
                                            null
                                        )
                                }

                            </div>
                        </div>

                    </div>
                    {
                        ( encounter.isClinicalNoteGenerated &&
                            width > 1300 )
                            ?
                            (
                                <div className='splitView'>
                                    <ToggleButtonGroup
                                        value={ isSplitView }
                                        exclusive
                                        className='splitViewToggleGroup'
                                        onChange={ handleChangeOfSplitView }
                                    >
                                        <ToggleButton className='splitViewToggleBtn' value={ true }><VerticalSplit /></ToggleButton>
                                        <ToggleButton className='splitViewToggleBtn' value={ false }><Article /></ToggleButton>
                                    </ToggleButtonGroup>
                                </div>
                            )
                            :
                            (
                                null
                            )

                    }

                    <div className='bottomContent'>
                        {
                            encounter.isTranscribed ?
                                (
                                    <>
                                        { isSplitView && encounter.isClinicalNoteGenerated &&
                                            width > 1300 ?
                                            (
                                                <div className={ `tabView` }>
                                                    <Tabs
                                                        value={ splitTabValue }
                                                        variant="scrollable"
                                                        scrollButtons
                                                        allowScrollButtonsMobile
                                                        onChange={ ( event, newValue ) => { setSplitTabValue( newValue ) } }
                                                    >
                                                        {
                                                            splitTabList().map( tab => (
                                                                <Tab
                                                                    key={ `tab_${ tab.value }` }
                                                                    value={ tab.value }
                                                                    label={ tab.label }
                                                                    wrapped
                                                                />
                                                            ) )
                                                        }
                                                    </Tabs>
                                                    <div className='mainView'>
                                                        { getElementOfTab( splitTabValue ) }
                                                    </div>
                                                </div>
                                            )
                                            :
                                            (
                                                null
                                            ) }

                                        <div className={ `tabView` }>
                                            <Tabs
                                                value={ tabValue }
                                                variant="scrollable"
                                                scrollButtons={ isSplitView && encounter.isClinicalNoteGenerated &&
                                                    width > 1300 ? true : 'auto' }
                                                allowScrollButtonsMobile
                                                onChange={ ( event, newValue ) =>
                                                {
                                                    setTabValue( newValue );
                                                    cancelEditFn();
                                                    if ( newValue === splitTabValue )
                                                    {
                                                        var possibleTabs = splitTabList().filter( tab => tab.value !== newValue )
                                                        if ( possibleTabs.length > 0 )
                                                        {
                                                            setSplitTabValue( possibleTabs[ 0 ].value )
                                                        }
                                                        else
                                                        {
                                                            setSplitTabValue( newValue === 'data' ? 'clinical_note' : 'data' )
                                                        }
                                                    }



                                                } }
                                            >
                                                {
                                                    tabList().map( tab => (
                                                        <Tab
                                                            key={ `tab_${ tab.value }` }
                                                            value={ tab.value }
                                                            label={ tab.label }
                                                            wrapped
                                                        />
                                                    ) )
                                                }
                                            </Tabs>
                                            <div className='mainView'>
                                                { getElementOfTab( tabValue, true ) }
                                            </div>
                                        </div>
                                    </>

                                )
                                :
                                (
                                    null
                                )
                        }
                    </div>
                </div>
            )
            :
            ( null )


    )
}

export default EncounterDetail