import React, { useState } from 'react'
import './dashboardLayout.scss'
import { Outlet } from 'react-router-dom'
import DashboardHeader from '../../components/headers/dashboardHeader/DashboardHeader'
import SideMenu from '../../components/menus/sideMenu/SideMenu';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { sizes } from '../../constants/sizes';
import { openInNewTab } from '../../utils/common';
import config from '../../config/config';

function DashboardLayout ()
{
    const [ showSideMenu, setShowSideMenu ] = useState( true );
    const { width } = useWindowDimensions();
    return (
        <div className='dashboardLayout'>
            <DashboardHeader show={ showSideMenu } setShow={ setShowSideMenu } />
            <div className='dashboardContainer'>
                <SideMenu show={ showSideMenu } setShow={setShowSideMenu}/>
                <div className='content' onClick={()=>{if(width<sizes.md){setShowSideMenu(false)}}}>
                    <Outlet />
                    <div className='bottomArea'>
                        <div className='copyRightText'>Copyright © 2023 <span className='link' onClick={ () => { openInNewTab( config.infralogicsUrl ) } }>Infralogics</span>. All rights reserved</div>
                    </div>
                </div>
            </div>



        </div>
    )
}

export default DashboardLayout