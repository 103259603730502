import React from 'react'
import
{
  createBrowserRouter,
  RouterProvider
} from "react-router-dom";

import './styles/global.scss'

import Home from './pages/home/Home';
import Error from './pages/error/Error';
import About from './pages/about/About';
import PrivacyPolicy from './pages/privacyPolicy/PrivacyPolicy';
import TermsConditions from './pages/termsConditions/TermsCondition';
import ContactUs from './pages/contactUs/ContactUs';
import SignUp from './pages/signUp/SignUp';
import Login from './pages/login/Login';
import PublicRoute from './routes/PublicRoute';
import PrivateRoute from './routes/PrivateRoute';
import DashboardLayout from './layouts/dashboardLayout/DashboardLayout';
import Encounters from './pages/encounters/Encounters';
import MyUsage from './pages/myUsage/MyUsage';
import PatientData from './pages/patientData/PatientData';
import NewEncounter from './pages/newEncounter/NewEncounter';
import ResetPassword from './pages/resetPassword/ResetPassword';
import OrganizationRoute from './routes/OrganizationRoute';
import OrganizationUsage from './pages/organizationUsage/OrganizationUsage';
import Users from './pages/users/Users';
import Patients from './pages/patients/Patients';
import EncounterDetail from './pages/encounterDetail/EncounterDetail';
import IndividualRoute from './routes/IndividualRoute';
import Preference from './pages/preferences/Preference';

function App ()
{
  const router = createBrowserRouter( [
    {
      path: "/",
      element: <Home />
    },
    {

      path: "/",
      element: <PublicRoute />,
      children: [
        {
          path: "login",
          element: <Login />
        }
      ]
    },
    {
      path: "/",
      element: <PrivateRoute />,
      children: [
        {
          path: "/",
          element: <DashboardLayout />,
          children: [
            {
              path: "/user",
              element: <Encounters />
            },
            {
              path: "/user/encounters",
              element: <Encounters />
            },
            {
              path: "/user/patient-view",
              element: <PatientData />
            },
            {
              path: "/user/my-usage",
              element: <MyUsage />
            },
            {
              path: "/user/encounter/new",
              element: <NewEncounter />
            },
            {
              path: "/user/encounter/detail",
              element: <EncounterDetail />
            },
            {
              path: "/user/reset-password",
              element: <ResetPassword />
            },
            {
              path: "/user/preferences",
              element: <Preference />
            },
            {
              path: "/",
              element: <IndividualRoute />,
              children: [
                {
                  path: "/org/patients",
                  element: <Patients />
                },
              ]
            },
            {
              path: "/",
              element: <OrganizationRoute />,
              children: [
                {
                  path: "/org/usage",
                  element: <OrganizationUsage />
                },
                {
                  path: "/org/users",
                  element: <Users />
                },
                {
                  path: "/org/patients",
                  element: <Patients />
                },
              ]
            }


          ]

        }
      ]
    },

    {
      path: "/about",
      element: <About />
    },
    {
      path: "/privacy-policy",
      element: <PrivacyPolicy />
    },
    {
      path: "/terms-conditions",
      element: <TermsConditions />
    },
    {
      path: "/contact-us",
      element: <ContactUs />
    },
    {
      path: "/sign-up",
      element: <SignUp />
    },
    {
      path: "*",
      element: <Error />
    }
  ] );


  return (
    <RouterProvider router={ router } />
  )
}

export default App