import React, { createContext, useState, useEffect, useContext } from 'react'
import { UserContext } from './UserContext';
import socketIOClient from "socket.io-client";
import { getSocketUrl } from '../webservices/webservice';


export const SocketContext = createContext();

export default function SocketContextProvider ( { children } )
{
    const [ updateEncounter, setUpdateEncounter ] = useState( false );
    const { loggedInUserDetails } = useContext( UserContext );

    const onEncounterUpdation = () =>
    {
        setUpdateEncounter( false )
    }

    useEffect( () =>
    {
        var socket = null;

        if ( loggedInUserDetails.user )
        {
            socket = socketIOClient(
                getSocketUrl(),
                {
                    query: {

                        userId: loggedInUserDetails.user.userId
                    }
                }
            );
            socket.on( "updateEncounter", ( { userIds } ) =>
            {
                console.log( 'updateEncounter', userIds )
                if ( userIds.includes( loggedInUserDetails.user.userId ) )
                {
                    setUpdateEncounter( true );
                }
            } );
        }
        else
        {
            if ( socket )
            {
                socket.disconnect();
            }
        }

        return () =>
        {
            if ( socket )
            {
                socket.disconnect();
            }

        }
    }, [ loggedInUserDetails.user ] )





    return (
        <SocketContext.Provider value={ { updateEncounter, onEncounterUpdation } }>
            { children }
        </SocketContext.Provider>
    )
}
