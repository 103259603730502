import React, { createContext, useContext, useReducer } from "react";
import { v4 } from "uuid";
import Notification from "../components/notification/Notification";

export const NotificationContext = createContext();

const NotificationProvider = ( props ) =>
{
    const [ state, dispatch ] = useReducer( ( state, action ) =>
    {
        switch ( action.type )
        {
            case "ADD_NOTIFICATION":
                return [ ...state, { ...action.payload } ];
            case "REMOVE_NOTIFICATION":
                return state.filter( el => el.id !== action.id );
            default:
                return state
        }
    }, [] );

    return (
        <NotificationContext.Provider value={ dispatch }>
            <div style={ { position: 'fixed', top: 10, right: 10, width: '90%', maxWidth: 400, zIndex: 2080 } }>
                { state.map( ( note ) =>
                {
                    return <Notification dispatch={ dispatch } key={ note.id } { ...note } />
                } ) }
            </div>
            { props.children }
        </NotificationContext.Provider>
    )
};

export const useNotification = () =>
{
    const dispatch = useContext( NotificationContext );

    return ( props ) =>
    {
        dispatch( {
            type: "ADD_NOTIFICATION",
            payload: {
                id: v4(),
                ...props
            }
        } )
    }
};

export default NotificationProvider;