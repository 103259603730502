const PROD = "production";
const DEV = "development";
const LOCAL = "local"
const selectedEnv = 1;

function getEnvironment ()
{
    if ( selectedEnv === 1 )
    {
        return PROD
    }
    else if ( selectedEnv === 2 )
    {
        return DEV
    }
    else
    {
        return LOCAL
    }
}

module.exports = {
    serverUrl: (
        getEnvironment() === PROD ?
            'https://api.fasttrackehr.com'
            :
            (
                getEnvironment() === DEV ?
                    'https://api-dev.fasttrackehr.com'
                    :
                    'http://localhost:8500'
            )
    ),
    socketUrl: (
        getEnvironment() === PROD ?
            'https://socket.fasttrackehr.com'
            :
            (
                getEnvironment() === DEV ?
                    'https://socket-dev.fasttrackehr.com'
                    :
                    'http://localhost:8400'
            )
    ),
    infralogicsUrl: 'https://infralogics.us/',
    characterCountMinimumForLearning : 100,
    refreshTime : 13 * 60 * 1000 , //13Mins
    maxAudioFileSize : 75 * 1024 * 1024 ,//75MB
    audioFileDownloadSizeLimit: 20 * 1024 * 1024, //20 MB 
    recordingLimit :1 * 60 * 60 * 1000, // 1 hour
    errorMessageDisplayDuration :  5 * 60 * 1000 // 5 minutes
}