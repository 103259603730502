import React, { useContext, useState } from 'react'
import './login.scss';
import MainTextField from '../../components/textfields/mainTextField/MainTextField';
import MainButton from '../../components/buttons/mainButton/MainButton';
import { colors } from '../../constants/colors';
import { NavLink, useNavigate } from 'react-router-dom';
import { openInNewTab } from '../../utils/common';
import config from '../../config/config';
import { regex } from '../../constants/regex';
import ForgotPassword from '../../modals/forgotPassword/ForgotPassword';
import { LoaderContext } from '../../contexts/LoaderContext';
import { useNotification } from '../../contexts/NotificationContext';
import { loginRequest } from '../../webservices/webservice';
import { UserContext } from '../../contexts/UserContext';

function Login ()
{
    const [ email, setEmail ] = useState( '' )
    const [ password, setPassword ] = useState( '' )
    const [ showForgotPasswordModal, setShowForgotPasswordModal ] = useState( false )
    const navigate = useNavigate();
    const { showLoader, hideLoader } = useContext( LoaderContext );
    const notify = useNotification();
    const { login } = useContext( UserContext );

    const isEmailValid = () =>
    {
        if ( email === "" || regex.mail.test( email ) )
        {
            return true
        }
        else
        {
            return false
        }
    }

    const isFormValid = () =>
    {
        if (
            email.trim() !== '' &&
            isEmailValid() &&
            password !== ''
        )
        {
            return true;
        }
        else
        {
            return false;
        }
    }

    const openForgetPasswordModal = () =>
    {
        setShowForgotPasswordModal( true )
    }

    const closeForgetPasswordModal = () =>
    {
        setShowForgotPasswordModal( false )
    }

    const loginBtnPressed = ()=>{
        showLoader()
        loginRequest(email,password)
        .then(data=>{
            hideLoader();
            console.log(data)
            var user = data.user;
            var token = data.access_token
            var role = user.roleName
            var refreshToken = data.refresh_token
            var expiresIn = data.expires_in
            login(token,user,role, refreshToken, expiresIn);
            if(user.isSysGeneratedPassword){
                navigate('/user/reset-password')
            }
            else{
                navigate('/user/encounters')
            }
        })
        .catch(error=>{
            hideLoader();
            notify( {
                type: "ERROR",
                message: error.message ? error.message : error,
                title: error.title ? error.title : "Error!!!",
            } );
        })
    }

    return (
        <div className='login'>
            <ForgotPassword open={showForgotPasswordModal} handleClose={closeForgetPasswordModal}/>
            <div className='imageArea'>
            </div>
            <div className='loginArea'>
                <div className='titleText'>
                    Welcome to <span style={ { color: colors.primaryColor, display: "block" } }>FastTrack EHR</span>
                </div>
                <div className='separation'>
                    <div className='line'>
                    </div>
                    <div className='separationText'>
                        LOGIN WITH EMAIL
                    </div>
                    <div className='line'>
                    </div>
                </div>
                <div className='loginForm'>
                    <div className='field'>
                        <MainTextField type='text' required title='Email' placeholder='Email' value={ email } onChange={ ( e ) => { setEmail( e.target.value ) } } errorText={ !isEmailValid() ? 'Invalid email.' : '' } />
                    </div>
                    <div className='field'>
                        <MainTextField type='password' required title='Password' placeholder='Password' value={ password } onChange={ ( e ) => { setPassword( e.target.value ) } } />
                    </div>
                    <div className='forgetPassword'>
                        <div className='button' onClick={openForgetPasswordModal}>Forgot Your Password?</div>
                    </div>
                    <div className='buttons'>
                        <MainButton text={ 'Login' }
                            filled
                            disabled={ !isFormValid() }
                            color={ colors.primaryColor }
                            hoverColor={ colors.blackColor }
                            textColor={ colors.whiteColor }
                            onClick={ loginBtnPressed }
                        />
                        <MainButton text={ 'Sign Up' }
                            filled
                            color={ colors.secondaryColor }
                            hoverColor={ colors.blackColor }
                            textColor={ colors.whiteColor }
                            onClick={ () => { navigate( '/sign-up' ) } }
                        />
                    </div>
                    <div className='privacyTermsSection'>
                        By continuing, you agree to our <NavLink to={ '/terms-conditions' } className={ 'navLink' } >Terms & Conditions</NavLink>  and <NavLink to={ '/privacy-policy' } className={ 'navLink' } >Privacy Policy</NavLink>
                    </div>
                </div>
                <div className='bottomArea'>
                    <div className='copyRightText'>Copyright © 2023 <span className='link' onClick={ () => { openInNewTab( config.infralogicsUrl ) } }>Infralogics</span>. All rights reserved</div>
                </div>

            </div>

        </div>
    )
}

export default Login