import React from 'react'
import './termsConditions.scss'
import MainHeader from '../../components/headers/mainHeader.js/MainHeader';
import { colors } from '../../constants/colors';
import MainButton from '../../components/buttons/mainButton/MainButton';
import { useNavigate } from 'react-router-dom';
import Footer from '../../components/footer/Footer';

function TermsConditions ()
{
    const navigate = useNavigate();
    return (
        <div className='termsConditions'>
            <MainHeader />
            <div className='titleArea'>
                <div className='title'><span style={ { color: colors.primaryColor } }>Terms & Conditions</span></div>
                <div className='description'>Legal document</div>
            </div>
            <div className='mainSection'>
                <div className='textArea'>
                    <div className='title'>Terms & Conditions for FastTrack EHR</div>
                    <div className='detail'>By accessing or using FastTrack EHR software, you agree to abide by these terms and conditions.</div>
                    <div className='title'>1. Use of Software</div>
                    <div className='point'>FastTrack EHR is licensed for use, not sold. Users must comply with applicable laws and regulations.</div>
                    <div className='point'>Users are responsible for maintaining the confidentiality of their account credentials.</div>
                    <div className='title'>2. User Conduct</div>
                    <div className='point'>Users agree not to misuse or interfere with the software's functionality, security, or access.</div>
                    <div className='point'>Users are prohibited from uploading malicious content or violating the rights of others.</div>
                    <div className='title'>3. Intellectual Property</div>
                    <div className='point'>All software content, trademarks, and intellectual property remain the property of FastTrack EHR.</div>
                    <div className='point'>Users are granted a limited, non-exclusive license to use the software as intended.</div>
                    <div className='title'>4. Data Privacy</div>
                    <div className='point'>FastTrack EHR respects user privacy and handles data in accordance with its Privacy Policy.</div>
                    <div className='point'>Users are responsible for the accuracy and legality of the data they input into the software.</div>
                    <div className='title'>5. Limitation of Liability</div>
                    <div className='point'>FastTrack EHR is not liable for any damages arising from the use or inability to use the software.</div>
                    <div className='point'>Users agree to indemnify and hold FastTrack EHR harmless from any claims or liabilities.</div>
                    <div className='title'>6. Modifications and Termination</div>
                    <div className='point'>FastTrack EHR reserves the right to modify, suspend, or terminate access to the software at any time.</div>
                    <div className='point'>Users will be notified of significant changes to the terms and conditions.</div>
                    <div className='title'>7. Governing Law</div>
                    <div className='point'>These terms are governed by and construed in accordance with the laws of United States of America, without regard to conflicts of law principles.</div>
                    <div className='title'>8. Contact Information</div>
                    <div className='point'>For questions or concerns about these terms and conditions, users can contact through following link</div>
                    <div className='button'>
                        <MainButton
                            text={ 'Contact US' }
                            filled
                            hoverColor={ colors.blackColor }
                            textColor={ colors.whiteColor }
                            onClick={ () => { navigate( '/contact-us' ) } }
                        />
                    </div>

                </div>

            </div>
            <Footer />
        </div>
    )
}

export default TermsConditions