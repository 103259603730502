import axios from 'axios';
import config from '../config/config';
import { getRefeshToken, getToken, getTokenExpiry, removeUserSession, setNewSession } from "../session/user";


let requestMethod = {
    post: 'POST',
    get: 'GET'
}

let serverUrl = config.serverUrl
let socketUrl = config.socketUrl
let baseUrl = `${ serverUrl }/api/`;


let relativeUrl = {
    refreshToken: 'general/token/refresh',
    login: 'user/login',
    getCountryCodes: 'general/geo/country-codes',
    sendContact: 'general/contact-details',
    forgotPassword: 'user/password/forgot',
    resetPassword: 'user/password/new',
    getUserUsage: 'user/user/usage',
    getOrgUsage: 'user/org/usage',
    getAllPatients: 'user/patient',
    createEncounterWithNote: 'user/encounter/new/data',
    createEncounterWithTranscription: 'user/encounter/new/transcribe',
    searchEncounters: 'user/encounter/search',
    getAllUsers: 'user/user',
    getUserUsageAdmin: 'user/user/usage-admin',
    getEncounterDetail: 'user/encounter/get',
    getAudioOfEncounter: 'user/audio/encounter',
    generateClinicalNote: 'user/encounter/generate-record',
    generateICD10Code: 'user/encounter/generate-icd10-codes',
    generateCPTCode: 'user/encounter/generate-cpt-codes',
    approveEncounter: 'user/encounter/approve',
    deleteEncounter: 'user/encounter/delete',
    generateMedicalInsights: 'user/encounter/generate-insights',
    updateEncounterDate: 'user/encounter/date',
    updateEncounterPatient: 'user/encounter/patient',
    updateEncounterData: 'user/encounter/data',
    updateEncounterClinicalNote: 'user/encounter/clinic-record',
    updateEncounterCptCodes: 'user/encounter/cpt-codes/update',
    updateEncounterIcd10Codes: 'user/encounter/icd10-codes/update',
    updateEncounterInsights: 'user/encounter/insights/update',
    getPatients: 'user/patient/all',
    updatePatients: 'user/patient/update',
    deletePatient: 'user/patient/delete',
    activatePreference: 'user/preference/activate',
    deactivatePreference: 'user/preference/deactivate',
    getPreference: 'user/preference',
    AddPatient: 'user/patient/add'
}


const makeRequest = ( method, relativeUrl, data, authorised ) =>
{
    return new Promise( function ( resolve, reject )
    {
        checkAndUpdateToken( authorised )
            .then( function ( status )
            {
                var options = {
                    'method': method,
                    'url': baseUrl + relativeUrl,
                    'data': data
                };
                if ( authorised )
                {
                    options[ 'headers' ] = { 'Authorization': 'Bearer ' + getToken() };
                }
                axios( options )
                    .then( function ( response )
                    {
                        resolve( response.data );
                    } )
                    .catch( function ( err )
                    {
                        var errorResponse = ( err.response && err.response.data ) ? err.response.data : err;
                        if ( errorResponse )
                        {
                            if(errorResponse.status === 401){
                                removeUserSession();
                                reject( {
                                    title: 'Session Expired',
                                    message: 'Please log in again',
                                    errorDetail: err
                                } );
                            }
                            else{
                                reject( errorResponse );
                            }
                        }
                        else
                        {
                            console.log( err );
                            reject( {
                                message: 'Some Unknown Error',
                                errorDetail: err
                            } );
                        }
                    } )
            } )
            .catch( function ( err )
            {
                reject( err );
            } )
    } )
}

const checkAndUpdateToken = ( authorised ) =>
{
    return new Promise( function ( resolve, reject )
    {
        if ( authorised )
        {
            var tokenExpiry = getTokenExpiry();
            var refreshToken = getRefeshToken();
            var accessToken = getToken();
            if ( tokenExpiry && parseInt( tokenExpiry ) < new Date().getTime() )
            {
                axios( {
                    'method': requestMethod.post,
                    'url': baseUrl + relativeUrl.refreshToken,
                    'data': {
                        accessToken,
                        refreshToken
                    }
                } )
                    .then( function ( response )
                    {
                        console.log( response )
                        var info = response.data.data;
                        setNewSession( info.access_token, info.user, info.user.roleName, info.refresh_token, info.expires_in );
                        resolve( true )
                    } )
                    .catch( function ( err )
                    {
                        removeUserSession();
                        reject( {
                            title: 'Session Expired',
                            message: 'Please log in again',
                            errorDetail: err
                        } );
                    } )
            }
            else
            {
                resolve( true )
            }
        }
        else
        {
            resolve( true )
        }
    } )
}

const checkAndUpdateAccessToken = ( authorised ) =>
{
    return new Promise( function ( resolve, reject )
    {
        if ( authorised )
        {
            var refreshToken = getRefeshToken();
            var accessToken = getToken();
                axios( {
                    'method': requestMethod.post,
                    'url': baseUrl + relativeUrl.refreshToken,
                    'data': {
                        accessToken,
                        refreshToken
                    }
                } )
                    .then( function ( response )
                    {
                        console.log( response )
                        var info = response.data.data;
                        setNewSession( info.access_token, info.user, info.user.roleName, info.refresh_token, info.expires_in );
                        resolve( true )
                    } )
                    .catch( function ( err )
                    {
                        removeUserSession();
                        reject( {
                            title: 'Session Expired',
                            message: 'Please log in again',
                            errorDetail: err
                        } );
                    } )
            

        }
        else
        {
            resolve( true )
        }
    } )
}



export const getServerUrl = () =>
{
    return ( serverUrl );
}

export const getSocketUrl = () =>
{
    return ( socketUrl );
}

//Check Token Request

export const checkTokenRequest = () =>
{
    return new Promise( function ( resolve, reject )
    {
        checkAndUpdateToken( true )
            .then( function ( response )
            {
                resolve( response )
            } )
            .catch( function ( err )
            {
                reject( err )
            } )
    } )
}

//Check refresh token for audio

export const checkAcessTokenRequest = () =>
{
    return new Promise( function ( resolve, reject )
    {
        checkAndUpdateAccessToken( true )
            .then( function ( response )
            {
                resolve( response )
            } )
            .catch( function ( err )
            {
                reject( err )
            } )
    } )
}


export const getCountryCodesRequest = () =>
{
    return new Promise( function ( resolve, reject )
    {
        makeRequest( requestMethod.get, relativeUrl.getCountryCodes,
            null, false )
            .then( function ( response )
            {
                resolve( response.data )
            } )
            .catch( function ( err )
            {
                reject( err )
            } )
    } )

}


export const sendContactRequest = ( name, email, phoneNumber, countryCode, message ) =>
{
    return new Promise( function ( resolve, reject )
    {
        makeRequest( requestMethod.post, relativeUrl.sendContact,
            { name, email, phoneNumber, countryCode, message }, false )
            .then( function ( response )
            {
                resolve( response.message )
            } )
            .catch( function ( err )
            {
                reject( err )
            } )
    } )

}



export const forgotPasswordRequest = ( email ) =>
{
    return new Promise( function ( resolve, reject )
    {
        makeRequest( requestMethod.post, relativeUrl.forgotPassword,
            { email }, false )
            .then( function ( response )
            {
                resolve( response.message )
            } )
            .catch( function ( err )
            {
                reject( err )
            } )
    } )

}


export const loginRequest = ( email, password ) =>
{
    return new Promise( function ( resolve, reject )
    {
        makeRequest( requestMethod.post, relativeUrl.login,
            { email, password }, false )
            .then( function ( response )
            {
                resolve( response.data )
            } )
            .catch( function ( err )
            {
                reject( err )
            } )
    } )

}


export const resetPasswordRequest = ( oldPassword, newPassword ) =>
{
    return new Promise( function ( resolve, reject )
    {
        makeRequest( requestMethod.post, relativeUrl.resetPassword,
            { oldPassword, newPassword }, true )
            .then( function ( response )
            {
                resolve( response.message )
            } )
            .catch( function ( err )
            {
                reject( err )
            } )
    } )

}


export const getUserUsageRequest = ( month, year ) =>
{
    return new Promise( function ( resolve, reject )
    {
        makeRequest( requestMethod.post, relativeUrl.getUserUsage,
            { month, year }, true )
            .then( function ( response )
            {
                resolve( response.data )
            } )
            .catch( function ( err )
            {
                reject( err )
            } )
    } )

}

export const getOrgUsageRequest = ( month, year ) =>
{
    return new Promise( function ( resolve, reject )
    {
        makeRequest( requestMethod.post, relativeUrl.getOrgUsage,
            { month, year }, true )
            .then( function ( response )
            {
                resolve( response.data )
            } )
            .catch( function ( err )
            {
                reject( err )
            } )
    } )

}



export const getAllPatientsRequest = () =>
{
    return new Promise( function ( resolve, reject )
    {
        makeRequest( requestMethod.get, relativeUrl.getAllPatients,
            null, true )
            .then( function ( response )
            {
                resolve( response.data )
            } )
            .catch( function ( err )
            {
                reject( err )
            } )
    } )

}

export const createEncounterWithNoteRequest = ( patientId, patientCaseName, patientEmrId, patientDetails, data, encounterDate ) =>
{
    return new Promise( function ( resolve, reject )
    {
        makeRequest( requestMethod.post, relativeUrl.createEncounterWithNote,
            { patientId, patientCaseName, patientEmrId, patientDetails, data, encounterDate }, true )
            .then( function ( response )
            {
                resolve( response.data )
            } )
            .catch( function ( err )
            {
                reject( err )
            } )
    } )

}

export const createEncounterWithTranscriptionRequest = ( patientId, patientCaseName, patientEmrId, patientDetails, encounterDate, audioFile, audioDuration, isRecorded, isConversation ) =>
{
    return new Promise( function ( resolve, reject )
    {
        var bodyFormData = new FormData();
        bodyFormData.append( 'audio', audioFile );
        bodyFormData.append( 'encounterDate', encounterDate );
        bodyFormData.append( 'audioDuration', audioDuration );
        if ( patientId )
        {
            bodyFormData.append( 'patientId', patientId )
        }
        if ( patientCaseName )
        {
            bodyFormData.append( 'patientCaseName', patientCaseName )
        }
        if ( patientEmrId )
        {
            bodyFormData.append( 'patientEmrId', patientEmrId )
        }
        if ( patientDetails )
        {
            bodyFormData.append( 'patientDetails', JSON.stringify( patientDetails ) )
        }
        if ( isRecorded )
        {
            bodyFormData.append( 'isRecorded', isRecorded )
        }
        if ( isConversation )
        {
            bodyFormData.append( 'isConversation', isConversation )
        }


        makeRequest( requestMethod.post, relativeUrl.createEncounterWithTranscription, bodyFormData, true )
            .then( function ( response )
            {
                resolve( response.data )
            } )
            .catch( function ( err )
            {
                reject( err )
            } )
    } )
}


export const searchEncountersRequest = ( patientId, isTranscribed, isClinicalNoteGenerated, isApproved, encounterDate, emcounterDataTz, isCptCodesGenerated, isIcd10CodesGenerated ) =>
{
    return new Promise( function ( resolve, reject )
    {
        makeRequest( requestMethod.post, relativeUrl.searchEncounters,
            {
                patientId,
                isTranscribed,
                isClinicalNoteGenerated,
                isApproved,
                encounterDate,
                emcounterDataTz,
                isCptCodesGenerated,
                isIcd10CodesGenerated
            }, true )
            .then( function ( response )
            {
                resolve( response.data )
            } )
            .catch( function ( err )
            {
                reject( err )
            } )
    } )

}

export const getAllUsersRequest = () =>
{
    return new Promise( function ( resolve, reject )
    {
        makeRequest( requestMethod.get, relativeUrl.getAllUsers,
            null, true )
            .then( function ( response )
            {
                resolve( response.data )
            } )
            .catch( function ( err )
            {
                reject( err )
            } )
    } )

}


export const getUserOrgUsageRequest = ( userId, month, year ) =>
{
    return new Promise( function ( resolve, reject )
    {
        makeRequest( requestMethod.post, relativeUrl.getUserUsageAdmin,
            { userId, month, year }, true )
            .then( function ( response )
            {
                resolve( response.data )
            } )
            .catch( function ( err )
            {
                reject( err )
            } )
    } )

}

export const getEncounterDetailRequest = ( encounterId ) =>
{
    return new Promise( function ( resolve, reject )
    {
        makeRequest( requestMethod.post, relativeUrl.getEncounterDetail,
            { encounterId }, true )
            .then( function ( response )
            {
                resolve( response.data )
            } )
            .catch( function ( err )
            {
                reject( err )
            } )
    } )

}

export const getAudioOfEncounterRequest = ( encounterId ) =>
{
    return new Promise( function ( resolve, reject )
    {
        makeRequest( requestMethod.post, relativeUrl.getAudioOfEncounter,
            { encounterId }, true )
            .then( function ( response )
            {
                resolve( response.data )
            } )
            .catch( function ( err )
            {
                reject( err )
            } )
    } )

}


export const generateClinicalNoteRequest = ( encounterId ) =>
{
    return new Promise( function ( resolve, reject )
    {
        makeRequest( requestMethod.post, relativeUrl.generateClinicalNote,
            { encounterId }, true )
            .then( function ( response )
            {
                resolve( response.message )
            } )
            .catch( function ( err )
            {
                reject( err )
            } )
    } )

}

export const generateICD10CodeRequest = ( encounterId ) =>
{
    return new Promise( function ( resolve, reject )
    {
        makeRequest( requestMethod.post, relativeUrl.generateICD10Code,
            { encounterId }, true )
            .then( function ( response )
            {
                resolve( response.message )
            } )
            .catch( function ( err )
            {
                reject( err )
            } )
    } )

}

export const generateCPTCodeRequest = ( encounterId ) =>
{
    return new Promise( function ( resolve, reject )
    {
        makeRequest( requestMethod.post, relativeUrl.generateCPTCode,
            { encounterId }, true )
            .then( function ( response )
            {
                resolve( response.message )
            } )
            .catch( function ( err )
            {
                reject( err )
            } )
    } )

}


export const approveEncounterRequest = ( encounterId ) =>
{
    return new Promise( function ( resolve, reject )
    {
        makeRequest( requestMethod.post, relativeUrl.approveEncounter,
            { encounterId }, true )
            .then( function ( response )
            {
                resolve( response.message )
            } )
            .catch( function ( err )
            {
                reject( err )
            } )
    } )

}

export const deleteEncounterRequest = ( encounterId ) =>
{
    return new Promise( function ( resolve, reject )
    {
        makeRequest( requestMethod.post, relativeUrl.deleteEncounter,
            { encounterId }, true )
            .then( function ( response )
            {
                resolve( response.message )
            } )
            .catch( function ( err )
            {
                reject( err )
            } )
    } )

}

export const generateMedicalInsightsRequest = ( encounterId ) =>
{
    return new Promise( function ( resolve, reject )
    {
        makeRequest( requestMethod.post, relativeUrl.generateMedicalInsights,
            { encounterId }, true )
            .then( function ( response )
            {
                resolve( response.message )
            } )
            .catch( function ( err )
            {
                reject( err )
            } )
    } )

}


export const updateEncounterDateRequest = ( encounterId, encounterDate ) =>
{
    return new Promise( function ( resolve, reject )
    {
        makeRequest( requestMethod.post, relativeUrl.updateEncounterDate,
            { encounterId, encounterDate }, true )
            .then( function ( response )
            {
                resolve( response.message )
            } )
            .catch( function ( err )
            {
                reject( err )
            } )
    } )

}


export const updateEncounterPatientRequest = ( encounterId, patientId, patientCaseName, patientEmrId, patientDetails, ) =>
{
    return new Promise( function ( resolve, reject )
    {
        makeRequest( requestMethod.post, relativeUrl.updateEncounterPatient,
            { encounterId, patientId, patientCaseName, patientEmrId, patientDetails }, true )
            .then( function ( response )
            {
                resolve( response.message )
            } )
            .catch( function ( err )
            {
                reject( err )
            } )
    } )

}


export const updateEncounterDataRequest = ( encounterId, data ) =>
{
    return new Promise( function ( resolve, reject )
    {
        makeRequest( requestMethod.post, relativeUrl.updateEncounterData,
            { encounterId, data }, true )
            .then( function ( response )
            {
                resolve( response.message )
            } )
            .catch( function ( err )
            {
                reject( err )
            } )
    } )

}

export const updateEncounterClinicalNoteRequest = ( encounterId, clinicalRecord ) =>
{
    return new Promise( function ( resolve, reject )
    {
        makeRequest( requestMethod.post, relativeUrl.updateEncounterClinicalNote,
            { encounterId, clinicalRecord }, true )
            .then( function ( response )
            {
                resolve( response.message )
            } )
            .catch( function ( err )
            {
                reject( err )
            } )
    } )

}


export const updateEncounterCptCodesRequest = ( encounterId, cptCodes ) =>
{
    return new Promise( function ( resolve, reject )
    {
        makeRequest( requestMethod.post, relativeUrl.updateEncounterCptCodes,
            { encounterId, cptCodes }, true )
            .then( function ( response )
            {
                resolve( response.message )
            } )
            .catch( function ( err )
            {
                reject( err )
            } )
    } )

}


export const updateEncounterIcd10CodesRequest = ( encounterId, icd10Codes ) =>
{
    return new Promise( function ( resolve, reject )
    {
        makeRequest( requestMethod.post, relativeUrl.updateEncounterIcd10Codes,
            { encounterId, icd10Codes }, true )
            .then( function ( response )
            {
                resolve( response.message )
            } )
            .catch( function ( err )
            {
                reject( err )
            } )
    } )

}


export const updateEncounterInsightsRequest = ( encounterId, insights ) =>
{
    return new Promise( function ( resolve, reject )
    {
        makeRequest( requestMethod.post, relativeUrl.updateEncounterInsights,
            { encounterId, insights }, true )
            .then( function ( response )
            {
                resolve( response.message )
            } )
            .catch( function ( err )
            {
                reject( err )
            } )
    } )

}
export const getPatientsRequest = () =>
{
    return new Promise( function ( resolve, reject )
    {
        makeRequest( requestMethod.get, relativeUrl.getPatients,
            null, true )
            .then( function ( response )
            {
                resolve( response.data )
            } )
            .catch( function ( err )
            {
                reject( err )
            } )
    } )

}

export const updatePatientRequest = ( patientId, patientName, patientEmrId, patientDetails ) =>
{
    return new Promise( function ( resolve, reject )
    {
        makeRequest( requestMethod.post, relativeUrl.updatePatients,
            { patientId, patientName, patientEmrId, patientDetails }, true )
            .then( function ( response )
            {
                resolve( response )
            } )
            .catch( function ( err )
            {
                reject( err )
            } )
    } )

}

export const deletePatientRequest = ( patientId ) =>
{
    return new Promise( function ( resolve, reject )
    {
        makeRequest( requestMethod.post, relativeUrl.deletePatient,
            { patientId }, true )
            .then( function ( response )
            {
                resolve( response.message )
            } )
            .catch( function ( err )
            {
                reject( err )
            } )
    } )
}


export const activatePreferenceRequest = ( preference ) => 
{
    return new Promise( function ( resolve, reject )
    {
        makeRequest( requestMethod.post, relativeUrl.activatePreference, preference, true )
            .then( function ( response )
            {
                resolve( response.message )
            } )
            .catch( function ( error )
            {
                reject( error )
            } )
    } )
}


export const deactivatePreferenceRequest = ( preference ) => 
{
    return new Promise( function ( resolve, reject )
    {
        makeRequest( requestMethod.post, relativeUrl.deactivatePreference, preference, true )
            .then( function ( response )
            {
                resolve( response.message )
            } )
            .catch( function ( error )
            {
                reject( error )
            } )
    } )
}

export const getPreferenceRequest = () => 
{
    return new Promise( function ( resolve, reject )
    {
        makeRequest( requestMethod.get, relativeUrl.getPreference, null, true )
            .then( function ( response )
            {
                resolve( response.data )
            } )
            .catch( function ( error )
            {
                reject( error )
            } )
    } )
}

export const addPatientRequest = (patientData) =>
{
    return new Promise( function ( resolve, reject )
    {
        makeRequest( requestMethod.post, relativeUrl.AddPatient, {patientData}, true )
            .then( function ( response )
            {
                resolve( response.message )
            } )
            .catch( function ( err )
            {
                reject( err )
            } )
    } )

}