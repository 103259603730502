import React, { useContext, useEffect, useState } from 'react'
import './patientData.scss'
import { LoaderContext } from '../../contexts/LoaderContext';
import { useNotification } from '../../contexts/NotificationContext';
import { DataStoreContext } from '../../contexts/DataStoreContext';
import MainDropdown from '../../components/dropdowns/mainDropdown/MainDropdown'
import { deleteEncounterRequest, searchEncountersRequest } from '../../webservices/webservice';
import {  Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { formattedDate } from '../../utils/date';
import IconBadge from '../../components/badges/iconBadge/IconBadge';
import { colors } from '../../constants/colors';
import MainIconButton from '../../components/buttons/mainIconButton/MainIconButton';
import { useLocation, useNavigate } from 'react-router-dom';
import Alert from '../../modals/alert/Alert';


function PatientData ()
{
  const { showLoader, hideLoader } = useContext( LoaderContext )
  const notify = useNotification();
  const { state } = useLocation()
  const [ encounters, setEncounters ] = useState( [] );
  const [ selectedPatient, setSelectedPatient ] = useState(  );
  const { patients } = useContext( DataStoreContext )
  const [ deleteAlert, setDeleteAlert ] = useState( {
    show: false,
    encounter: null
  } )
  const navigate = useNavigate();

  const navigateToEncounterDetailPage = ( encounter ) =>
  {
    navigate( '/user/encounter/detail', { state: { encounterId: encounter.encounterId } } )
  }

  const patientOptions = () =>
  {
    var options = []
    patients.forEach( patient =>
    {
      options.push( {
        value: patient,
        label: `${ patient.patientName }${ patient.patientEmrId ? ` | EMR ${ patient.patientEmrId } ` : '' }${ patient.patientDetails && patient.patientDetails.dob ? ` | DOB ${ patient.patientDetails.dob }` : '' }`
      } )
    } );

    return options

  }

  const deleteEncounter = ( encounterId ) =>
  {
    showLoader()
    deleteEncounterRequest( encounterId )
      .then( function ( message )
      {
        hideLoader()
        closeDeleteAlert();
        getEncountersForAPatient( selectedPatient.value.patientId );
        notify( {
          type: "SUCCESS",
          message: message,
          title: "Success!!!"
        } )
      } )
      .catch( function ( error )
      {
        hideLoader()
        notify( {
          type: "ERROR",
          message: error.message ? error.message : error,
          title: error.title ? error.title : "Error!!!"
        } )
      } )
  }

  const openDeleteAlert = ( encounter ) =>
  {
    setDeleteAlert( {
      show: true,
      encounter: encounter
    } )
  }


  const closeDeleteAlert = () =>
  {
    setDeleteAlert( {
      show: false,
      encounter: null
    } )
  }



  const getEncountersForAPatient = ( patientId ) =>
  {
    showLoader();
    searchEncountersRequest(
      patientId,
      null,
      null,
      null,
      null,
      null,
      null,
      null
    )
      .then( ents =>
      {
        hideLoader()
        console.log( ents )
        setEncounters( ents )
      } )
      .catch( error =>
      {
        hideLoader()
        notify( {
          type: "ERROR",
          message: error.message ? error.message : error,
          title: error.title ? error.title : "Error!!!"
        } )
      } )
  }


  const handleChangeInSelectedPatient = ( selectedOption ) =>
  {
    setSelectedPatient( selectedOption )
    if ( selectedOption )
    {
      getEncountersForAPatient( selectedOption.value.patientId )
    }
  }

  useEffect (() => {
    if(state)
    {
      handleChangeInSelectedPatient( {
      value: state.patient,
      label: `${ state.patient.patientName }${ state.patient.patientEmrId ? ` | EMR ${ state.patient.patientEmrId } ` : '' }${ state.patient.patientDetails && state.patient.patientDetails.dob ? ` | DOB ${ state.patient.patientDetails.dob }` : '' }`
    } )
    }
    

  },[state])

  return (
    <div className='patientData'>
      <Alert
        open={ deleteAlert.show }
        handleClose={ closeDeleteAlert }
        okFn={ () => { deleteEncounter( deleteAlert.encounter.encounterId ) } }
        cancelFn={ closeDeleteAlert }
        title={ deleteAlert.show ? `Are you sure you want to delete the encounter with ${ deleteAlert.encounter.patientCaseName } (#${ deleteAlert.encounter.patientEmrId }) ?` : '' }
        okText={ "Delete" }
        cancelText={ 'Cancel' } />
        <div className='selector'>
          <MainDropdown
            title={ 'Patient' }
            placeholder={ 'Select a patient' }
            value={selectedPatient}
            options={ patientOptions() }
            onChange={ handleChangeInSelectedPatient }
            isClearable
          />
      </div> 
      {
        selectedPatient ?
          (
            <Paper className='paper' sx={ { overflow: "auto", maxHeight: '70vh' } }>
              <TableContainer className='tableContainer' sx={ { width: '100%', display: 'table', tableLayout: 'fixed' } } >
                <Table className='table' stickyHeader sx={ { minWidth: 800 } } >
                  {
                    encounters.length === 0 ?
                      ( <caption className="caption">No Encounters Found!</caption> )
                      :
                      ( null )
                  }
                  <TableHead className='head'>
                    <TableRow>
                      <TableCell className='cell'  >
                        Date
                      </TableCell >
                      <TableCell className='cell'>
                        Patient
                      </TableCell>
                      <TableCell className='cell' align='center'>
                        Type
                      </TableCell>
                      <TableCell className='cell' align='center'>
                        Clinical Note
                      </TableCell>
                      <TableCell className='cell' align='center' >
                        Medical Insights
                      </TableCell>
                      <TableCell className='cell' align='center'>
                        Billing Codes
                      </TableCell>
                      <TableCell className='cell' align='center'>
                        Approval
                      </TableCell>
                      <TableCell className='cell' align='center' >
                        Created
                      </TableCell>
                      <TableCell className='cell' align='center'>
                        Actions
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className='body'>
                    { encounters.map( encounter => (
                      <TableRow className='row' key={ `encounter${ encounter.encounterId }` }>
                        <TableCell className='cell'><div className='text'>{ formattedDate( encounter.encounterDate ) }</div></TableCell>
                        <TableCell className='cell'>
                          <div className='patient'>
                            <div className='patientName'>{ encounter.patientCaseName }</div>
                            {
                              encounter.patientEmrId ?
                                (
                                  <div className='patientEmrId'>EMR { encounter.patientEmrId }</div>
                                )
                                :
                                (
                                  null
                                )
                            }
                          </div>
                        </TableCell>
                        <TableCell className='cell' align='center'>
                          <div className='type'>
                            <div className='typeText'>
                              {
                                encounter.isTranscriptionUsed ?
                                  (
                                    encounter.isTranscriptionModeConversation ?
                                      'Conversation'
                                      :
                                      'Dictation'
                                  )
                                  :
                                  (
                                    'Medical Notes'
                                  )
                              }
                            </div>
                            {
                              encounter.isTranscriptionUsed ?
                                (
                                  <div className='status' style={ { backgroundColor: encounter.isTranscribed ? colors.successColor : colors.inProgressColor } }>
                                    { encounter.isTranscribed ?
                                      'Transcribed'
                                      :
                                      'In Progress' }
                                  </div>
                                )
                                :
                                (
                                  null
                                )
                            }
                          </div>
                        </TableCell>
                        <TableCell className="cell" align='center'>
                          <div className='badge'>
                            {
                              encounter.isClinicalNoteGenerated ?
                                (
                                  <IconBadge
                                    title='Clinical Note Generated'
                                    iconName={ 'task_alt_outlined' }
                                    selectedColor={ colors.successColor }
                                  />
                                )
                                :
                                (
                                  <IconBadge
                                    title='Clinical Note Pending'
                                    iconName={ 'error_outline_outlined' }
                                    selectedColor={ colors.greyColor }
                                  />
                                )
                            }
                          </div>
                        </TableCell>
                        <TableCell className="cell" align='center'>
                          <div className='badge'>
                            {
                              encounter.isInsightsGenerated ?
                                (
                                  <IconBadge
                                    title='Medical Insights Generated'
                                    iconName={ 'task_alt_outlined' }
                                    selectedColor={ colors.successColor }
                                  />
                                )
                                :
                                (
                                  <IconBadge
                                    title='Medical Insights Pending'
                                    iconName={ 'error_outline_outlined' }
                                    selectedColor={ colors.greyColor }
                                  />
                                )
                            }
                          </div>
                        </TableCell>
                        <TableCell className="cell" align='center'>
                          <div className='badge'>
                            {
                              encounter.isCptCodesGenerated && encounter.isIcd10CodesGenerated ?
                                (
                                  <IconBadge
                                    title='Billing Codes Generated'
                                    iconName={ 'task_alt_outlined' }
                                    selectedColor={ colors.successColor }
                                  />
                                )
                                :
                                (
                                  <IconBadge
                                    title='Billing Codes Pending'
                                    iconName={ 'error_outline_outlined' }
                                    selectedColor={ colors.greyColor }
                                  />
                                )
                            }
                          </div>
                        </TableCell>
                        <TableCell className="cell" align='center'>
                          <div className='badge'>
                            {
                              encounter.isApproved ?
                                (
                                  <IconBadge
                                    title='Approved'
                                    iconName={ 'task_alt_outlined' }
                                    selectedColor={ colors.successColor }
                                  />
                                )
                                :
                                (
                                  <IconBadge
                                    title='Pending Approval'
                                    iconName={ 'error_outline_outlined' }
                                    selectedColor={ colors.greyColor }
                                  />
                                )
                            }
                          </div>
                        </TableCell>
                        <TableCell className='cell' align='center'><div className='text'>{ formattedDate( encounter.createdDate ) }</div></TableCell>
                        <TableCell className='cell' align='center'>
                          <div className='buttons'>
                            <MainIconButton iconName={ 'text_snippet_outlined' } title='Encounter Details' defaultColor={ colors.primaryColor } selectedColor={ colors.blackColor } onClick={ () => { navigateToEncounterDetailPage( encounter ) } } />
                            <MainIconButton iconName={ 'cancel_outlined' } title='Delete' defaultColor={ colors.errorColor } selectedColor={ colors.blackColor } onClick={ () => { openDeleteAlert( encounter ) } } />
                          </div>
                        </TableCell>
                      </TableRow>
                    ) ) }
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          )
          :
          (
            null
          )
      }

    </div>
  )
}

export default PatientData