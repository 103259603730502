import React, { createContext, useState, useEffect } from 'react'
import { setUserSession, removeUserSession, getUser, getRole, getTokenExpiry } from '../session/user';
import { checkTokenRequest } from '../webservices/webservice';

export const UserContext = createContext();

export default function UserContextProvider ( { children } )
{
    const [ loggedInUserDetails, setLoggedInUserDetails ] = useState( {
        user: getUser(),
        role: getRole(),
    } );

    const login = ( token, user, role, refreshToken, expiresIn ) =>
    {
        setUserSession( token, user, role, refreshToken, expiresIn )
        setLoggedInUserDetails( {
            user: user,
            role: role
        } )
    }

    const logOut = () =>
    {
        removeUserSession();
    }



    useEffect( () =>
    {
        setLoggedInUserDetails( {
            user: getUser(),
            role: getRole()
        } )

        if ( loggedInUserDetails.user && loggedInUserDetails.role )
        {
            checkTokenRequest()
                .then( ( response ) =>
                {
                    console.log( response )
                }
                )
                .catch( ( err ) =>
                {
                    console.log( err )
                }
                )
        }


    }, [] )

    useEffect( () =>
    {
        window.addEventListener( 'storage', e =>
        {
            console.log(e)
            setLoggedInUserDetails( {
                user: getUser(),
                role: getRole()
            } )
        } )
        return () =>
        {
            window.removeEventListener( 'storage', e => console.log( e ) )
        }
    }, [] )

    return (
        <UserContext.Provider value={ { login, logOut, loggedInUserDetails } }>
            { children }
        </UserContext.Provider>
    )
}