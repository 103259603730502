import React from 'react'
import './mainTextField.scss'

function MainTextField ( { title,required=false, errorText = '', ...props } )
{
    return (
        <div className='mainTextField'>
            <div className='title'>{ `${title}${required ? '*' : ''}`} </div>
            <input className={ `input ${ errorText ? 'error' : '' }` } { ...props } />
            {
                errorText ?
                    (
                        <div className='errorText'>{ errorText }</div>

                    )
                    :
                    (
                        null
                    )
            }
        </div>

    )
}

export default MainTextField