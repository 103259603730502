import React, { useContext, useEffect, useState } from 'react'
import './users.scss'
import { LoaderContext } from '../../contexts/LoaderContext';
import { useNotification } from '../../contexts/NotificationContext';
import { getAllUsersRequest } from '../../webservices/webservice';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { formattedDate } from '../../utils/date';
import IconBadge from '../../components/badges/iconBadge/IconBadge';
import { colors } from '../../constants/colors';
import MainIconButton from '../../components/buttons/mainIconButton/MainIconButton';
import UserInfo from '../../modals/userInfo/UserInfo';


function Users ()
{
    const { showLoader, hideLoader } = useContext( LoaderContext )
    const notify = useNotification();
    const [ users, setUsers ] = useState( [] );
    const [ infoModal, setInfoModal ] = useState( {
        show: false,
        user: null
    } )

    const openInfoModal = ( user ) =>
    {
        setInfoModal( {
            show: true,
            user: user
        } )
    }


    const closeInfoModal = () =>
    {
        setInfoModal( {
            show: false,
            user: null
        } )
    }

    const getUsers = () =>
    {
        showLoader();
        getAllUsersRequest()
            .then( usrs =>
            {
                hideLoader();
                console.log( usrs )
                setUsers(usrs)
            } )
            .catch( error =>
            {
                hideLoader()
                notify( {
                    type: "ERROR",
                    message: error.message ? error.message : error,
                    title: error.title ? error.title : "Error!!!"
                } )
            } )
    }

    useEffect(() => {
      getUsers()
    }, [])
    


    return (
        <div className='users'>
            <UserInfo
                open={ infoModal.show }
                handleClose={ closeInfoModal }
                user={ infoModal.user }
            />
            <div className='users__title'>
                Users({ users.length })
            </div>
            <Paper className='paper' sx={ { overflow: "auto", maxHeight: '70vh' } }>
                <TableContainer className='tableContainer' sx={ { width: '100%', display: 'table', tableLayout: 'fixed' } } >
                    <Table className='table' stickyHeader sx={ { minWidth: 800 } } >
                        {
                            users.length === 0 ?
                                ( <caption className="caption">No Users Found!</caption> )
                                :
                                ( null )
                        }
                        <TableHead className='head'>
                            <TableRow>
                                <TableCell className='cell'  >
                                    Role
                                </TableCell >
                                <TableCell className='cell'>
                                    Name
                                </TableCell>
                                <TableCell className='cell'>
                                    Contact
                                </TableCell>
                                <TableCell className='cell' align='center'>
                                    Status
                                </TableCell>
                                <TableCell className='cell' align='center' >
                                    Created
                                </TableCell>
                                <TableCell className='cell' align='center'>
                                    Actions
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody className='body'>
                            { users.map( user => (
                                <TableRow className='row' key={ `encounter${ user.userId }` }>
                                    <TableCell className='cell'><div className='roleName'>{ user.roleName }</div></TableCell>
                                    <TableCell className='cell'>
                                        <div className='name'>
                                            { user.userPrefix }. {user.userFirstName} {user.userLastName}
                                        </div>
                                    </TableCell>
                                    <TableCell className='cell'>
                                        <div className='contact'>
                                            <div className='single'>
                                                <IconBadge title='Email' placement='left' iconName={'mail_outlined'} />
                                                <div className='single__text'>{user.userEmail}</div>
                                            </div>
                                            <div className='single'>
                                                <IconBadge title='Phone' placement='left' iconName={ 'local_phone_outlined' } />
                                                <div className='single__text'>+{user.userCountrycode} { user.userPhone }</div>
                                            </div>
                                        </div>
                                    </TableCell>
                                    <TableCell className="cell" align='center'>
                                        <div className='badge'>
                                            {
                                                user.isActiveUser && user.isActiveOrganization ?
                                                    (
                                                        <IconBadge
                                                            title='Active'
                                                            iconName={ 'task_alt_outlined' }
                                                            selectedColor={ colors.successColor }
                                                        />
                                                    )
                                                    :
                                                    (
                                                        <IconBadge
                                                            title='Inactive'
                                                            iconName={ 'error_outline_outlined' }
                                                            selectedColor={ colors.errorColor }
                                                        />
                                                    )
                                            }
                                        </div>
                                    </TableCell>
                                    <TableCell className='cell' align='center'><div className='text'>{ formattedDate( user.createdDate ) }</div></TableCell>
                                    <TableCell className='cell' align='center'>
                                        <div className='buttons'>
                                            <MainIconButton iconName={ 'info_outlined' } title='Usage' selectedColor={ colors.primaryColor } onClick={ () => {openInfoModal(user) } } />
                                            
                                        </div>
                                    </TableCell>
                                </TableRow>
                            ) ) }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>

        </div>
    )
}

export default Users