var moment = require( "moment" );


export const formattedDate= ( dateString ) =>
{
    var date = moment( dateString ).local().calendar( null, {
        sameDay: 'D MMM, hh:mm a',
        nextDay: 'D MMM, hh:mm a',
        nextWeek: 'D MMM, hh:mm a',
        lastDay: 'D MMM, hh:mm a',
        lastWeek: 'D MMM, hh:mm a',
        sameYear: 'D MMM, hh:mm a',
        sameElse: 'D MMM Y, hh:mm a'
    } );
    return date;
}

export const formatDateDDMMYY = (dateString) => {
    var date = moment(dateString).format('MM/DD/YYYY');
    return date;
}

export const secondsToShow = ( seconds ) =>
{
    return ( moment.utc( 1000 * seconds ).format( 'mm:ss' ) );
}

export const monthToShow = (date) =>
{
    return (moment(date).format('MMM YYYY'))
}

export const momentOfdate = (date) =>{
    return (moment(date))
}