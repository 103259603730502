import React, { useContext, useEffect, useState } from 'react'
import { LoaderContext } from '../../contexts/LoaderContext';
import { Modal } from '@mui/material';
import { useNotification } from '../../contexts/NotificationContext';
import MainIconButton from '../../components/buttons/mainIconButton/MainIconButton';
import './editPatient.scss';
import MainDatePicker from '../../components/dateTimePickers/mainDatePicker/MainDatePicker';
import MainTextField from '../../components/textfields/mainTextField/MainTextField';
import MainButton from '../../components/buttons/mainButton/MainButton';
import { colors } from '../../constants/colors'
import { updatePatientRequest } from '../../webservices/webservice';
import { formatDateDDMMYY, momentOfdate } from '../../utils/date';
import moment from 'moment';

function EditPatient ( { open, handleDataChange, handleClose, patient = null } )
{
    const [ data, setData ] = useState( {
        patientId: null,
        patientName: '',
        patientEmrId: '',
        dob: null
    } );
    const { showLoader, hideLoader } = useContext( LoaderContext )
    const notify = useNotification();

    const isFormValid = () =>
    {
        if (
            data.patientName &&
            ( data.dob === null ||
                (data.dob.isValid() && data.dob.isBefore()) )
        )
        {
            return true
        }
        else
        {
            return false
        }

    }

    const isValueChanged = () =>
    {
        if (
            data.patientName.trim() === patient.patientName &&
            data.patientEmrId.trim() === ( patient.patientEmrId ? patient.patientEmrId : "" ) &&
            ( data.dob ?
                data.dob.format( 'MM/DD/YYYY' )
                :
                null
            ) ===
            (
                patient.patientDetails &&
                    patient.patientDetails.dob ?
                    patient.patientDetails.dob
                    :
                    null
            )
        )
        {
            return false
        }
        else
        {
            return true
        }

    }

    const closeFunction = () =>
    {
        setData( {
            patientId: null,
            patientName: '',
            patientEmrId: '',
            dob: null
        } )
        handleClose()
    }
    const assignData = () =>
    {
        setData( {
            patientId: patient.patientId,
            patientName: patient.patientName,
            patientEmrId: patient.patientEmrId ? patient.patientEmrId : '',
            dob: patient.patientDetails && patient.patientDetails.dob ? moment( patient.patientDetails.dob, 'MM/DD/YYYY' ) : null
        } )
    }

    const updatePatient = () =>
    {
        showLoader()
        var patientDetail = {
        }
        if ( data.dob !== null )
        {
            patientDetail[ 'dob' ] = data.dob.format( 'MM/DD/YYYY' )
        }
        updatePatientRequest(
            data.patientId,
            data.patientName.trim(),
            data.patientEmrId.trim() === "" ? null : data.patientEmrId.trim(),
            Object.keys( patientDetail ).length > 0 ? patientDetail : null
        )
            .then( ( response ) =>
            {
                hideLoader()
                handleDataChange()
                notify( {
                    type: "SUCCESS",
                    message: response.message,
                    title: "Success!!!"
                } )
                handleClose()
            } )
            .catch( ( error ) =>
            {
                hideLoader()
                notify( {
                    type: "ERROR",
                    message: error.message ? error.message : error,
                    title: error.title ? error.title : "Error!!!"
                } )
            } )
    }

    useEffect( () =>
    {
        if ( patient )
        {
            assignData()
        }

    }, [ open, patient ] )
    // useEffect(() => {
    //     isFormValid()
    // }, [data])
    return (
        <Modal className='editPatient' open={ open }>
            <div className='box'>
                {
                    ( data && patient ) ?
                        (
                            <>
                                <div className='header'>
                                    <div className='title'>Edit</div>
                                    <MainIconButton iconName={ 'highlight_off' } onClick={ closeFunction } />
                                </div>

                                <div className='mainContent'>
                                    <div className='details'>
                                        <div className='top'>
                                            <MainTextField title={ 'Patient / Case Name' } placeholder={ 'Patient / Case Name' } required disabled={ false } value={ data.patientName } onChange={ ( e ) =>
                                            {
                                                setData( { ...data, patientName: e.target.value } )
                                            } } />
                                        </div>
                                        <div className='bottom'>
                                            <MainTextField title={ 'Patient EMR ID' } placeholder={ 'Patient EMR ID' } disabled={ false } value={ data.patientEmrId } onChange={ ( e ) =>
                                            {
                                                setData( { ...data, patientEmrId: e.target.value } )
                                            } } />
                                            <MainDatePicker
                                                title={ 'Date of Birth' }
                                                disabled={ false }
                                                onChange={ date =>
                                                {
                                                    console.log( date )
                                                    setData( { ...data, dob: date } )
                                                } }
                                                clearable
                                                disableFuture
                                                value={ data.dob }
                                            />
                                        </div>
                                    </div>
                                    <div className='button'>
                                        <MainButton
                                            text={ 'Save' }
                                            disabled={ !( isFormValid() && isValueChanged() ) }
                                            filled
                                            hoverColor={ colors.blackColor }
                                            textColor={ colors.whiteColor }
                                            onClick={ () => { updatePatient() } }
                                        />
                                    </div>
                                </div>

                            </>
                        )
                        :
                        (
                            null
                        )
                }


            </div>
        </Modal>
    )
}

export default EditPatient