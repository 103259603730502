import React from 'react'
import './about.scss'
import aboutImg from '../../images/about.jpeg';
import MainHeader from '../../components/headers/mainHeader.js/MainHeader';
import { colors } from '../../constants/colors';
import MainButton from '../../components/buttons/mainButton/MainButton';
import { useNavigate } from 'react-router-dom';
import Footer from '../../components/footer/Footer';

function About ()
{
    const navigate = useNavigate();
    return (
        <div className='about'>
            <MainHeader />
            <div className='titleArea'>
                <div className='title'><span style={ { color: colors.primaryColor } }>About</span> Us</div>
                <div className='description'>Who we are?</div>
            </div>
            <div className='mainSection'>
                <div className='imgArea'>
                    <img className='image' src={ aboutImg } alt='about' />
                </div>
                <div className='textArea'>
                    <div className='title'>Welcome to Our Healthcare Technology Platform</div>
                    <div className='detail'>At Infralogics, we are committed to revolutionizing healthcare through innovative technologies. Our FastTrackEHR product harnesses the power of AI to streamline medical documentation, ensuring accurate and efficient healthcare record management.</div>
                    <div className='title'>Our Mission</div>
                    <div className='detail'>Our mission is to empower healthcare providers with cutting-edge tools that simplify patient-doctor interactions, transform conversations into comprehensive records, and enhance the quality of care.</div>
                    <div className='title'>What We Offer</div>
                    <div className='point'><span className='main'>Advanced AI Technology:</span>  Our software uses state-of-the-art technologies to transform unstructured clinical data into EHR ready Health records, optimizing healthcare workflows.</div>
                    <div className='point'><span className='main'>Efficient Record-Keeping:</span>  We provide a HIPPA compliant solution for storing and managing patient data, ensuring privacy and security at all levels.</div>
                    <div className='point'><span className='main'>Dedicated Support:</span>  Our team is committed to support healthcare professionals, by reducing their administrative tasks & enabling them to spend more time with patients.</div>

                    <div className='title'>Our Commitment</div>
                    <div className='detail'>At Infralogics, we prioritize accuracy, efficiency, and security. Our FastTrackEHR Product, offered as SaaS model on Cloud platform adheres to strict data privacy and security standards to safeguard patient information and enable healthcare providers to focus on delivering exceptional care.</div>
                    <div className='title'>Join Us in Transforming Healthcare</div>
                    <div className='detail'>We invite you to experience the future of healthcare documentation with us. Together, let’s revolutionize the way medical records are created, managed, and utilized for better patient outcomes.</div>
                    <div className='button'>
                        <MainButton
                            text={ 'Contact US' }
                            filled
                            hoverColor={ colors.blackColor }
                            textColor={ colors.whiteColor }
                            onClick={ () => { navigate( '/contact-us' ) } }
                        />
                    </div>

                </div>

            </div>
            <Footer />
        </div>
    )
}

export default About