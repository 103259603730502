import React from 'react'
import './dashboardHeader.scss'
import { useNavigate } from 'react-router-dom';
import logoComplete from '../../../images/logoComplete.png'
import logo from '../../../images/logo.png'
import AccountMenu from '../../menus/accountMenu/AccountMenu';
import MainIconButton from '../../buttons/mainIconButton/MainIconButton';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import { sizes } from '../../../constants/sizes';


function DashboardHeader ( { show, setShow } )
{
    const { width } = useWindowDimensions();
    const navigate = useNavigate();
    return (
        <React.Fragment>
            <div className='dashboardHeader'>
                <div className='left' style={ { flexDirection: width < sizes.md ? 'row-reverse' : 'row' } }>
                    <div className='imageArea'>
                        <img className='logo' style={ { width: width < sizes.md ? 50 : ( show ? 200 : 50 ) } } src={ width < sizes.md ? logo : ( show ? logoComplete : logo ) } alt='logo' onClick={ () => { navigate( '/' ) } } />
                    </div>
                    <div className='menuIcon'>
                        <MainIconButton iconName={ 'menu' } fontSize={ 30 } onClick={ () => { setShow( !show ) } } />
                    </div>
                </div>
                <div className='right'>
                    <AccountMenu />
                </div>
            </div>

        </React.Fragment>

    )
}

export default DashboardHeader