import React, { useContext, useEffect, useState } from "react";
import "./preference.scss";
import { FormControlLabel, FormGroup, Switch } from "@mui/material";
import { LoaderContext } from "../../contexts/LoaderContext";
import { useNotification } from "../../contexts/NotificationContext";
import {
  activatePreferenceRequest,
  deactivatePreferenceRequest,
  getPreferenceRequest,
} from "../../webservices/webservice";
import { colors } from '../../constants/colors'
import { DataStoreContext } from "../../contexts/DataStoreContext";

export default function Preference() {
  const [preference, setPreference] = useState(null);
  const { showLoader, hideLoader } = useContext(LoaderContext);
  const {updateData} = useContext(DataStoreContext)
  const notify = useNotification();


  const handleChange = (event) => {
    console.log(event.target.checked, event.target.value);
    setPreference({
      ...preference,
      [event.target.value]: event.target.checked,
    });
    if (event.target.checked === true) {
      showLoader();
      activatePreferenceRequest({ [event.target.value]: true })
        .then((message) => {
          getPrefrence()
          updateData()
          hideLoader();
          notify({
            type: "SUCCESS",
            message: message,
            title: "Success!!!",
          });
        })
        .catch((error) => {
          hideLoader();
          notify({
            type: "ERROR",
            message: error.message ? error.message : error,
            title: error.title ? error.title : "Error!!!",
          });
        });
    } else {
      deactivatePreferenceRequest({ [event.target.value]: false })
        .then((message) => {
          getPrefrence()
          updateData()
          hideLoader();
          notify({
            type: "SUCCESS",
            message: message,
            title: "Success!!!",
          });
        })
        .catch((error) => {
          hideLoader();
          notify({
            type: "ERROR",
            message: error.message ? error.message : error,
            title: error.title ? error.title : "Error!!!",
          });
        });
    }
  };

 
  const getPrefrence = () => {
    showLoader();
    getPreferenceRequest()
      .then((values) => {
        hideLoader();
        setPreference(values);
      })
      .catch((error) => {
        hideLoader();
        notify({
          type: "ERROR",
          message: error.message ? error.message : error,
          title: error.title ? error.title : "Error!!!",
        });
      });
  }

  useEffect(() => {
    getPrefrence();
  }, []);

  return (
    <div className="preference">
      {preference ? (
        <div>
          <div className="encounters__header">
            <div className="encounters__header--title">Preferences</div>
          </div>
          <div className="box">
            <div className="toggleBox">
              <div className="left">
                <div className="title">Auto Pilot</div>
                <div className="description">Automates the generation of documents</div>
              </div>

              <Switch
                value={"isAutoPilot"}
                checked={preference.isAutoPilot}
                onChange={handleChange}
                size="small"
                className="switch"
              />
            </div>
            <div className="toggleBox">
              <div className="left">
                <div className="title">Medical Insights</div>
                <div className="description">Enable the generation of Medical Insights</div>
              </div>

              <Switch
                value={"isMedicalInsights"}
                checked={preference.isMedicalInsights}
                onChange={handleChange}
                size="small"
                className="switch"
              />
            </div>
            <div className="toggleBox">
              <div className="left">
                <div className="title">Billing Codes</div>
                <div className="description">Enable the generation of CPT Codes and ICD-10 Codes</div>
              </div>
              <Switch
                value={"isBillingCodes"}
                checked={preference.isBillingCodes}
                onChange={handleChange}
                size="small"
                className="switch"
              />
            </div>
            <div className="toggleBox">
              <div className="left">
                <div className="title">Approval</div>
                <div className="description">Enable approval feature for medical encounters, optimizing workflow transparency.</div>
              </div>
              <Switch
                value={"includeApproval"}
                checked={preference.includeApproval}
                onChange={handleChange}
                size="small"
                className="switch"
              />
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}
