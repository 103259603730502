import React, { useContext, useEffect, useState } from 'react'
import { LoaderContext } from '../../contexts/LoaderContext';
import { getUserOrgUsageRequest } from '../../webservices/webservice';
import { Icon, Modal } from '@mui/material';
import { useNotification } from '../../contexts/NotificationContext';
import MainIconButton from '../../components/buttons/mainIconButton/MainIconButton';
import './userInfo.scss';
import MainDatePicker from '../../components/dateTimePickers/mainDatePicker/MainDatePicker';
import { monthToShow } from '../../utils/date';
import moment from 'moment';

function UserInfo({ open, handleClose, user = null }) {
    const [data, setData] = useState({});
    const { showLoader, hideLoader } = useContext(LoaderContext)
    const [date,setDate] = useState(moment())
    const notify = useNotification();

    const getUserInfo = (month, year) => {
        showLoader()
        getUserOrgUsageRequest(user.userId, month, year)
            .then(usageData => {
                hideLoader();
                console.log(usageData)
                setData(usageData)
            })
            .catch(error => {
                hideLoader()
                notify({
                    type: "ERROR",
                    message: error.message ? error.message : error,
                    title: error.title ? error.title : "Error!!!"
                })
            })
    }

    const handleDateChange = (date) => {
        setDate(date)
        if(date)
        {
            getUserInfo(date.month() +1 , date.year())
        }
        else{
            getUserInfo(null , null);
        }
    }

    useEffect(() => {
        if (open && user) {
            getUserInfo(moment().month() +1 , moment().year());
            setDate(moment())
        }
    }, [open, user])
    return (
        <Modal className='userInfo' open={open}>
            <div className='box'>
                {
                    user ?
                        (
                            <>
                                <div className='header'>
                                    <div className='title'>{user.userPrefix}. {user.userFirstName} {user.userLastName} ({user.roleName})</div>
                                    <MainIconButton iconName={'highlight_off'} onClick={handleClose} />
                                </div>
                                
                                    <div className='head'>
                                        <div className='heading'>
                                            User Info
                                        </div>
                                        <div className='drop-downs'>
                                        <MainDatePicker title={ 'Month and Year' } views={ [ 'year', 'month', ]} onChange={(e) => { handleDateChange(e) }}  disableFuture value={date} />
                                        </div>
                                    </div>

                                    <div className='mainContent'>
                                        {
                                            Object.keys(data).map((key, i) => (

                                                data[key].data ?
                                                    (
                                                        < div className='big-box' key={`userInfo${i}`}>
                                                            <div className='basic-info'>
                                                                <div className='body'>
                                                                    <div className='heading'>
                                                                    {key + (data[key]['isTotal'] ? " (Total)" : ` (${monthToShow(date)})` )}
                                                                    </div>
                                                                    <div className='total'>
                                                                        {data[key].count}
                                                                    </div>
                                                                </div>
                                                                <Icon className='icon' sx={{ color: `${data[key].color}` }}>
                                                                    {data[key].icon}
                                                                </Icon>
                                                            </div>
                                                            <div className='data-grid'>
                                                                {
                                                                    Object.keys(data[key].data).map((dataKey, j) => (
                                                                        <div className='item' key={`userInfoData${j}`}>
                                                                            <div className='label'>{dataKey}</div>
                                                                            <div className='value'>{data[key].data[dataKey].count}</div>
                                                                        </div>
                                                                    ))
                                                                }
                                                            </div>
                                                        </div>
                                                    )
                                                    :
                                                    (
                                                        <div className='small-box' key={`userInfo${i}`}>
                                                            <div className='body'>
                                                                <div className='heading'>
                                                                {key + (data[key]['isTotal'] ? " (Total)" : ` (${monthToShow(date)})` )}
                                                                </div>
                                                                <div className='total'>
                                                                    {data[key].count}
                                                                </div>
                                                            </div>

                                                            <Icon className='icon' sx={{ color: `${data[key].color}` }}>
                                                                {data[key].icon}
                                                            </Icon>
                                                        </div>
                                                    )
                                            ))
                                        }
                                    </div>
                                
                            </>

                        )
                        :
                        (
                            null
                        )
                }
            </div>
        </Modal>
    )
}

export default UserInfo