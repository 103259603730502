export const getUser = () =>
{
    const userStr = localStorage.getItem( 'user' );
    if ( userStr ) return JSON.parse( userStr );
    else return null;
}

// return the token from the session storage
export const getToken = () =>
{
    return localStorage.getItem( 'token' ) || null;
}

// return the role from the session storage
export const getRole = () =>
{
    return localStorage.getItem( 'role' ) || null;
}

// return the token from the session storage
export const getRefeshToken = () =>
{
    return localStorage.getItem( 'refresh_token' ) || null;
}

// return the token from the session storage
export const getTokenExpiry = () =>
{
    return localStorage.getItem( 'token_expiry' ) || null;
}


// remove the token and user from the session storage
export const removeUserSession = () =>
{
    localStorage.removeItem( 'token' );
    localStorage.removeItem( 'user' );
    localStorage.removeItem( 'role' );
    localStorage.removeItem( 'refresh_token' );
    localStorage.removeItem( 'token_expiry' );
    const event = new Event( 'storage' );
    window.dispatchEvent( event )
}

// set the token and user from the session storage
export const setUserSession = ( token, user, role, refreshToken, expiresIn ) =>
{
    localStorage.setItem( 'token', token );
    localStorage.setItem( 'user', JSON.stringify( user ) );
    localStorage.setItem( 'role', role );
    localStorage.setItem( 'refresh_token', refreshToken );
    //3sec reduced to handle network latency
    var tokenExpiry = new Date().getTime() + parseInt( expiresIn ) - 3000
    localStorage.setItem( 'token_expiry', tokenExpiry )
}

// set the token after token update session storage
export const setNewSession = ( token, user, role, refreshToken, expiresIn ) =>
{
    localStorage.setItem( 'token', token );
    localStorage.setItem( 'refresh_token', refreshToken );
    //3sec reduced to handle network latency
    var tokenExpiry = new Date().getTime() + parseInt( expiresIn ) - 3000
    localStorage.setItem( 'token_expiry', tokenExpiry )
    if ( JSON.stringify( user ) !== localStorage.getItem( 'user' ) || role !== getRole() )
    {
        console.log('User Changed')
        localStorage.setItem( 'user', JSON.stringify( user ) );
        localStorage.setItem( 'role', role );
        const event = new Event( 'storage' );
        window.dispatchEvent( event )
    }
}

