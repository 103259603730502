import React, { useContext, useEffect, useState } from 'react'
import './patients.scss'
import { LoaderContext } from '../../contexts/LoaderContext';
import { useNotification } from '../../contexts/NotificationContext';
import { UserContext } from '../../contexts/UserContext'
import { deletePatientRequest, getPatientsRequest } from '../../webservices/webservice';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { formattedDate } from '../../utils/date';
import { useNavigate } from 'react-router-dom';
import { colors } from '../../constants/colors';
import MainIconButton from '../../components/buttons/mainIconButton/MainIconButton';
import EditPatient from '../../modals/editPatient/EditPatient';
import Alert from '../../modals/alert/Alert';
import { DataStoreContext } from '../../contexts/DataStoreContext';
import AddPatient from '../../modals/addPatient/AddPatient';


function Patients() {
    const { showLoader, hideLoader } = useContext(LoaderContext);
    const { loggedInUserDetails } = useContext(UserContext)
    const { updateData } = useContext( DataStoreContext )
    const notify = useNotification();
    const [patients, setPatients] = useState([]);
    const navigate = useNavigate();
    const [ patientDeleteAlert, setPatientDeleteAlert ] = useState( {
        show: false,
        patient: null,
        showOkButton: true
      } )
    const [editModal, setEditModal] = useState({
        show: false,
        patient: null
    })
    const [addModal, setAddModal] = useState({
        show: false,
    })
    const navigateToPatientView = (patient) => {
        navigate('/user/patient-view', { state: { patient: patient } })
    }
    const openEditModel = (patient) => {
        setEditModal({
            show: true,
            patient: patient
        })
    }

    const closeEditModal = () => {
        setEditModal({
            show: false,
            patient: null
        })
        updateData();
    }

    const closeAddModal = () => {
        setAddModal({
            show: false,
        })
        updateData();
        getPatients()
    }

    const openAddModal = () => {
        setAddModal({
            show: true,
        })
    }

    

    const openPatientDeleteAlert = (patient) => {
        setPatientDeleteAlert({
            show: true,
            patient: patient,
            showOkButton: patient.encounterCount==="0"? true : false
        })

    }


    const closePatientDeleteAlert = () => {
        getPatients()
        setPatientDeleteAlert({
            show: false,
            patient: null,
            showOkButton: true
        })
        updateData();
    }

    const deletePatient = ( patientId ) =>
    {
      showLoader()
      deletePatientRequest( patientId )
        .then( function ( message )
        {
          hideLoader()
          closePatientDeleteAlert();
          notify( {
            type: "SUCCESS",
            message: message,
            title: "Success!!!"
          } )
        } )
        .catch( function ( error )
        {
          hideLoader()
          notify( {
            type: "ERROR",
            message: error.message ? error.message : error,
            title: error.title ? error.title : "Error!!!"
          } )
        } )
    }

    const getPatients = () => {
        showLoader();

        getPatientsRequest()
            .then((patients) => {
                setPatients(patients);
                hideLoader();
            })
            .catch((error) => {
                hideLoader();
                notify({
                    type: "ERROR",
                    message: error.message || error,
                    title: "Error Loading Patients",
                });
            });
    };

    useEffect(() => {
        getPatients();
    }, []);



    return (
        <div className='patients'>
            <Alert
                open={patientDeleteAlert.show}
                handleClose={closePatientDeleteAlert}
                okFn={() => { deletePatient(patientDeleteAlert.patient.patientId) }}
                cancelFn={closePatientDeleteAlert}
                showOkButton= {patientDeleteAlert.showOkButton? true : false}
                title={ patientDeleteAlert.show ? patientDeleteAlert.showOkButton ? `Are you sure you want to delete ${ patientDeleteAlert.patient.patientName }` :`Cannot delete patient: Active encounters exist.\nPlease delete encounters before proceeding.`:``}
                okText={"Delete"}
                cancelText={patientDeleteAlert.showOkButton? `Cancel` : `Ok`} />
            <EditPatient
                open={editModal.show}
                handleClose={closeEditModal}
                handleDataChange={getPatients}
                patient={editModal.patient}
            />
            <AddPatient
                open={addModal.show}
                handleClose={closeAddModal}
            />
            <div className='header-section'>
                <div className='text'> Patients ({patients.length})</div>
              
                <div className='right'>
                    <MainIconButton className = 'button' iconName={ 'person_add_alt' } fontSize={ 30 } onClick={ () => { openAddModal() } } />
                </div>
            </div>
            <Paper className='paper' sx={{ overflow: "auto", maxHeight: '70vh' }}>
                <TableContainer className='tableContainer' sx={{ width: '100%', display: 'table', tableLayout: 'fixed' }}>
                    <Table className='table' stickyHeader sx={{ minWidth: 800 }}>
                        {patients.length === 0 ? (
                            <caption className="caption">No Patients Found!</caption>
                        ) : (
                            null
                        )}
                        <TableHead className='head'>
                            <TableRow>
                                <TableCell className='cell'>Name</TableCell>
                                <TableCell className='cell'>Details</TableCell>
                                <TableCell className='cell'>Created by</TableCell>
                                <TableCell className='cell' align='center'>Number of encounters</TableCell>
                                <TableCell className='cell' align='center'>Created</TableCell>
                                <TableCell className='cell' align='center'>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody className='body'>
                            {patients.map((patient) => (
                                <TableRow className='row' key={`patient${patient.patientId}`}>
                                    <TableCell className='cell'><div className='data'>{patient.patientName}</div></TableCell>
                                    <TableCell className='cell'>
                                        <div className='data'>
                                            {
                                                patient.patientEmrId ?
                                                    (
                                                        <div className='patientEmrId'><span className='label'>EMR </span> : <span className='value'>{patient.patientEmrId}</span></div>
                                                    )
                                                    :
                                                    (
                                                        null
                                                    )
                                            }
                                            {
                                                (patient.patientDetails && patient.patientDetails.dob) ? (
                                                    <div className='patientDob'><span className='label'>DOB </span> : <span className='value'>{patient.patientDetails.dob}</span></div>
                                                )
                                                    :
                                                    (
                                                        null

                                                    )}
                                        </div>
                                    </TableCell>
                                    <TableCell className='cell'>
                                        <div className='data'>
                                            <span className='user-name'>{`${patient.userPrefix}. ${patient.userFirstName} ${patient.userLastName}`}</span> (<span className='role'>{`${patient.roleName}`}</span>)

                                        </div>
                                    </TableCell>
                                    <TableCell className='cell' align='center'><div className='data'>{patient.encounterCount}</div></TableCell>
                                    <TableCell className='cell' align='center'><div className='data'>{formattedDate(patient.createdDate)}</div></TableCell>
                                    <TableCell className='cell' align='center'>
                                        <MainIconButton
                                            iconName={'create_outlined'}
                                            title='Edit'
                                            selectedColor={colors.primaryColor}
                                            onClick={() => {
                                                openEditModel(patient)
                                            }}
                                        />
                                        {
                                            !loggedInUserDetails.user.isOrganization ?
                                                (
                                                    <MainIconButton
                                                        iconName={'info_outlined'}
                                                        title='Patient View'
                                                        selectedColor={colors.primaryColor}
                                                        onClick={() => {
                                                            navigateToPatientView(patient)
                                                        }}
                                                    />
                                                )
                                                :
                                                (
                                                    null
                                                )
                                        }
                                        <MainIconButton
                                            iconName={ 'cancel_outlined' } 
                                            title='Delete'
                                            defaultColor={ colors.errorColor } 
                                            selectedColor={ colors.blackColor } 
                                            onClick={ () => { openPatientDeleteAlert(patient)} } />

                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </div>
    );
}

export default Patients