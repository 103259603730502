import React, { useContext } from 'react'
import { UserContext } from '../contexts/UserContext'
import { Navigate, Outlet } from 'react-router-dom';

function OrganizationRoute ( { element: Element, ...rest } )
{
    const { loggedInUserDetails } = useContext( UserContext )

    if ( loggedInUserDetails.role === 'org-admin' )
    {
        return ( <Outlet /> )
    }
    else
    {
        return <Navigate to="/user" />;
    }
}

export default OrganizationRoute