import React, { createRef, useCallback, useContext, useEffect, useRef, useState } from 'react'
import './newEncounter.scss'
import MainDropdown from '../../components/dropdowns/mainDropdown/MainDropdown'
import MainTextField from '../../components/textfields/mainTextField/MainTextField'
import MainTextArea from '../../components/textfields/mainTextArea/MainTextArea'
import MainDateTimePicker from '../../components/dateTimePickers/mainDateTimePicker/MainDateTimePicker'
import { DataStoreContext } from "../../contexts/DataStoreContext";
import { genders } from '../../constants/data'
import moment from 'moment'
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, ToggleButton, ToggleButtonGroup } from '@mui/material'
import { colors } from '../../constants/colors'
import MainButton from '../../components/buttons/mainButton/MainButton'
import MainIconButton from '../../components/buttons/mainIconButton/MainIconButton'
import { LoaderContext } from '../../contexts/LoaderContext'
import { useNotification } from '../../contexts/NotificationContext'
import { checkAcessTokenRequest, createEncounterWithNoteRequest, createEncounterWithTranscriptionRequest } from '../../webservices/webservice'
import { useNavigate } from 'react-router-dom'
import { useDropzone } from 'react-dropzone'
import MicRecorder from 'mic-recorder-to-mp3';
import { secondsToShow } from '../../utils/date'
import MainDatePicker from '../../components/dateTimePickers/mainDatePicker/MainDatePicker'
import { refreshTime, maxAudioFileSize, errorMessageDisplayDuration, recordingLimit } from '../../config/config'
import CheckBox from '../../components/checkBox/CheckBox'
import HoverIcon from '../../components/hoverIcon/HoverIcon'

function NewEncounter() {
  const { patients, updateData, preferences } = useContext(DataStoreContext)
  const [patient, setPatient] = useState({
    patientName: '',
    patientEmrId: '',
    dob: null
  })
  const [fileDetails, setFileDetails] = useState({
    file: null,
    duration: null,
    sampleRate: null
  });
  const [audioContext, setAudioContext] = useState(null);
  const [mediaStream, setMediaStream] = useState(null);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [encounterDate, setEncounterDate] = useState(moment())
  const [selectedPatient, setSelectedPatient] = useState(null)
  const [isPlayingAudio, setIsPlayingAudio] = useState(false);
  const [selectedType, setSelectedType] = useState(null)
  const [notes, setNotes] = useState('')
  const [isRecord, setIsRecord] = useState(null);
  const [isRecordTab, setIsRecordTab] = useState(false);
  const [timeInSeconds, setTimeInSeconds] = useState(0);
  const [blob, setBlob] = useState(null);
  const [buffer, setBuffer] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  const [ isChrome , setIsChrome ] = useState(navigator.userAgent.indexOf("Chrome") != -1)
  const [Mp3Recorder, setMp3Recorder] = useState(
    new MicRecorder({ bitRate: 128 })
  );
  const [tokenCheckInterval, setTokenCheckInterval] = useState(null);
  const [recordingTimeout, setRecordingTimeout] = useState(null);
  const [isTimeOut, setIsTimeOut] = useState(false)
  const { showLoader, hideLoader } = useContext(LoaderContext);
  const notify = useNotification();
  const navigate = useNavigate();
  const audioRef = createRef();
  const audioStreamRef = useRef(null);
  const speakerStreamRef = useRef(null);
  const recorderRef = useRef(null);
  const onDrop = useCallback((acceptedFiles, fileRejections) => {
    if (fileRejections.length > 0) {
      fileRejections[0].errors.forEach(error => {
        notify({
          type: "ERROR",
          message: error.message,
          title: "Error!!!",
        });
      })
    }
    if (acceptedFiles.length > 0) {
      var fileToCheck = acceptedFiles[0]
      var audio = new Audio(URL.createObjectURL(fileToCheck));
      audio.addEventListener('loadedmetadata', async () => {
        // Get duration
        var audioDuration = audio.duration;

        // Get sampling rate
        try {
          var audioContext = new (window.AudioContext || window.webkitAudioContext)();
          var arrayBuffer = await fileToCheck.arrayBuffer();
          var decodedData = await audioContext.decodeAudioData(arrayBuffer);
          var audioSampleRate = decodedData.sampleRate;
          if (audioSampleRate < 16000 && audioSampleRate > 48000) {
            notify({
              type: "ERROR",
              message: 'The sample rate of this audio file is outside the supported range (16 kHz to 48 kHz)',
              title: "Error!!!",
            });
          }
          else {
            setFileDetails({
              file: fileToCheck,
              duration: audioDuration,
              sampleRate: audioSampleRate
            })
          }

        } catch (error) {
          console.error('Error decoding audio file:', error);
        }
      });
    }
  })


  const { getRootProps, getInputProps } = useDropzone(
    {
      onDrop,
      multiple: false,
      maxFiles: 1,
      maxSize: maxAudioFileSize,
      accept: {
        'audio/mpeg': ['.m4a', '.ogg', '.wav', '.mp3', '.flac', '.mp4', '.webm', '.amr']
      }
    }
  );


  const patientOptions = () => {
    var options = []
    options.push(
      {
        value: 'NewPatient',
        label: 'New Patient'
      }
    )
    patients.forEach(patient => {
      options.push({
        value: patient,
        label: `${patient.patientName}${patient.patientEmrId ? ` | EMR ${patient.patientEmrId}` : ''}${patient.patientDetails && patient.patientDetails.dob ? ` | DOB ${patient.patientDetails.dob}` : ''}`
      })
    });

    return options

  }

  const genderOptions = genders.map(gender => ({
    label: gender,
    value: gender
  }))


  const handlePatientOptionChange = (option) => {
    setSelectedPatient(option);
    if (option.value === 'NewPatient') {
      setPatient({
        patientName: '',
        patientEmrId: '',
        dob: null
      })
    }
    else {
      setPatient({
        patientName: option.value.patientName,
        patientEmrId: option.value.patientEmrId ? option.value.patientEmrId : '',
        dob: option.value.patientDetails && option.value.patientDetails.dob ? moment(option.value.patientDetails.dob, 'MM/DD/YYYY') : null,
      })
    }
  }

  const isPatientFieldsDisabled = () => {
    if (selectedPatient.value === 'NewPatient') {
      return false
    }
    else {
      return true
    }
  }

  const handleChangeOfSelectedType = (e) => {
    setSelectedType(e.target.value)
  }

  const handleChangeOfIsRecord = (e, newIsRecord) => {
    setIsRecord(newIsRecord)
    setFileDetails({
      file: null,
      duration: null,
      sampleRate: null
    })
    resetRecording();
  }

  const isTranscriptionFormValid = () => {
    if (
      selectedPatient &&
      (isPatientFieldsDisabled() || (patient.patientName.trim() !== '' && (patient.dob === null || (patient.dob.isValid() && patient.dob.isBefore())))) &&
      encounterDate &&
      (selectedType === 'dictation' || selectedType === 'conversation') &&
      (
        (isRecord === false && fileDetails.file) ||
        (isRecord === true && blob)
      )
    ) {
      return true
    }
    else {
      return false
    }
  }

  const playAudio = () => {
    if (isRecord === false && fileDetails.file) {
      const audioUrl = URL.createObjectURL(fileDetails.file);
      audioRef.current.src = audioUrl;
      audioRef.current.play();
      setIsPlayingAudio(true);

    }
    if (isRecord === true && blob) {
      const audioUrl = URL.createObjectURL(blob);
      audioRef.current.src = audioUrl;
      audioRef.current.play();
      setIsPlayingAudio(true);

    }
  }
  const stopAudio = () => {
    if (isPlayingAudio) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
      setIsPlayingAudio(false);
    }
  }
  const handleAudioEnded = () => {
    setIsPlayingAudio(false);
  };


  const getSelectedForm = () => {
    if (selectedType === 'notes') {
      return (
        <div className='noteForm'>
          <div className='field'>
            <MainTextArea title={'Medical Notes'} required rows="4" maxLength={9000} placeholder={'Type Encounter Notes or Transcripts to extract Insights & Generate Clinical Note'} value={notes} onChange={(e) => { setNotes(e.target.value) }} />
          </div>
          <div className='button'>
            <MainButton
              text={preferences && preferences.isAutoPilot ? 'Generate Docs' : 'Save'}
              disabled={!isSaveNoteFormValid()}
              filled
              hoverColor={colors.blackColor}
              textColor={colors.whiteColor}
              onClick={createEncounterWithNote}
            />
          </div>

        </div>
      )
    }
    else if (selectedType === 'dictation' || selectedType === 'conversation') {
      return (
        <div className='transcriptionForm'>
          <ToggleButtonGroup
            value={isRecord}
            exclusive
            className='recordToggleGroup'
            onChange={handleChangeOfIsRecord}
          >
            <ToggleButton className='recordToggleBtn' value={true}>Record</ToggleButton>
            <ToggleButton className='recordToggleBtn' value={false}>Upload</ToggleButton>
          </ToggleButtonGroup>
          <div className={isRecord === null ? 'hideSection' : 'expandSection'}>
            {
              isRecord !== null ?
                (
                  isRecord ?
                    (
                      <div className='recordSection'>
                        < div className='timer'>
                          <div className='text'>{secondsToShow(timeInSeconds)}</div>
                        </div>

                        {blob && buffer
                          ?
                          (

                            <div className='blobSection'>
                              <div className='transcribeBtn'>
                                <MainButton
                                  text={preferences && preferences.isAutoPilot ? 'Generate Docs' : 'Transcribe'}
                                  disabled={!isTranscriptionFormValid()}
                                  filled
                                  hoverColor={colors.blackColor}
                                  textColor={colors.whiteColor}
                                  onClick={createEncounterWithTranscription}
                                />
                              </div>
                              <div className='blob'>
                                <div className='left'>
                                  <div className='blobName'>{'Recorded Audio'}</div>
                                  <div className='blobSize'>{blob.size} bytes</div>
                                  <div className='blobSize'>{timeInSeconds} seconds</div>
                                </div>
                                <div className='right'>
                                  <div className='button'>
                                    <MainIconButton title={isPlayingAudio ? 'Stop Audio' : 'Play Audio'} iconName={isPlayingAudio ? 'stop_circle_outlined' : 'play_circle_outline'} selectedColor={colors.primaryColor} onClick={() => { isPlayingAudio ? stopAudio() : playAudio() }} />
                                  </div>
                                  <div className='button'>
                                    <MainIconButton title={'Delete Audio'} iconName={'highlight_off_outlined'} selectedColor={colors.blackColor} defaultColor={colors.errorColor} onClick={resetRecording} />
                                  </div>
                                </div>
                                <audio ref={audioRef} controls hidden onEnded={handleAudioEnded} />
                              </div>

                            </div>
                          )
                          :
                          (
                            <div className='recordButton'>
                              <MainButton
                                text={isRecording ? 'Stop Recording' : 'Start Recording'}
                                filled
                                color={isRecording ? colors.errorColor : colors.successColor}
                                hoverColor={isRecording ? colors.darkErrorColor : colors.darkSuccessColor}
                                textColor={colors.whiteColor}
                                onClick={() => {
                                  if (isRecording) {
                                    if(isRecordTab)
                                    {
                                      stopAudioAndScreenRecording()
                                    }
                                    else{
                                      stopRecording();
                                    }
                                  } else {
                                    if (isRecordTab) {
                                      startAudioAndScreenRecording();
                                    } else {
                                      startRecording();
                                    }
                                  }
                                }}
                              />
                              {
                                isChrome ?
                                  <div className='check-box'>
                                    <CheckBox size={20} disabled={isRecording} checked={isRecordTab} onChange={() => { setIsRecordTab(!isRecordTab) }} />
                                    <div className='hover-text'>Enable to include audio from this screen in a different tab.<HoverIcon margin='5px' placement='right' iconName={'help_outline'} title={
                                      <div style={{
                                        fontFamily: 'Poppins, sans- serif',
                                        padding: '15px',
                                        maxWidth: '600px',
                                        margin: '0 auto',
                                        textAlign: 'center',
                                        lineHeight: '1.6'
                                      }}>
                                        <div style={{
                                          fontSize: '20px',
                                          marginBottom: '20px'
                                        }}>Audio Sharing Instructions</div>
                                        <ol style={{ textAlign: 'left' }}>
                                          <li>Click the "START RECORDING" button on this page.</li>
                                          <li>A dialog box will appear with options for sharing your screen.</li>
                                          <li>Choose the "Chrome Tab" or "Browser Tab" option.</li>
                                          <li>Select the specific tab you want to share from the list of open tabs.</li>
                                          <li>Make sure "Also share tab audio" is enabled.</li>
                                          <li>Click the "Share" button to start sharing only that tab.</li>
                                        </ol>
                                      </div>
                                    } /></div>
                                  </div>
                                  :
                                  null
                              }
                            </div>
                          )}
                        {
                          isRecording && isTimeOut ?
                            (
                              <div className='statusBox'>
                                <div className='text'>
                                  Recording session ending in {secondsToShow((recordingLimit - (timeInSeconds * 1000)) / 1000)} seconds
                                </div>

                              </div>
                            ) : null
                        }
                      </div>
                    )
                    :
                    (
                      fileDetails.file
                        ?
                        (
                          <div className='fileSection'>
                            <div className='file'>
                              <div className='left'>
                                <div className='fileName'>{fileDetails.file.path}</div>
                                <div className='fileSize'>{fileDetails.file.size} bytes</div>
                                <div className='fileSize'>{fileDetails.duration} seconds</div>
                                <div className='fileSize'>{fileDetails.sampleRate} Hz</div>
                              </div>
                              <div className='right'>
                                <div className='button'>
                                  <MainIconButton title={isPlayingAudio ? 'Stop Audio' : 'Play Audio'} iconName={isPlayingAudio ? 'stop_circle_outlined' : 'play_circle_outline'} selectedColor={colors.primaryColor} onClick={() => { isPlayingAudio ? stopAudio() : playAudio() }} />
                                </div>
                                <div className='button'>
                                  <MainIconButton title={'Delete Audio'} iconName={'highlight_off_outlined'} selectedColor={colors.errorColor} onClick={() => {
                                    setFileDetails({
                                      file: null,
                                      duration: null,
                                      sampleRate: null
                                    })
                                  }} />
                                </div>
                              </div>
                              <audio ref={audioRef} controls hidden onEnded={handleAudioEnded} />
                            </div>
                            <div className='transcribeBtn'>
                              <MainButton
                                text={preferences && preferences.isAutoPilot ? 'Generate Docs' : 'Transcribe'}
                                disabled={!isTranscriptionFormValid()}
                                filled
                                hoverColor={colors.blackColor}
                                textColor={colors.whiteColor}
                                onClick={createEncounterWithTranscription}
                              />
                            </div>
                          </div>
                        )
                        :
                        (<div className='uploadSection'>
                          <div className='uploadArea'  {...getRootProps({ className: 'dropzone' })
                          } >
                            <input {...getInputProps()} />
                            <p className='uploadText'>Drag & drop your audio file or <span className='clickable'>browse</span></p>
                          </div>
                        </div>
                        )
                    )
                )
                :
                (
                  null
                )
            }
          </div >

        </div >
      )
    }
    else {
      return (
        null
      )
    }
  }

  const isSaveNoteFormValid = () => {
    if (
      selectedPatient &&
      (isPatientFieldsDisabled() || (patient.patientName.trim() !== '' && (patient.dob === null || (patient.dob.isValid() && patient.dob.isBefore())))) &&
      encounterDate &&
      selectedType === 'notes' &&
      notes.trim() !== ''
    ) {
      return true
    }
    else {
      return false
    }
  }

  const createEncounterWithNote = () => {
    showLoader();
    var patientDetail = {
    }
    if (patient.dob !== null) {
      patientDetail['dob'] = patient.dob.format('MM/DD/YYYY')
    }
    createEncounterWithNoteRequest(
      selectedPatient && selectedPatient.value !== 'NewPatient' ? selectedPatient.value.patientId : null,
      selectedPatient && selectedPatient.value !== 'NewPatient' ? null : patient.patientName.trim(),
      selectedPatient && selectedPatient.value !== 'NewPatient' ? null : (patient.patientEmrId.trim() === "" ? null : patient.patientEmrId.trim()),
      selectedPatient && selectedPatient.value !== 'NewPatient' ? null : (Object.keys(patientDetail).length > 0 ? patientDetail : null),
      notes.trim(),
      encounterDate.format('YYYY-MM-DD HH:mm:ssZ')
    )
      .then(data => {
        console.log(data);
        hideLoader();
        updateData();
        navigate('/user/encounter/detail', { state: { encounterId: data } })
      })
      .catch(error => {
        hideLoader();
        notify({
          type: "ERROR",
          message: error.message ? error.message : error,
          title: error.title ? error.title : "Error!!!",
        });
      })
  }

  const createEncounterWithTranscription = () => {
    showLoader();
    var patientDetail = {
    }
    if (patient.dob !== null) {
      patientDetail['dob'] = patient.dob.format('MM/DD/YYYY')
    }
    var file = null
    var duration = null
    if (isRecord) {
      file = new File(buffer, "audio.mp3", {
        type: blob.type,
        lastModified: Date.now(),
      })
      duration = timeInSeconds
    }
    else {
      file = fileDetails.file
      duration = fileDetails.duration
    }
    if (duration >= 1) {
      createEncounterWithTranscriptionRequest(
        selectedPatient && selectedPatient.value !== 'NewPatient' ? selectedPatient.value.patientId : null,
        selectedPatient && selectedPatient.value !== 'NewPatient' ? null : patient.patientName.trim(),
        selectedPatient && selectedPatient.value !== 'NewPatient' ? null : (patient.patientEmrId.trim() === "" ? null : patient.patientEmrId.trim()),
        selectedPatient && selectedPatient.value !== 'NewPatient' ? null : (Object.keys(patientDetail).length > 0 ? patientDetail : null),
        encounterDate.format('YYYY-MM-DD HH:mm:ssZ'),
        file,
        duration,
        isRecord,
        selectedType === 'conversation' ? true : false
      )
        .then(data => {
          console.log(data);
          hideLoader();
          updateData();
          navigate('/user/encounter/detail', { state: { encounterId: data } })
        })
        .catch(error => {
          hideLoader();
          notify({
            type: "ERROR",
            message: error.message ? error.message : error,
            title: error.title ? error.title : "Error!!!",
          });
        })
    }
    else {
      hideLoader();
      notify({
        type: "ERROR",
        message: `Provide a longer recording for analysis.`,
        title: `Insufficient Audio Length`,
      });
    }
  }


  const startAudioAndScreenRecording = () => {
    checkAcessTokenRequest()
    .then(()=>{
       Promise.all([
      navigator.mediaDevices.getUserMedia({ audio: true }),
      navigator.mediaDevices.getDisplayMedia({
        audio: true,
        video: true
      })
    ])
      .then(([micStream, displayStream]) => {

        audioStreamRef.current = micStream;
        speakerStreamRef.current = displayStream;

        const newAudioContext = new (window.AudioContext || window.webkitAudioContext)();
        setAudioContext(newAudioContext);

        const destination = newAudioContext.createMediaStreamDestination();

        const micSource = newAudioContext.createMediaStreamSource(micStream);
        micSource.connect(destination);

        const displayAudioTrack = displayStream.getAudioTracks()[0];
        if (displayAudioTrack) {
          const displaySource = newAudioContext.createMediaStreamSource(new MediaStream([displayAudioTrack]));
          displaySource.connect(destination);
        }

        // Stop the video track as we don't need it
        displayStream.getVideoTracks().forEach(track => track.stop());

        const newRecorder = new MediaRecorder(destination.stream, { mimeType: 'audio/webm' });
        recorderRef.current = newRecorder;
        setMediaRecorder(newRecorder);
        setMediaStream(destination.stream);

        const chunks = [];
        newRecorder.ondataavailable = (e) => chunks.push(e.data);
        newRecorder.onstop = () => {
          const blob = new Blob(chunks, { type: 'audio/webm' });
          setBlob(blob);
          setBuffer([blob]);
        };

        newRecorder.start(1000); // Record in 1-second chunks
        setIsRecording(true);
        setTimeInSeconds(0);

        const newTokenCheckInterval = setInterval(() => {
          checkAcessTokenRequest()
            .catch((error) => {
              stopRecording();
              notify({
                type: "ERROR",
                message: "Recording stopped due to authentication error",
                title: "Error!!!"
              });
            });
        }, refreshTime);
        setTokenCheckInterval(newTokenCheckInterval);

        const newRecordingTimeout = setTimeout(() => {
          stopRecording();
          setIsTimeOut(true);
        }, recordingLimit);
        setRecordingTimeout(newRecordingTimeout);
      })
      .catch((error) => {
        console.error('Recording error:', error);
        notify({
          type: "ERROR",
          message: error.message || "Failed to start recording",
          title: "Error!!!"
        });
      });
    })
    .catch((error) => {
      hideLoader()
      notify({
        type: "ERROR",
        message: error.message || "Failed to start recording",
        title: "Error!!!"
      });
    })
   
  };

  const stopAudioAndScreenRecording = () => {
    if (mediaRecorder && mediaRecorder.state !== 'inactive') {
      mediaRecorder.stop();
      if (mediaStream) {
        mediaStream.getTracks().forEach(track => track.stop());
      }
      if (recorderRef.current) {
        recorderRef.current.stop();  // Stop the MediaRecorder
      }
      if (audioStreamRef.current) {
        audioStreamRef.current.getTracks().forEach((track) => track.stop());
      }
      if (speakerStreamRef.current) {
        speakerStreamRef.current.getTracks().forEach((track) => track.stop());
      }
      setIsRecording(false);
      setIsTimeOut(false);

      if (tokenCheckInterval) {
        clearInterval(tokenCheckInterval);
        setTokenCheckInterval(null);
      }
      if (recordingTimeout) {
        clearTimeout(recordingTimeout);
        setRecordingTimeout(null);
      }
    }
  };

  const startRecording = () =>
  {
    checkAcessTokenRequest()
    .then(()=>{
      Mp3Recorder
      .start()
      .then( () =>
      {
        setTimeInSeconds( 0 )
        setBlob( null );
        setBuffer( null );
        setIsRecording( true )
        var intervalId = setInterval(()=>{
          checkAcessTokenRequest()
        },refreshTime)
        setTokenCheckInterval(intervalId);
      } )
      .catch( ( e ) =>
      {
        notify( {
          type: "ERROR",
          message: e.message,
          title: "Error!!!"
        } )
      } );
    })
    .catch((error)=>{
      console.log(error)
    })
  }

  const stopRecording = () =>
  {
    if (tokenCheckInterval) {
      clearInterval(tokenCheckInterval); // Clear the interval when recording stops
      setTokenCheckInterval(null)
    }
    if (recordingTimeout) {
      clearTimeout(recordingTimeout);
      setRecordingTimeout(null);
    }
    Mp3Recorder
      .stop()
      .getMp3()
      .then( ( [ buffer, blob ] ) =>
      {
        console.log( blob )
        setBlob( blob );
        setBuffer( buffer );
        setIsRecording( false )
        setIsTimeOut(false)
      } )
      .catch( ( e ) =>
      {
        notify( {
          type: "ERROR",
          message: e.message,
          title: "Error!!!"
        } )
      } );


  }
  const resetRecording = () => {
    setTimeInSeconds(0);
    setBlob(null);
    setBuffer(null);
    setIsRecording(false);
    setTokenCheckInterval(null);
    setRecordingTimeout(null);
    setIsTimeOut(false);
    if (mediaStream) {
      mediaStream.getTracks().forEach(track => track.stop());
    }
    setMediaStream(null);
    setMediaRecorder(null);
    if (audioContext) {
      audioContext.close();
      setAudioContext(null);
    }
    setIsPlayingAudio(false)
  };

  useEffect(() => {
    const remainingTime = recordingLimit - (timeInSeconds * 1000);
    if (remainingTime === errorMessageDisplayDuration) {
      setIsTimeOut(true)
    }
  }, [timeInSeconds])


  useEffect(() => {
    let interval;
    if (isRecording) {
      interval = setInterval(() => {
        setTimeInSeconds((prevTime) => prevTime + 1);
      }, 1000);

      const recordingTimeout = setTimeout(() => {
        stopRecording();
        clearInterval(interval);
        setRecordingTimeout(null);
      }, recordingLimit);
      setRecordingTimeout(recordingTimeout);


    } else {
      clearInterval(interval);
    }

    return () => {
      clearInterval(interval);
      if (recordingTimeout) {
        clearTimeout(recordingTimeout)
        setRecordingTimeout(null)
      }
    };
  }, [isRecording])

  return (
    <div className='newEncounter'>
      <div className='newEncounter__title'>
        New Encounter
      </div>
      <div className='topContent'>
        <div className='patientArea'>
          <div className='patient'>
            <MainDropdown title={'Patient'} placeholder={'Patient'} required options={patientOptions()} onChange={handlePatientOptionChange} />
          </div>
          <div className={selectedPatient && !isPatientFieldsDisabled() ? 'show' : 'hide'}>
            {
              selectedPatient && !isPatientFieldsDisabled() ?
                (
                  <div className='details'>
                    <div className='field'>
                      <MainTextField title={'Patient / Case Name'} placeholder={'Patient / Case Name'} required disabled={isPatientFieldsDisabled()} value={patient.patientName} onChange={(e) => { setPatient({ ...patient, patientName: e.target.value }) }} />
                    </div>
                    <div className='field'>
                      <MainTextField title={'Patient EMR ID'} placeholder={'Patient EMR ID'} disabled={isPatientFieldsDisabled()} value={patient.patientEmrId} onChange={(e) => { setPatient({ ...patient, patientEmrId: e.target.value }) }} />
                    </div>
                    <div className='field'>
                      <MainDatePicker title={'Date of Birth'} disabled={isPatientFieldsDisabled()} onChange={date => { setPatient({ ...patient, dob: date }) }} disableFuture value={patient.dob} />
                    </div>
                  </div>
                )
                :
                (
                  null
                )
            }
          </div>

        </div>
        <div className='dateArea'>
          <div className='date'>
            <MainDateTimePicker title={'Encounter Date'} required value={encounterDate} onChange={date => { setEncounterDate(date) }} />
          </div>

        </div>
      </div>
      <div className='mainContent'>
        <FormControl className='typeArea'>
          <FormLabel className='typeArea__title' >Recording Type</FormLabel>
          <RadioGroup
            className='radioGroup'
            value={selectedType}
            onChange={handleChangeOfSelectedType}
          >
            <FormControlLabel className='radioLabel' value="conversation" control={<Radio />} label="Conversation" />
            <FormControlLabel className='radioLabel' value="dictation" control={<Radio />} label="Dictation" />
            <FormControlLabel className='radioLabel' value="notes" control={<Radio />} label="Medical Notes" />
          </RadioGroup>
        </FormControl>

        {getSelectedForm()}


      </div>
    </div>
  )
}

export default NewEncounter