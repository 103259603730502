import { Modal } from '@mui/material'
import React from 'react'
import MainButton from '../../components/buttons/mainButton/MainButton';
import './alert.scss'
import MainIconButton from '../../components/buttons/mainIconButton/MainIconButton';

function Alert ( { open, handleClose, title, okFn, cancelFn, okText, cancelText, showOkButton = true } )
{
    return (
        <Modal className='alert' open={ open }>
            <div className='box'>
                <div className='header'>
                    <MainIconButton iconName={ 'highlight_off' } onClick={ handleClose } />
                </div>
                <div className='title'>
                    { title }
                </div>
                <div className='buttons'>
                    {
                        showOkButton?
                        (
                            <MainButton text={ okText } onClick={ okFn } />
                        )
                        :
                        (
                            null
                        )
                        
                    }
                    
                    <MainButton text={ cancelText } onClick={ cancelFn } />
                </div>
            </div>
        </Modal>
    )
}

export default Alert