import React, { useContext, useState } from 'react'
import './resetPassword.scss'
import MainTextField from '../../components/textfields/mainTextField/MainTextField'
import MainButton from '../../components/buttons/mainButton/MainButton'
import { colors } from '../../constants/colors'
import { regex } from '../../constants/regex'
import { useNavigate } from 'react-router-dom'
import { LoaderContext } from '../../contexts/LoaderContext'
import { useNotification } from '../../contexts/NotificationContext'
import { UserContext } from '../../contexts/UserContext'
import { resetPasswordRequest } from '../../webservices/webservice'

function ResetPassword ()
{
  const [ oldPassword, setOldPassword ] = useState( '' )
  const [ newPassword, setNewPassword ] = useState( '' )
  const [ confirmPassword, setConfirmPassword ] = useState( '' )
  const navigate = useNavigate();
  const { showLoader, hideLoader } = useContext( LoaderContext );
  const notify = useNotification();
  const { logOut } = useContext( UserContext );

  const isNewPasswordValid = () =>
  {
    if ( newPassword === '' || regex.password.test( newPassword ) )
    {
      return true
    }
    else
    {
      return false
    }
  }

  const isConfirmPasswordValid = () =>
  {
    if ( confirmPassword === '' || confirmPassword === newPassword )
    {
      return true
    }
    else
    {
      return false
    }
  }

  const isFormValid = () =>
  {
    if (
      oldPassword.trim() !== "" &&
      newPassword.trim() !== "" &&
      isNewPasswordValid() &&
      confirmPassword.trim() !== "" &&
      isConfirmPasswordValid()
    )
    {
      return true;
    }
    else
    {
      return false;
    }
  }

  const onResetBtnPressed = () =>
  {
    showLoader();
    resetPasswordRequest( oldPassword, newPassword )
      .then( message =>
      {
        hideLoader();
        notify( {
          type: "SUCCESS",
          message: message,
          title: "Success!!!",
        } );
        logOut();
        navigate( '/login' );
      } )
      .catch( error =>
      {
        hideLoader();
        notify( {
          type: "ERROR",
          message: error.message ? error.message : error,
          title: error.title ? error.title : "Error!!!",
        } );
      } )
  }

  return (
    <div className='resetPassword'>
      <div className='resetPassword__title'>
        Reset Password
      </div>
      <div className='mainContent'>
        <div className='resetPasswordForm'>
          <div className='description'>For security, passwords must be periodically reset to a new one. System-generated passwords must be reset upon initial login for added safety. </div>
          <div className='field'>
            <MainTextField title={ 'Old Password' } required type={ 'password' } value={ oldPassword } onChange={ ( e ) => { setOldPassword( e.target.value ) } } />
          </div>
          <div className='field'>
            <MainTextField title={ 'New Password' } required type={ 'password' } value={ newPassword } onChange={ ( e ) => { setNewPassword( e.target.value ) } } errorText={ !isNewPasswordValid() ? 'Password must include 1 digit (1-9), 1 lowercase, 1 uppercase letter, 1 special character, no spaces, 8-16 characters' : '' } />
          </div>
          <div className='field'>
            <MainTextField title={ 'Confirm New Password' } required type={ 'password' } value={ confirmPassword } onChange={ ( e ) => { setConfirmPassword( e.target.value ) } } errorText={ !isConfirmPasswordValid() ? `Passwords entered don't match.` : '' } />
          </div>
          <div className='button'>
            <MainButton text={ 'Reset Password' }
              filled
              disabled={ !isFormValid() }
              color={ colors.primaryColor }
              hoverColor={ colors.blackColor }
              textColor={ colors.whiteColor }
              onClick = {onResetBtnPressed}
            />
          </div>

        </div>

      </div>
    </div>
  )
}

export default ResetPassword