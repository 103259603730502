import React, { useContext, useEffect, useState } from 'react';
import './organizationUsage.scss'
import { LoaderContext } from '../../contexts/LoaderContext';
import { useNotification } from '../../contexts/NotificationContext';
import { getOrgUsageRequest } from '../../webservices/webservice';
import { Icon } from '@mui/material';
import MainDatePicker from '../../components/dateTimePickers/mainDatePicker/MainDatePicker';
import { monthToShow } from '../../utils/date';
import moment from 'moment';
import { UserContext } from '../../contexts/UserContext';

function OrganizationUsage() {
  const [data, setData] = useState({});
  const { showLoader, hideLoader } = useContext(LoaderContext)
  const [date,setDate] = useState(moment())
  const notify = useNotification();
  const { loggedInUserDetails } = useContext( UserContext);

  const getOrgUsage = (month,year) => {
    showLoader()
    getOrgUsageRequest(month, year)
      .then(usageData => {
        hideLoader();
        console.log(usageData)
        setData(usageData)
      })
      .catch(error => {
        hideLoader()
        notify({
          type: "ERROR",
          message: error.message ? error.message : error,
          title: error.title ? error.title : "Error!!!"
        })
      })
  }

  const handleDateChange = (date) =>{
    setDate(date)
    if(date)
        {
            getOrgUsage(date.month() +1 , date.year())
        }
        else{
            getOrgUsage(null , null);
        }
  }
  useEffect(() => {
    getOrgUsage(moment().month() +1 , moment().year());
    setDate(moment())
  }, [])

  return (
    <div className='organizationUsage'>
      <div className='head'>
        <div className='heading'>
          Organization Usage { loggedInUserDetails.user && loggedInUserDetails.user.organizationName ? `- ${ loggedInUserDetails.user.organizationName}` : '' }
        </div>
        <div className='drop-downs'>
          <MainDatePicker title={ 'Month and Year' } views={ [ 'year', 'month']} onChange={(date)=>{handleDateChange(date)}} disableFuture value={date}/>
        </div>
      </div>

      <div className='mainContent'>
        {
          Object.keys(data).map((key, i) => (

            data[key].data ?
              (
                < div className='big-box' key={`orgUsage${i}`}>
                  <div className='basic-info'>
                    <div className='body'>
                      <div className='heading'>
                      {key + (data[key]['isTotal'] ? " (Total)" : ` (${monthToShow(date)})` )}
                      </div>
                      <div className='total'>
                        {data[key].count}
                      </div>
                    </div>
                    <Icon className='icon' sx={{ color: `${data[key].color}` }}>
                      {data[key].icon}
                    </Icon>
                  </div>
                  <div className='data-grid'>
                    {
                      Object.keys(data[key].data).map((dataKey, j) => (
                        <div className='item' key={`orgUsageData${j}`}>
                          <div className='label'>{dataKey}</div>
                          <div className='value'>{data[key].data[dataKey].count}</div>
                        </div>
                      ))
                    }
                  </div>
                </div>
              )
              :
              (
                <div className='small-box' key={`orgUsage${i}`}>
                  <div className='body'>
                    <div className='heading'>
                    {key + (data[key]['isTotal'] ? " (Total)" : ` (${monthToShow(date)})` )}
                    </div>
                    <div className='total'>
                      {data[key].count}
                    </div>
                  </div>

                  <Icon className='icon' sx={{ color: `${data[key].color}` }}>
                    {data[key].icon}
                  </Icon>
                </div>
              )
          ))
        }
      </div>
    </div >
  )
}

export default OrganizationUsage