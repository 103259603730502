import { Divider, Icon, IconButton, ListItemIcon, Menu, MenuItem, Tooltip } from '@mui/material';
import React, { useContext, useState } from 'react'
import { UserContext } from '../../../contexts/UserContext';
import { colors } from '../../../constants/colors';
import './accountMenu.scss'
import { useNavigate } from 'react-router-dom';


function AccountMenu ()
{
    const [ anchorEl, setAnchorEl ] = useState( null );
    const { loggedInUserDetails, logOut } = useContext( UserContext )
    const navigate = useNavigate();
    const open = Boolean( anchorEl );
    const handleClick = ( event ) =>
    {
        setAnchorEl( event.currentTarget );
    };
    const handleClose = () =>
    {
        setAnchorEl( null );
    };

    const onLogOutBtnClicked = () =>
    {
        handleClose();
        logOut();

    }
    const onResetBtnClicked = () =>
    {
        handleClose();
        navigate( '/user/reset-password' )

    }
    const onPreferenceBtnClicked = () =>
    {
        handleClose();
        navigate( '/user/preferences' )

    }
    return (
        <div className='accountMenu'>
            <Tooltip title="Account Settings">
                <IconButton
                    onClick={ handleClick }
                    sx={ { color: colors.primaryColor } }
                    aria-controls={ open ? 'account-menu' : undefined }
                    aria-haspopup="true"
                    aria-expanded={ open ? 'true' : undefined }
                >
                    <Icon fontSize="large" >person_outlined</Icon>
                </IconButton>
            </Tooltip>
            <Menu
                anchorEl={ anchorEl }
                id="account-menu"
                open={ open }
                onClose={ handleClose }
                onClick={ handleClose }
                slotProps={ {
                    paper: {
                        elevation: 0,
                        sx: {
                            overflow: 'visible',
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                            mt: 1.5,
                            '&:before': {
                                content: '""',
                                display: 'block',
                                position: 'absolute',
                                top: 0,
                                right: 14,
                                width: 10,
                                height: 10,
                                bgcolor: 'background.paper',
                                transform: 'translateY(-50%) rotate(45deg)',
                                zIndex: 0,
                            },
                            '.MuiList-root': {
                                paddingTop: 0,
                                paddingBottom: 0,
                            },
                            '.name': {
                                textAlign: "center",
                                textTransform: 'capitalize',
                                fontFamily: `'Poppins', sans-serif`,
                                fontSize: 16,
                                fontWeight: '300',
                                color: colors.primaryColor,
                                marginTop: '10px',
                                marginLeft: '10px',
                                marginRight: '10px',
                            },
                            '.role': {
                                textAlign: "center",
                                textTransform: 'uppercase',
                                fontFamily: `'Poppins', sans-serif`,
                                fontSize: 13,
                                fontWeight: '400',
                                color: colors.secondaryColor,
                                marginBottom: '5px',
                                marginLeft: '10px',
                                marginRight: '10px',
                            },
                            '.orgName':{
                                textAlign: "center",
                                textTransform: 'capitalize',
                                fontFamily: `'Poppins', sans-serif`,
                                fontSize: 12,
                                fontWeight: '300',
                                color: colors.blackColor,
                                marginBottom: '5px',
                                marginLeft: '10px',
                                marginRight: '10px',
                            },
                            '.MuiMenuItem-root': {
                                padding: '10px',
                                fontFamily: `'Poppins', sans-serif`,
                                fontSize: 14,
                                fontWeight: '300',
                                '&:hover': {
                                    backgroundColor: colors.lightPrimaryColor
                                }
                            }
                        },
                    }
                } }
                transformOrigin={ { horizontal: 'right', vertical: 'top' } }
                anchorOrigin={ { horizontal: 'right', vertical: 'bottom' } }
            >
                <div className='name'>
                    { `${ loggedInUserDetails.user.userPrefix }. ${ loggedInUserDetails.user.userFirstName } ${ loggedInUserDetails.user.userLastName }` }
                </div>
                <div className='role'>
                    { loggedInUserDetails.role }
                </div>
                {
                    loggedInUserDetails.user.isOrganization ?
                        (
                            <div className='orgName'>
                                ({ loggedInUserDetails.user.organizationName })
                            </div>
                        )
                        :
                        (
                            null
                        )
                }

                <Divider />
                <MenuItem onClick={ onResetBtnClicked }>
                    <ListItemIcon>
                        <Icon fontSize='small'>lock_reset</Icon>
                    </ListItemIcon>
                    Reset Password
                </MenuItem>
                <MenuItem onClick={ onPreferenceBtnClicked }>
                    <ListItemIcon>
                        <Icon fontSize='small'>settings</Icon>
                    </ListItemIcon>
                    Preferences
                </MenuItem>
                <MenuItem onClick={ onLogOutBtnClicked }>
                    <ListItemIcon>
                        <Icon fontSize='small'>logout</Icon>
                    </ListItemIcon>
                    Logout
                </MenuItem>
            </Menu>
        </div>
    )
}

export default AccountMenu