import { Icon, IconButton, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react'
import { colors } from '../../../constants/colors';




function MainIconButton ( { iconName, placement = 'bottom', fontSize=24, padding='8px', defaultColor = colors.greyColor, disabled = false,  selectedColor = colors.primaryColor, title = '', ...props } )
{
    const CustomIconButton = styled( IconButton )( {
        color: defaultColor,
        borderColor: defaultColor,
        padding:padding,
        transition: 'all 1s',
        '&:hover': {
            color: selectedColor
        },
        '&:active': {
            color: selectedColor
        },
        '&:disabled': {
            color: colors.greyColor,
            cursor: 'not-allowed',
            pointerEvents : 'unset',
            '&:hover': {
                cursor: 'not-allowed'
            }
        }

    } );

    return (
        <CustomIconButton { ...props } disabled={disabled} >
            <Tooltip title={ title } arrow placement={ placement } disableHoverListener = {disabled} >
                <Icon style={{fontSize:fontSize}}>{ iconName }</Icon>
            </Tooltip>
        </CustomIconButton>

    )
}

export default MainIconButton