import React from 'react'
import './error.scss';
import MainButton from '../../components/buttons/mainButton/MainButton';
import { useNavigate } from 'react-router-dom';
import { colors } from '../../constants/colors';

export default function Error ()
{
    const navigate = useNavigate();
    return (
        <div className='errorPage'>
            <div className='title'>
                404
            </div>
            <div className='description'>
                We are sorry, but the page you requested was not found
            </div>
            <div className='button'>
                <MainButton
                    text={ 'Home' }
                    color={colors.whiteColor}
                    textColor={colors.whiteColor}
                    hoverTextColor={colors.errorColor}
                    hoverColor={colors.whiteColor}
                    onClick={ () => { navigate( '/' ) } }
                />
            </div>

        </div>
    )
}
