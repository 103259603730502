import React, { useCallback, useContext, useEffect, useState } from "react";
import MainButton from "../../components/buttons/mainButton/MainButton";
import { csvToJsonConvertor } from "../../utils/csvToJson";
import { LoaderContext } from "../../contexts/LoaderContext";
import { useNotification } from "../../contexts/NotificationContext";
import { useDropzone } from "react-dropzone";
import "./addPatient.scss";
import { addPatientRequest } from "../../webservices/webservice";
import MainIconButton from "../../components/buttons/mainIconButton/MainIconButton";
import { Modal } from "@mui/material";
import moment from "moment";

export default function AddPatient({ open, handleClose }) {
  const { showLoader, hideLoader } = useContext(LoaderContext);
  const notify = useNotification();
  const [selectedFile, setSelectedFile] = useState([]);
  const [convertedCsvFile, setConvertedCsvFile] = useState([]);
  const [invalidPatientDataCount, setInvalidPatientDataCount] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");

  const onDrop = useCallback(async (event) => {
    try {
      const fileToCheck = event[0];
      if (!fileToCheck) {
        notify({
          type: "ERROR",
          message: "No file selected",
          title: "Error!!!",
        });
      }
      if (!fileToCheck.name.toLowerCase().endsWith(".csv")) {
        notify({
          type: "ERROR",
          message: "Invalid file format. Please select a CSV file.",
          title: "Error!!!",
        });
      } else {
        setSelectedFile([fileToCheck]);
      }
    } catch (error) {
      notify({
        type: "ERROR",
        message: "Error processing file",
        title: "Error!!!",
      });
    }
  });

  const closeModal = () => {
    handleClose();
    setSelectedFile([]);
    setConvertedCsvFile([]);
    setInvalidPatientDataCount(0);
    setErrorMessage("");
  };

  const csvToJson = (file) => {
    csvToJsonConvertor(file)
      .then((data) => {
        if (data.data.length > 0) {
          const headings = Object.keys(data.data[0]);
          const hasPatientNameHeading = headings.includes("patientName");
          const hasPatientEmrIdHeading = headings.includes("patientEmrId");
          const hasPatientDobHeading = headings.includes("dob");
  
          if (hasPatientNameHeading && hasPatientEmrIdHeading && hasPatientDobHeading) {
            const filteredData = data.data.filter((value) => {
              return (
                value.patientName.trim() !== "" &&
                value.patientEmrId.trim() !== "" &&
                value.dob.trim() !== "" &&
                moment(value.dob, "MM/DD/YYYY", true).isValid()
              );
            });
  
            if (filteredData.length > 0) {
              const patientObject = filteredData.map((value) => {
                const patientData = {
                  patientName: value.patientName.trim(),
                  patientEmrId: value.patientEmrId.trim(),
                  patientDetails: {
                    dob: moment(value.dob, "MM/DD/YYYY").format("MM/DD/YYYY"),
                  },
                };
                return patientData;
              });
  
              setConvertedCsvFile(patientObject);
            } else {
              setErrorMessage("No valid data found in CSV");
              setInvalidPatientDataCount(1);
            }
          } else {
            setErrorMessage("CSV is missing 'patientName' or 'patientEmrId' or 'dob' headings");
            setInvalidPatientDataCount(1);
          }
        } else {
          setErrorMessage("No Data");
          setInvalidPatientDataCount(1);
        }
      })
      .catch((error) => {
        notify({
          type: "ERROR",
          message: "Unable to upload file. Check CSV file",
          title: "Error!!!",
        });
      });
  };
  

  const handleRemoveFile = (index) => {
    const updatedFiles = [...selectedFile];
    updatedFiles.splice(index, 1);
    setSelectedFile(updatedFiles);
    setSelectedFile([]);
    setConvertedCsvFile([]);
    setInvalidPatientDataCount(0);
    setErrorMessage("");
  };

  const downloadCsv = () => {
    const csvData =
      "patientName,patientEmrId,dob\nJohn,J123,09/19/2000\nAlice,A123,02/17/1999\nBob,B123,07/20/2014";
    const blob = new Blob([csvData], { type: "text/csv" });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = "sample.csv";
    link.click();
  };

  useEffect(() => {
    if (selectedFile.length > 0) {
      csvToJson(selectedFile[0]);
    }
  }, [selectedFile]);

  const addPatient = () => {
    showLoader();
    addPatientRequest(convertedCsvFile)
      .then((message) => {
        hideLoader();
        notify({
          type: "SUCCESS",
          message: message,
          title: "Success!!!",
        });
        closeModal();
      })
      .catch((error) => {
        hideLoader();
        notify({
          type: "ERROR",
          message: error.message ? error.message : error,
          title: error.title ? error.title : "Error!!!",
        });
        handleClose();
        setSelectedFile([]);
      });
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1,
    maxSize: 1024 * 1024 * 100,
    accept: {
      "text/csv": [".csv"],
    },
  });

  return (
    <Modal className="addPatient" open={open}>
      <div className="box">
        <div className="header">
          <div>
            <MainIconButton iconName={"download"} onClick={downloadCsv} />
            Demo.csv
          </div>
          <MainIconButton iconName={"highlight_off"} onClick={closeModal} />
        </div>
        {}
        {selectedFile.length > 0 ? (
          <>
            <div className="fileSection">
              <div className="file">
                <div className="left">
                  <div className="fileName">
                    <ul>
                      <li key="1">{selectedFile[0].name}</li>
                    </ul>
                  </div>
                  <div className="fileSize">
                    Total Patient Count :{" "}{convertedCsvFile.length}
                  </div>
                </div>
                <div className="right">
                  <div className="button">
                    <MainIconButton
                      iconName={"highlight_off"}
                      onClick={() => handleRemoveFile(0)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="uploadSection">
            <div
              className="uploadArea"
              {...getRootProps({ className: "dropzone" })}
            >
              <input {...getInputProps()} />
              <p className="uploadText">
                Drag & drop your csv file or{" "}
                <span className="clickable">browse</span>
              </p>
            </div>
          </div>
        )}
        {selectedFile.length > 0 && invalidPatientDataCount === 0 ? (
          <div className="buttons">
            <MainButton
              text={"Upload"}
              onClick={addPatient}
              disabled={
                selectedFile.length > 0 && invalidPatientDataCount === 0
                  ? false
                  : true
              }
            />
          </div>
        ) : (
          ""
        )}
        {invalidPatientDataCount > 0 ? (
          <div className="statusBox error">
            <div className="text">{errorMessage}</div>
          </div>
        ) : (
          ""
        )}
      </div>
    </Modal>
  );
}
