import React from 'react'
import './privacyPolicy.scss'
import MainHeader from '../../components/headers/mainHeader.js/MainHeader';
import { colors } from '../../constants/colors';
import MainButton from '../../components/buttons/mainButton/MainButton';
import { useNavigate } from 'react-router-dom';
import Footer from '../../components/footer/Footer';

function PrivacyPolicy ()
{
    const navigate = useNavigate();
    return (
        <div className='privacyPolicy'>
            <MainHeader />
            <div className='titleArea'>
                <div className='title'><span style={ { color: colors.primaryColor } }>Privacy Policy</span></div>
                <div className='description'>Legal document</div>
            </div>
            <div className='mainSection'>
                <div className='textArea'>
                    <div className='title'>Privacy Policy for FastTrack EHR</div>
                    <div className='detail'>This Privacy Policy describes how FastTrack EHR ("we," "our," or "us") collects, uses, and discloses information when you use our software. We are committed to safeguarding your privacy and ensuring the security of your data.</div>
                    <div className='title'>Information We Collect</div>
                    <div className='point'><span className='main'>Personal Information: </span> We may collect personal details (such as name, contact information) provided voluntarily during registration or use of our software.</div>
                    <div className='point'><span className='main'>Health Information: </span>  FastTrack EHR processes health-related data shared by users to create and manage health records, ensuring strict confidentiality and adherence to privacy laws.</div>
                    <div className='point'><span className='main'>Usage Data: </span>  We collect information about how our software is accessed and used to improve our services and user experience.</div>
                    <div className='title'>How We Use Your Information</div>
                    <div className='point'><span className='main'>Service Provision:  </span> Personal and health information is used to provide our EHR services, including transcription, note generation, code assignment, and patient record management.</div>

                    <div className='title'>Data Security</div>
                    <div className='detail'>We employ industry-standard security measures to protect your information from unauthorized access, alteration, or disclosure. This includes encryption, access controls, and regular security assessments.</div>
                    <div className='title'>Data Sharing & Disclosure</div>
                    <div className='detail'>We do not sell or share personal health information with third parties except as required by law or for providing our services. </div>
                    <div className='title'>Your Choices & Rights</div>
                    <div className='detail'>Users have the right to access, modify, or delete their personal information. We provide options to manage data preferences within the software.</div>
                    <div className='title'>Updates to this Policy</div>
                    <div className='detail'>This Privacy Policy may be updated periodically. We recommend reviewing this page for any changes. Continued use of FastTrack EHR signifies acceptance of these updates.</div>
                    <div className='title'>Contact Us</div>
                    <div className='detail'>For inquiries about this Privacy Policy or data practices, please contact us.</div>
                    <div className='button'>
                        <MainButton
                            text={ 'Contact US' }
                            filled
                            hoverColor={ colors.blackColor }
                            textColor={ colors.whiteColor }
                            onClick={ () => { navigate( '/contact-us' ) } }
                        />
                    </div>

                </div>

            </div>
            <Footer />
        </div>
    )
}

export default PrivacyPolicy