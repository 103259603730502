import React, { useContext } from 'react'
import './footer.scss'
import logoCompleteWhite from '../../images/logoCompleteWhite.png'
import hippaLogo from '../../images/hipaa.png'
import { NavLink } from 'react-router-dom'
import { openInNewTab } from '../../utils/common'
import config from '../../config/config'
import { UserContext } from '../../contexts/UserContext'

function Footer ()
{
    const { loggedInUserDetails } = useContext( UserContext );
    return (
        <div className='footer'>
            <div className='logoArea'>
                <img className='logo' src={ logoCompleteWhite } alt='logo' />
                <img className='badge' src={ hippaLogo } alt='badge' />
            </div>
            <div className='sectionsArea'>
                <div className='section'>
                    <div className='title'>
                        Site Pages
                    </div>
                    <NavLink to={ '/about' } className={ 'navLink' } >
                        About
                    </NavLink>
                    <NavLink to={ '/contact-us' } className={ 'navLink' } >
                        Contact Us
                    </NavLink>
                    {

                    }
                    {
                        loggedInUserDetails.user ?
                            (
                                <NavLink to={ '/user' } className={ 'navLink' } >
                                    Dashboard
                                </NavLink>

                            )
                            :
                            (
                                <React.Fragment>
                                    <NavLink to={ '/login' } className={ 'navLink' } >
                                        Login
                                    </NavLink>
                                    <NavLink to={ '/sign-up' } className={ 'navLink' } >
                                        Sign Up
                                    </NavLink>
                                </React.Fragment>
                            )
                    }

                </div>
                <div className='section'>
                    <div className='title'>
                        Legal Information
                    </div>
                    <NavLink to={ '/terms-conditions' } className={ 'navLink' } >
                        Terms & Conditions
                    </NavLink>
                    <NavLink to={ '/privacy-policy' } className={ 'navLink' } >
                        Privacy Policy
                    </NavLink>
                   
                </div>
            </div>
            <div className='bottomArea'>
                <div className='copyRightText'>Copyright © 2023 <span className='link' onClick={ () => { openInNewTab( config.infralogicsUrl ) } }>Infralogics</span>. All rights reserved</div>
            </div>
        </div>
    )
}

export default Footer