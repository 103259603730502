import React from 'react'
import './signUp.scss'
import aboutImg from '../../images/about.jpeg';
import MainHeader from '../../components/headers/mainHeader.js/MainHeader';
import { colors } from '../../constants/colors';
import MainButton from '../../components/buttons/mainButton/MainButton';
import { useNavigate } from 'react-router-dom';
import Footer from '../../components/footer/Footer';

function SignUp ()
{
    const navigate = useNavigate();
    return (
        <div className='signUp'>
            <MainHeader />
            <div className='titleArea'>
                <div className='title'><span style={ { color: colors.primaryColor } }>Sign Up</span></div>
                <div className='description'>Ready to get started? Connect with us to begin your journey with FastTrack EHR.</div>
            </div>
            <div className='mainSection'>
                <div className='imgArea'>
                    <img className='image' src={ aboutImg } alt='about' />
                </div>
                <div className='textArea'>
                    <div className='title'>Welcome to FastTrack EHR !</div>
                    <div className='detail'>Great News! FastTrackEHR is now accepting new users, and we want you to be part of our revolutionary journey to transform healthcare documentation. Join us today to unlock the potential of our cutting-edge platform. Contact our team now to sign up and enjoy seamless record-keeping, powered by advanced AI technology. Embrace the future of healthcare with effortless, accurate, and comprehensive patient records. Don't miss out on the opportunity to be at the forefront of this transformation. </div>
                    <div className='detail'>Connect with us today and start your journey towards streamlined healthcare documentation.</div>
                    <div className='button'>
                        <MainButton
                            text={ 'Contact US' }
                            filled
                            hoverColor={ colors.blackColor }
                            textColor={ colors.whiteColor }
                            onClick={ () => { navigate( '/contact-us' ) } }
                        />
                    </div>

                </div>

            </div>
            <Footer />
        </div>
    )
}

export default SignUp