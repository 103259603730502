import React, { useContext, useEffect } from 'react'
import './sideMenu.scss'
import { getMenu } from '../../../constants/data'
import { NavLink } from 'react-router-dom'
import { Icon, Tooltip } from '@mui/material'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import { sizes } from '../../../constants/sizes'
import { UserContext } from '../../../contexts/UserContext'


function SideMenu ( { show, setShow } )
{
    const { width } = useWindowDimensions();
    const { loggedInUserDetails } = useContext( UserContext )
    return (
        <div
            className='sideMenu'
            style={
                {
                    maxWidth: width < sizes.md ? ( show ? 300 : 0 ) : 'unset',
                    padding: width < sizes.md ? ( show ? 30 : 0 ) : 30
                }
            }
        >
            { getMenu(loggedInUserDetails.role === 'org-admin',loggedInUserDetails.user.isOrganization).map( ( item ) => (
                <div
                    className="item"
                    key={ item.id }
                    style={
                        {
                            maxWidth: show ? 300 : 60,
                            display: width < sizes.md ? ( show ? 'unset' : 'none' ) : 'flex',
                            opacity: width < sizes.md ? ( show ? 1 : 0 ) : 1
                        }
                    }
                >
                    <span
                        className="title"
                        style={
                            {
                                display: show ? 'unset' : 'none'
                            }
                        }
                    >
                        { item.title }
                    </span>
                    {
                        item.listItems.map( ( listItem ) => (
                            <NavLink
                                to={ listItem.url }
                                className={ ( { isActive, isPending } ) => `listItem ${ isActive ? 'active' : '' } ${ listItem.isButton ? 'button' : '' }` }
                                key={ listItem.id }
                                onClick={ () => { if ( width < sizes.md ){setShow(false)}}}
                            >
                                <Tooltip
                                    disableHoverListener={ show }
                                    arrow
                                    title={ listItem.title }
                                    placement='right'
                                >
                                    <Icon className='icon'>{ listItem.icon }</Icon>
                                </Tooltip>
                                <span
                                    className="listItemTitle"
                                    style={
                                        {
                                            display: show ? 'unset' : 'none'
                                        }
                                    }
                                >{ listItem.title }</span>
                            </NavLink>
                        ) )
                    }
                </div>
            ) ) }
        </div>
    )
}

export default SideMenu