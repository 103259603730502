export const colors = {
    bgColor: '#fff',
    primaryColor: '#6D63AC',
    secondaryColor: '#F9AA20',
    textColor: '#201C32',
    successColor: '#009432',
    darkSuccessColor: '#007227',
    errorColor: '#c0392b',
    darkErrorColor: '#a43125',
    whiteColor: '#fff',
    blackColor: '#000',
    greyColor: '#BEC0C2',
    blackBgColor: '#151414',
    greyBorderColor: '#2B333F',
    lightBgColor: '#eff1f6',
    lightPrimaryColor : '#B6B1D5',
    disabledBgColor: '#f7f7f7',
    inProgressColor: '#3498db',
    lightBgPrimaryColor: '#DFDDED',
}