import React, { useContext } from 'react'
import { UserContext } from '../contexts/UserContext'
import { Navigate, Outlet } from 'react-router-dom';

function PublicRoute ( { element: Element, ...rest } )
{
    const { loggedInUserDetails } = useContext( UserContext )

    if ( loggedInUserDetails.user )
    {
        return ( <Navigate to="/user" /> )
    }
    else
    {
        return <Outlet />;
    }
}

export default PublicRoute