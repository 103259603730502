import { Modal } from '@mui/material'
import './forgotPassword.scss'
import React, { useContext, useState } from 'react'
import MainIconButton from '../../components/buttons/mainIconButton/MainIconButton';
import MainTextField from '../../components/textfields/mainTextField/MainTextField';
import { colors } from '../../constants/colors';
import MainButton from '../../components/buttons/mainButton/MainButton';
import { regex } from '../../constants/regex';
import { LoaderContext } from '../../contexts/LoaderContext';
import { useNotification } from '../../contexts/NotificationContext';
import { forgotPasswordRequest } from '../../webservices/webservice';

function ForgotPassword ( { open, handleClose } )
{
    const [ email, setEmail ] = useState( '' )
    const { showLoader, hideLoader } = useContext( LoaderContext )
    const notify = useNotification();
    const isEmailValid = () =>
    {
        if ( email === "" || regex.mail.test( email ) )
        {
            return true
        }
        else
        {
            return false
        }
    }

    const isFormValid = () =>
    {
        if (
            email.trim() !== '' &&
            isEmailValid()
        )
        {
            return true;
        }
        else
        {
            return false;
        }
    }

    const closeModal = () =>
    {
        setEmail( '' )
        handleClose();
    }

    const resetBtnClicked = () =>
    {
        showLoader()
        forgotPasswordRequest( email )
            .then( message =>
            {
                hideLoader()
                notify( {
                    type: "SUCCESS",
                    message: message,
                    title: "Success!!!"
                } )
                closeModal();
            } )
            .catch( error =>
            {
                hideLoader()
                notify( {
                    type: "ERROR",
                    message: error.message ? error.message : error,
                    title: error.title ? error.title : "Error!!!",
                } );
            } )
    }



    return (
        <Modal className='forgotPassword' open={ open }>
            <div className='box'>
                <div className='header'>
                    <div className='title'>Forgot Password?</div>
                    <MainIconButton iconName={ 'highlight_off' } onClick={ closeModal } />
                </div>
                <div className='mainSection'>
                    <div className='mainText'>Reset your password by providing your email. A new password will be sent to the provided email address.</div>
                    <div className='field'>
                        <MainTextField type='text' required title='Email' placeholder='Email' value={ email } onChange={ ( e ) => { setEmail( e.target.value ) } } errorText={ !isEmailValid() ? 'Invalid email.' : '' } />
                    </div>
                    <div className='button'>
                        <MainButton text={ 'Reset Password' }
                            filled
                            disabled={ !isFormValid() }
                            color={ colors.primaryColor }
                            hoverColor={ colors.blackColor }
                            textColor={ colors.whiteColor }
                            onClick={ resetBtnClicked }
                        />
                    </div>
                </div>

            </div>
        </Modal>
    )
}

export default ForgotPassword