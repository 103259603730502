import React from 'react';
import { Icon, Tooltip } from '@mui/material';
import './hoverIcon.scss';

function HoverIcon({ iconName, placement = 'bottom', selectedColor, title = '', fontSize = '20px', margin = '10px', ...props }) {
    const fontSizeClass = fontSize === '12px' ? 'small' : fontSize === '28px' ? 'large' : 'medium';
    const marginClass = margin === '5px' ? 'margin-small' : margin === '15px' ? 'margin-large' : 'margin-medium';

    return (
        <Tooltip title={ title } arrow placement={ placement } { ...props } enterTouchDelay={ 0 }>
            <Icon className={`custom-icon ${fontSizeClass} ${marginClass}`}>
                {iconName}
            </Icon>
        </Tooltip>
    );
}

export default HoverIcon;
