
const adminMenu = [
    {
        id: 1,
        title: "Doctor Panel",
        listItems: [
            {
                id: 1,
                title: "Encounters",
                url: "/user/encounters",
                icon: "record_voice_over",
                isButton: false
            },
            {
                id: 2,
                title: "Patient View",
                url: "/user/patient-view",
                icon: "wheelchair_pickup",
                isButton: false
            },
            {
                id: 3,
                title: "My Usage",
                url: "/user/my-usage",
                icon: "trending_up",
                isButton: false
            },
            {
                id: 4,
                title: "New Encounter",
                url: "/user/encounter/new",
                icon: "loupe",
                isButton: true
            },

        ],
    },
    {
        id: 2,
        title: "Admin Panel",
        listItems: [
            {
                id: 1,
                title: "Users",
                url: "/org/users",
                icon: "person",
                isButton: false
            },
            {
                id: 2,
                title: "Organization Usage",
                url: "/org/usage",
                icon: "corporate_fare_rounded",
                isButton: false
            },
            {
                id: 3,
                title: "Patients",
                url: "/org/patients",
                icon: "accessible",
                isButton: false
            },
            
        ],
    },
]

const menu = [
    {
        id: 1,
        title: "Doctor Panel",
        listItems: [
            {
                id: 1,
                title: "Encounters",
                url: "/user/encounters",
                icon: "record_voice_over",
                isButton: false
            },
            {
                id: 2,
                title: "Patient View",
                url: "/user/patient-view",
                icon: "wheelchair_pickup",
                isButton: false
            },
            {
                id: 3,
                title: "My Usage",
                url: "/user/my-usage",
                icon: "trending_up",
                isButton: false
            },
            {
                id: 5,
                title: "New Encounter",
                url: "/user/encounter/new",
                icon: "loupe",
                isButton: true
            },

        ],
    },
];

const individualMenu = [
    {
        id: 1,
        title: "Doctor Panel",
        listItems: [
            {
                id: 1,
                title: "Encounters",
                url: "/user/encounters",
                icon: "record_voice_over",
                isButton: false
            },
            {
                id: 2,
                title: "Patient View",
                url: "/user/patient-view",
                icon: "wheelchair_pickup",
                isButton: false
            },
            {
                id: 3,
                title: "My Usage",
                url: "/user/my-usage",
                icon: "trending_up",
                isButton: false
            },
            {
                id: 4,
                title: "Patients",
                url: "/org/patients",
                icon: "accessible",
                isButton: false
            },
            {
                id: 5,
                title: "New Encounter",
                url: "/user/encounter/new",
                icon: "loupe",
                isButton: true
            },

        ],
    },
];

export const getMenu = ( isOrgAdmin ,isOrganization) =>
{
    if ( isOrgAdmin )
    {
        return adminMenu
    }
    else
    {
        if(isOrganization)
        {
            return menu
        }

        else{
            return individualMenu
        }
    }
}


export const genders = [
    'Male',
    'Female',
    'Non-binary',
    'Other'
]