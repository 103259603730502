
import React from 'react'
import './checkBox.scss';
import { colors } from '../../constants/colors';
import { Checkbox } from '@mui/material';

function CheckBox({ size=null,color = colors.darkColor, checkedColor = colors.lightColor, ...props }) {
    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
    return (
        <Checkbox
            {...label}
            sx={{
                color: color,
                '&.Mui-checked': {
                    color: checkedColor,
                },
                '& .MuiSvgIcon-root': {
                     fontSize: size 
                    }
            }}
            {...props}
        />

    )
}

export default CheckBox