const converter = require('papaparse');

export const csvToJsonConvertor = (file) => {
  return new Promise((resolve, reject) => {
    converter.parse(file, {
      header:true,
      complete: function (results) {
        resolve( results );
      },
      error: function (error) {
        reject( error );
      },
    });
  });
};
