import React from 'react'
import './mainTextArea.scss'

function MainTextArea ( { title,required=false, errorText = '', ...props } )
{
    return (
        <div className='mainTextArea'>
            <div className='title'>{ `${ title }${ required ? '*' : '' }` } </div>
            <textarea className={ `textArea ${ errorText ? 'error' : '' }` } { ...props } />
            {
                errorText ?
                    (
                        <div className='errorText'>{ errorText }</div>

                    )
                    :
                    (
                        null
                    )
            }
        </div>

    )
}

export default MainTextArea