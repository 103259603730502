import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import LoaderContextProvider from "./contexts/LoaderContext";
import NotificationProvider from "./contexts/NotificationContext";
import UserContextProvider from "./contexts/UserContext";
import DataStoreContextProvider from './contexts/DataStoreContext';
import SocketContextProvider from './contexts/SocketContext';

const root = ReactDOM.createRoot( document.getElementById( 'root' ) );
root.render(
  <LoaderContextProvider>
    <NotificationProvider>
      <UserContextProvider>
        <DataStoreContextProvider>
          <SocketContextProvider>
            <App />
          </SocketContextProvider>
        </DataStoreContextProvider>
      </UserContextProvider>
    </NotificationProvider>
  </LoaderContextProvider>
);

