import React, { useState } from "react";
import './notification.scss';

const Notification = props =>
{
    const [ exit, setExit ] = useState( false );
    const [ width, setWidth ] = useState( 0 );
    const [ intervalID, setIntervalID ] = useState( null );

    const handleStartTimer = () =>
    {
        const id = setInterval( () =>
        {
            setWidth( prev =>
            {
                if ( prev < 100 )
                {
                    return prev + 0.5;
                }

                clearInterval( id );
                return prev;
            } );
        }, 20 );

        setIntervalID( id );
    };

    const handlePauseTimer = () =>
    {
        clearInterval( intervalID );
    };

    const handleCloseNotification = () =>
    {
        handlePauseTimer();
        setExit( true );
        setTimeout( () =>
        {
            props.dispatch( {
                type: "REMOVE_NOTIFICATION",
                id: props.id
            } )
        }, 400 )
    };

    React.useEffect( () =>
    {
        if ( width === 100 )
        {
            // Close notification
            handleCloseNotification()
        }
    }, [ width ] ) // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect( () =>
    {
        handleStartTimer();
    }, [] );

    return (
        <div
            onMouseEnter={ handlePauseTimer }
            onMouseLeave={ handleStartTimer }
            className={ `notification ${ props.type === "SUCCESS" ? "success" : "error"
                } ${ exit ? "exit" : "" }` }
        >
            <p className="title">{ props.title }</p>
            <p className="message">{ props.message }</p>
            <div className={ "bar" } style={ { width: `${ width }%` } } />
        </div>
    );
};

export default Notification;