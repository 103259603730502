import React from 'react'
import './mainDateTimePicker.scss'
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { colors } from '../../../constants/colors';
function MainDateTimePicker ( { title, required = false, ...props } )
{
    return (
        <div className='mainDateTimePicker'>
            <div className='title'>{ `${ title }${ required ? '*' : '' }` }</div>
            <LocalizationProvider dateAdapter={ AdapterMoment }>
                <DateTimePicker
                    className='picker'
                    slotProps={
                        {
                            textField:
                            {
                                fullWidth: true,
                                size: 'small'
                            },
                            desktopPaper: {
                                sx: {
                                    "& .MuiPickersDay-root": {
                                        "&.Mui-selected": {
                                            backgroundColor: colors.primaryColor + ' !important',
                                        },
                                    },
                                    "& .MuiMenuItem-root": {
                                        "&.Mui-selected": {
                                            backgroundColor: colors.primaryColor,
                                        },
                                    },
                                    "& .MuiButton-root": {
                                        color: colors.primaryColor
                                    }
                                }
                            },
                            mobilePaper: {
                                sx: {
                                    "& .MuiPickersDay-root": {
                                        "&.Mui-selected": {
                                            backgroundColor: colors.primaryColor,
                                            "&:hover": {
                                                backgroundColor: colors.primaryColor,
                                            }
                                        },
                                    },
                                    "& .MuiMenuItem-root": {
                                        "&.Mui-selected": {
                                            backgroundColor: colors.primaryColor,
                                        },
                                    },
                                    "& .MuiPickersYear-yearButton": {
                                        "&.Mui-selected": {
                                            backgroundColor: colors.primaryColor,
                                        },
                                    },
                                    "& .MuiButton-root": {
                                        color: colors.primaryColor
                                    },
                                    "& .MuiTab-root": {
                                        color: colors.primaryColor,
                                        "&.Mui-selected": {
                                            color: colors.primaryColor,
                                        },
                                    },
                                    "& .MuiTabs-indicator": {
                                        backgroundColor: colors.primaryColor
                                    },
                                    "& .MuiClock-pin": {
                                        backgroundColor: colors.primaryColor,
                                    },
                                    "& .MuiClockPointer-root": {
                                        backgroundColor: colors.primaryColor,
                                    },
                                    "& .MuiClockPointer-thumb": {
                                        backgroundColor: colors.primaryColor,
                                        borderColor: colors.primaryColor,
                                    },
                                }
                            }
                        } }
                    { ...props }
                />
            </LocalizationProvider>
        </div>
    )
}

export default MainDateTimePicker