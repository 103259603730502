import React, { useContext, useState } from 'react'
import './mainHeader.scss'
import logoComplete from '../../../images/logoComplete.png'
import { NavLink, useNavigate } from 'react-router-dom'
import MainButton from '../../buttons/mainButton/MainButton'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import { colors } from '../../../constants/colors'
import MainIconButton from '../../buttons/mainIconButton/MainIconButton'
import Drawer from '@mui/material/Drawer';
import { UserContext } from '../../../contexts/UserContext'


function MainHeader ()
{
    const { width } = useWindowDimensions();
    const [ openDrawer, setOpenDrawer ] = useState( false )
    const navigate = useNavigate();
    const toggleDrawer = () =>
    {
        setOpenDrawer( !openDrawer )
    }
    const { loggedInUserDetails } = useContext( UserContext );

    return (
        <div className='mainHeader'>
            <div className='left'>
                <img className='logo' src={ logoComplete } alt='logo' onClick={ () => { navigate( '/' ) } } />
            </div>
            {
                width > 900 ?
                    (
                        <div className='right'>
                            <div className='navButtons'>
                                <NavLink to={ '/' } className={ ( { isActive, isPending } ) => !isActive ? 'navLink' : 'navLink active' } >
                                    Home
                                </NavLink>
                                <NavLink to={ '/about' } className={ ( { isActive, isPending } ) => !isActive ? 'navLink' : 'navLink active' } >
                                    About
                                </NavLink>
                                <NavLink to={ '/contact-us' } className={ ( { isActive, isPending } ) => !isActive ? 'navLink' : 'navLink active' } >
                                    Contact Us
                                </NavLink>
                            </div>
                            {
                                loggedInUserDetails.user ?
                                    (
                                        <div className='mainButtons'>
                                            <MainButton text={ 'Dashboard' }
                                                filled
                                                color={ colors.primaryColor }
                                                hoverColor={ colors.blackColor }
                                                textColor={ colors.whiteColor }
                                                onClick={ () => { navigate( '/user' ) } }
                                            />
                                        </div>
                                    )
                                    :
                                    (
                                        <div className='mainButtons'>
                                            <MainButton text={ 'Login' }
                                                filled
                                                color={ colors.primaryColor }
                                                hoverColor={ colors.blackColor }
                                                textColor={ colors.whiteColor }
                                                onClick={ () => { navigate( '/login' ) } }
                                            />
                                            <MainButton text={ 'Sign Up' }
                                                filled
                                                color={ colors.secondaryColor }
                                                hoverColor={ colors.blackColor }
                                                textColor={ colors.whiteColor }
                                                onClick={ () => { navigate( '/sign-up' ) } }
                                            />
                                        </div>
                                    )
                            }
                        </div>
                    )
                    :
                    (
                        <div className='right'>
                            <MainIconButton iconName={ 'menu' } onClick={ toggleDrawer } />
                            <Drawer anchor={ 'right' } open={ openDrawer } onClose={ toggleDrawer }>
                                <div className='drawer'>
                                    <div className='navButtons'>
                                        <NavLink to={ '/' } className={ ( { isActive, isPending } ) => !isActive ? 'navLink' : 'navLink active' } >
                                            Home
                                        </NavLink>
                                        <NavLink to={ '/about' } className={ ( { isActive, isPending } ) => !isActive ? 'navLink' : 'navLink active' } >
                                            About
                                        </NavLink>
                                        <NavLink to={ '/contact-us' } className={ ( { isActive, isPending } ) => !isActive ? 'navLink' : 'navLink active' } >
                                            Contact Us
                                        </NavLink>
                                    </div>
                                    {
                                        loggedInUserDetails.user ?
                                            ( <div className='mainButtons'>
                                                <MainButton text={ 'Dashboard' }
                                                    filled
                                                    color={ colors.primaryColor }
                                                    hoverColor={ colors.blackColor }
                                                    textColor={ colors.whiteColor }
                                                    onClick={ () => { navigate( '/user' ) } }
                                                />

                                            </div>
                                            ) :
                                            ( <div className='mainButtons'>
                                                <MainButton text={ 'Login' }
                                                    filled
                                                    color={ colors.primaryColor }
                                                    hoverColor={ colors.blackColor }
                                                    textColor={ colors.whiteColor }
                                                    onClick={ () => { navigate( '/login' ) } }
                                                />
                                                <MainButton text={ 'Sign Up' }
                                                    filled
                                                    color={ colors.secondaryColor }
                                                    hoverColor={ colors.blackColor }
                                                    textColor={ colors.whiteColor }
                                                    onClick={ () => { navigate( '/sign-up' ) } }
                                                />
                                            </div> )
                                    }

                                </div>
                            </Drawer>
                        </div>
                    )
            }

        </div>
    )
}

export default MainHeader