import React, { useContext } from 'react'
import { UserContext } from '../contexts/UserContext'
import { Navigate, Outlet } from 'react-router-dom';

function PrivateRoute({element:Element, ...rest}) {
    const { loggedInUserDetails } = useContext( UserContext )

    if(loggedInUserDetails.user){
        return(<Outlet />)
    }
    else{
        return <Navigate to="/login" />;   
    }
}

export default PrivateRoute