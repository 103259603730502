import React, { useContext, useEffect, useState } from 'react'
import './encounters.scss'
import { LoaderContext } from '../../contexts/LoaderContext';
import { useNotification } from '../../contexts/NotificationContext';
import { deleteEncounterRequest, searchEncountersRequest } from '../../webservices/webservice';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { formattedDate } from '../../utils/date';
import IconBadge from '../../components/badges/iconBadge/IconBadge';
import { colors } from '../../constants/colors';
import MainIconButton from '../../components/buttons/mainIconButton/MainIconButton';
import { useNavigate } from 'react-router-dom';
import Alert from '../../modals/alert/Alert';
import { SocketContext } from '../../contexts/SocketContext';
import { DataStoreContext } from '../../contexts/DataStoreContext';

function Encounters ()
{
    const { showLoader, hideLoader } = useContext( LoaderContext )
    const {preferences} = useContext( DataStoreContext )
    const { updateEncounter, onEncounterUpdation } = useContext( SocketContext )
    const notify = useNotification();
    const [ encounters, setEncounters ] = useState( [] );
    const navigate = useNavigate();
    const [ deleteAlert, setDeleteAlert ] = useState( {
        show: false,
        encounter: null
    } )

    const navigateToEncounterDetailPage = ( encounter ) =>
    {
        navigate( '/user/encounter/detail', { state: { encounterId: encounter.encounterId } } )
    }

    const deleteEncounter = ( encounterId ) =>
    {
        showLoader()
        deleteEncounterRequest( encounterId )
            .then( function ( message )
            {
                hideLoader()
                closeDeleteAlert();
                getEncounters();
                notify( {
                    type: "SUCCESS",
                    message: message,
                    title: "Success!!!"
                } )
            } )
            .catch( function ( error )
            {
                hideLoader()
                notify( {
                    type: "ERROR",
                    message: error.message ? error.message : error,
                    title: error.title ? error.title : "Error!!!"
                } )
            } )
    }

    const openDeleteAlert = ( encounter ) =>
    {
        setDeleteAlert( {
            show: true,
            encounter: encounter
        } )
    }


    const closeDeleteAlert = () =>
    {
        setDeleteAlert( {
            show: false,
            encounter: null
        } )
    }



    const getEncounters = ( withLoader = true ) =>
    {
        if ( withLoader )
        {
            showLoader();
        }

        Promise.all( [
            searchEncountersRequest(
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
            ),
        ] )

            .then( values =>
            {
                hideLoader()
                console.log( values )
                setEncounters( values[ 0 ] )
            } )
            .catch( error =>
            {
                hideLoader()
                notify( {
                    type: "ERROR",
                    message: error.message ? error.message : error,
                    title: error.title ? error.title : "Error!!!"
                } )
            } )
    }

    useEffect( () =>
    {
        getEncounters()
    }, [] )

    useEffect( () =>
    {
        if ( updateEncounter )
        {
            getEncounters( false );
            onEncounterUpdation();
        }
    }, [ updateEncounter ] )



    return (
        <div className='encounters'>
            <Alert
                open={ deleteAlert.show }
                handleClose={ closeDeleteAlert }
                okFn={ () => { deleteEncounter( deleteAlert.encounter.encounterId ) } }
                cancelFn={ closeDeleteAlert }
                title={ deleteAlert.show ? `Are you sure you want to delete the encounter with ${ deleteAlert.encounter.patientCaseName } (#${ deleteAlert.encounter.patientEmrId }) ?` : '' }
                okText={ "Delete" }
                cancelText={ 'Cancel' } />
            <div className='encounters__header'>
                <div className='encounters__header--title'>
                    Encounters({ encounters.length })
                </div>
                <div className='rightBtn'>
                    <MainIconButton
                        iconName={ 'cached_outlined' }
                        fontSize={ 30 }
                        title='Refresh'
                        onClick={ getEncounters }
                    />
                </div>
            </div>

            <Paper className='paper' sx={ { overflow: "auto", maxHeight: '70vh' } }>
                <TableContainer className='tableContainer' sx={ { width: '100%', display: 'table', tableLayout: 'fixed' } } >
                    <Table className='table' stickyHeader sx={ { minWidth: 800 } } >
                        {
                            encounters.length === 0 ?
                                ( <caption className="caption">No Encounters Found!</caption> )
                                :
                                ( null )
                        }
                        <TableHead className='head'>
                            <TableRow>
                                <TableCell className='cell'  >
                                    Date
                                </TableCell >
                                <TableCell className='cell'>
                                    Patient
                                </TableCell>
                                <TableCell className='cell' align='center'>
                                    Type
                                </TableCell>
                                <TableCell className='cell' align='center'>
                                    Clinical Note
                                </TableCell>
                                { preferences && preferences.isMedicalInsights ?
                                    <TableCell className='cell' align='center' >
                                        Medical Insights
                                    </TableCell>
                                    : null
                                }
                                { preferences && preferences.isBillingCodes ?
                                    <TableCell className='cell' align='center'>
                                        Billing Codes
                                    </TableCell>
                                    : null
                                }
                                { preferences && preferences.includeApproval ?
                                    <TableCell className='cell' align='center'>
                                        Approval
                                    </TableCell>
                                    : null
                                }
                                <TableCell className='cell' align='center' >
                                    Created
                                </TableCell>
                                <TableCell className='cell' align='center'>
                                    Actions
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody className='body'>
                            { encounters.map( encounter => (
                                <TableRow className='row' key={ `encounter${ encounter.encounterId }` }>
                                    <TableCell className='cell'><div className='text'>{ formattedDate( encounter.encounterDate ) }</div></TableCell>
                                    <TableCell className='cell'>
                                        <div className='patient'>
                                            <div className='patientName'>{ encounter.patientCaseName }</div>
                                            {
                                                encounter.patientEmrId ?
                                                    (
                                                        <div className='patientEmrId'>EMR { encounter.patientEmrId }</div>
                                                    )
                                                    :
                                                    (
                                                        null
                                                    )
                                            }
                                        </div>
                                    </TableCell>
                                    <TableCell className='cell' align='center'>
                                        <div className='type'>
                                            <div className='typeText'>
                                                {
                                                    encounter.isTranscriptionUsed ?
                                                        (
                                                            encounter.isTranscriptionModeConversation ?
                                                                'Conversation'
                                                                :
                                                                'Dictation'
                                                        )
                                                        :
                                                        (
                                                            'Medical Notes'
                                                        )
                                                }
                                            </div>
                                            {
                                                encounter.isTranscriptionUsed ?
                                                    (
                                                        <div className='status' style={ { backgroundColor: encounter.isTranscribed ? colors.successColor : colors.inProgressColor } }>
                                                            { encounter.isTranscribed ?
                                                                'Transcribed'
                                                                :
                                                                'In Progress' }
                                                        </div>
                                                    )
                                                    :
                                                    (
                                                        null
                                                    )
                                            }
                                        </div>
                                    </TableCell>
                                    <TableCell className="cell" align='center'>
                                        <div className='badge'>
                                            {
                                                encounter.isClinicalNoteGenerated ?
                                                    (
                                                        <IconBadge
                                                            title='Clinical Note Generated'
                                                            iconName={ 'task_alt_outlined' }
                                                            selectedColor={ colors.successColor }
                                                        />
                                                    )
                                                    :
                                                    (
                                                        <IconBadge
                                                            title='Clinical Note Pending'
                                                            iconName={ 'error_outline_outlined' }
                                                            selectedColor={ colors.greyColor }
                                                        />
                                                    )
                                            }
                                        </div>
                                    </TableCell>
                                    {
                                        preferences && preferences.isMedicalInsights ?
                                            <TableCell className="cell" align='center'>
                                                <div className='badge'>{
                                                    encounter.isMedicalInsights ?

                                                        encounter.isInsightsGenerated ?
                                                            (
                                                                <IconBadge
                                                                    title='Medical Insights Generated'
                                                                    iconName={ 'task_alt_outlined' }
                                                                    selectedColor={ colors.successColor }
                                                                />
                                                            )
                                                            :
                                                            (
                                                                <IconBadge
                                                                    title='Medical Insights Pending'
                                                                    iconName={ 'error_outline_outlined' }
                                                                    selectedColor={ colors.greyColor }
                                                                />
                                                            )

                                                        : null
                                                }
                                                </div>
                                            </TableCell>
                                            : null
                                    }
                                    {
                                        preferences && preferences.isBillingCodes ?
                                            <TableCell className="cell" align='center'>
                                                <div className='badge'>
                                                    {
                                                        encounter.isBillingCodes ?
                                                            encounter.isCptCodesGenerated && encounter.isIcd10CodesGenerated ?
                                                                (
                                                                    <IconBadge
                                                                        title='Billing Codes Generated'
                                                                        iconName={ 'task_alt_outlined' }
                                                                        selectedColor={ colors.successColor }
                                                                    />
                                                                )
                                                                :
                                                                (
                                                                    <IconBadge
                                                                        title='Billing Codes Pending'
                                                                        iconName={ 'error_outline_outlined' }
                                                                        selectedColor={ colors.greyColor }
                                                                    />
                                                                )
                                                            : null
                                                    }
                                                </div>
                                            </TableCell> : null
                                    }

                                    {
                                        preferences && preferences.includeApproval ?
                                            <TableCell className="cell" align='center'>
                                                <div className='badge'>
                                                    {
                                                        encounter.isApproved ?
                                                            (
                                                                <IconBadge
                                                                    title='Approved'
                                                                    iconName={ 'task_alt_outlined' }
                                                                    selectedColor={ colors.successColor }
                                                                />
                                                            )
                                                            :
                                                            (
                                                                <IconBadge
                                                                    title='Pending Approval'
                                                                    iconName={ 'error_outline_outlined' }
                                                                    selectedColor={ colors.greyColor }
                                                                />
                                                            )
                                                    }
                                                </div>
                                            </TableCell> : null
                                    }
                                    <TableCell className='cell' align='center'><div className='text'>{ formattedDate( encounter.createdDate ) }</div></TableCell>
                                    <TableCell className='cell' align='center'>
                                        <div className='buttons'>
                                            <MainIconButton iconName={ 'text_snippet_outlined' } title='Encounter Details' defaultColor={ colors.primaryColor } selectedColor={ colors.blackColor } onClick={ () => { navigateToEncounterDetailPage( encounter ) } } />
                                            {
                        
                                                    <MainIconButton iconName={ 'cancel_outlined' } title='Delete' disabled = {!encounter.isTranscribed} defaultColor={ colors.errorColor } selectedColor={ colors.blackColor } onClick={ () => { openDeleteAlert( encounter ) } } />
                                                    
                                    
                                            }

                                        </div>
                                    </TableCell>
                                </TableRow>
                            ) ) }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </div>
    )
}

export default Encounters