import { Icon, Tooltip } from '@mui/material';
import React from 'react'
import { colors } from '../../../constants/colors';


function IconBadge ( { iconName, placement = 'bottom', fontSize = 24, selectedColor = colors.primaryColor, title = '', ...props } )
{
    return (
        <Tooltip title={ title } arrow placement={ placement } >
            <Icon style={ { fontSize: fontSize , color :selectedColor} } {...props} >{ iconName }</Icon>
        </Tooltip>
    )
}

export default IconBadge