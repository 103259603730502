import React, { createContext, useState, useEffect, useContext } from 'react'
import { LoaderContext } from './LoaderContext';
import { useNotification } from './NotificationContext';
import { getAllPatientsRequest, getCountryCodesRequest, getPreferenceRequest } from '../webservices/webservice';
import { UserContext } from './UserContext';


export const DataStoreContext = createContext();

export default function DataStoreContextProvider ( { children } )
{
    const [ countryCodes, setCountryCodes ] = useState( [] );
    const [patients, setPatients] = useState([])
    const [preferences, setPreferences] = useState(null)
    const { showLoader, hideLoader } = useContext( LoaderContext )
    const { loggedInUserDetails } = useContext( UserContext );
    const notify = useNotification();
    const intializeData = () =>
    {
        showLoader();
        Promise.all( [
            getCountryCodesRequest()
        ] )
            .then( values =>
            {
                hideLoader();
                setCountryCodes( values[ 0 ] )
            } )
            .catch( error =>
            {
                hideLoader();
                notify( {
                    type: "ERROR",
                    message: error.message ? error.message : error,
                    title: error.title ? error.title : "Error!!!"
                } )
            } )

    }

    const intializeDataWithUser = () =>{
        showLoader();
        Promise.all( [
            getAllPatientsRequest(),
            getPreferenceRequest()
        ] )
            .then( values =>
            {
                hideLoader();
                setPatients( values[ 0 ] )
                setPreferences(values[1])
            } )
            .catch( error =>
            {
                hideLoader();
                notify( {
                    type: "ERROR",
                    message: error.message ? error.message : error,
                    title: error.title ? error.title : "Error!!!"
                } )
            } )
    }

    const updateData=()=>{
        intializeDataWithUser()
    }


    useEffect( () =>
    {
        intializeData()
    }, [] )

    useEffect(() => {
     if(loggedInUserDetails.user){
        intializeDataWithUser();

     }
    }, [loggedInUserDetails])
    


    return (
        <DataStoreContext.Provider value={ { countryCodes, patients, updateData, preferences } }>
            { children }
        </DataStoreContext.Provider>
    )
}
