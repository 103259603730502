import React from 'react'
import './home.scss'
import MainButton from '../../components/buttons/mainButton/MainButton'
import { colors } from '../../constants/colors';
import MainHeader from '../../components/headers/mainHeader.js/MainHeader';
import { Icon } from '@mui/material';
import Footer from '../../components/footer/Footer';
import { useNavigate } from 'react-router-dom';

function Home ()
{
    const navigate = useNavigate();
    return (
        <div className='home'>
            <MainHeader />
            <section className='mainSection'>
                <div className='textArea'>
                    <div className='title'>
                        AI-Powered <span style={ { color: colors.primaryColor } }>Medical</span> <span style={ { color: colors.secondaryColor } }>Scribe</span> for seamless Health Record Documentation
                    </div>
                    <div className='description' >
                        Transforming patient-doctor conversations into accurate & efficient health records, revolutionizing healthcare documentation effortlessly.

                    </div>
                    <div className='buttons'>
                        <MainButton
                            text={ 'Contact US' }
                            filled
                            hoverColor={ colors.blackColor }
                            textColor={ colors.whiteColor }
                            onClick={ () => { navigate( '/contact-us' ) } }
                        />
                        <MainButton
                            text={ 'Login Now' }
                            filled
                            color={ colors.blackColor }
                            hoverColor={ colors.blackColor }
                            textColor={ colors.whiteColor }
                            onClick={ () => { navigate( '/login' ) } }
                        />
                    </div>
                </div>
                <div className='imageArea' >

                </div>
            </section>
            <section className='serviceSection'>
                <div className='textArea'>
                    <div className='title'>Our <span style={ { color: colors.primaryColor } }>Services</span></div>
                    <div className='description'>Capture Patient interactions, Generate insights and draft Clinical summaries automatically.</div>
                </div>
                <div className='serviceArea'>
                    <div className='serviceBox'>
                        <div className='iconArea'>
                            <Icon className='icon'>record_voice_over</Icon>
                        </div>
                        <div className='title'>
                            Conversation Transcription
                        </div>
                        <div className='description'>
                            Voice recognition and Automatic transcription of doctor-patient dialogue in real time, allows doctors to focus more on patients and less on documentation.
                        </div>
                    </div>
                    <div className='serviceBox'>
                        <div className='iconArea'>
                            <Icon className='icon'>note</Icon>
                        </div>
                        <div className='title'>
                            Clinical Note Generation
                        </div>
                        <div className='description'>
                            Automatically generate comprehensive clinical notes from conversations, facilitating efficient documentation and record-keeping.
                        </div>
                    </div>
                    <div className='serviceBox'>
                        <div className='iconArea'>
                            <Icon className='icon'>assignment</Icon>
                        </div>
                        <div className='title'>
                            { `Code Assignment (CPT & ICD-10)` }
                        </div>
                        <div className='description'>
                            Utilize AI algorithms to accurately assign CPT and ICD-10 codes, streamlining billing and ensuring coding precision.
                        </div>
                    </div>
                    <div className='serviceBox'>
                        <div className='iconArea'>
                            <Icon className='icon'>folder</Icon>
                        </div>
                        <div className='title'>
                            HIPPA Enabled Patient Data
                        </div>
                        <div className='description'>
                            We provide HIPPA compliant platform for storing and managing patient data, ensuring privacy and security at all levels.
                        </div>
                    </div>

                </div>
            </section>
            <Footer />
        </div>
    )
}

export default Home